<template>
  <b-card title="Notificacions WhatsApp">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <b-col
          cols="6"
          class="d-flex justify-content-center flex-column text-center"
        >
          <span class="mb-50"> Enviada? </span>
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner v-if="loadingWhatsAppNotificationSent" />
            <div v-else>
              <feather-icon
                v-if="whatsAppNotificationSent"
                icon="CheckIcon"
                size="28"
                class="text-success"
              />
              <feather-icon v-else icon="XIcon" size="28" class="text-danger" />
            </div>
          </div>
        </b-col>

        <b-col
          cols="4"
          class="d-flex justify-content-center flex-column text-center"
        >
          <span class="mb-50"> Acceptada? </span>
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <feather-icon
                v-if="whatsAppNotificationAccepted"
                icon="CheckIcon"
                size="28"
                class="text-success"
              />
              <feather-icon v-else icon="XIcon" size="28" class="text-danger" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
      loadingWhatsAppNotificationSent: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loadingStats"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    whatsAppNotificationSent() {
      return this.stats?.ownerWhatsAppBookingConfirmationSent || false;
    },
    whatsAppNotificationAccepted() {
      return this.stats?.ownerWhatsAppBookingConfirmationAccepted || false;
    },
  },
};
</script>
