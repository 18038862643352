<template>
  <div>
    <b-row :class="{ 'mb-2': lastMinutePolicySet || cancellationPolicySet }">
      <b-col cols="12">
        <p class="mb-50 font-weight-bold">
          {{ $t("Pagaments", locale) }}
        </p>
        <template
          v-if="
            !isEmpty(policy.initialPaymentAmount) &&
            policy.initialPaymentAmount < 100
          "
        >
          <p>
            {{
              $t("policies.initialPaymentAmountPercentage", locale, {
                percentage: policy.initialPaymentAmount || "XX",
              })
            }}
          </p>
          <p>
            {{
              $t("policies.finalPaymentAmountPercentage", locale, {
                percentage: policy.initialPaymentAmount
                  ? 100 - policy.initialPaymentAmount
                  : "XX",
              })
            }}
            <span v-if="!isEmptyOrZero(policy.finalPaymentLimit)">
              {{
                $t("policies.timeBeforeArrival", locale, {
                  time: timeText(policy.finalPaymentLimit),
                })
              }}
            </span>
            <span v-else>
              {{
                $t("policies.timeArrivalDay", locale, {
                  time: timeText(policy.finalPaymentLimit),
                })
              }}
            </span>
          </p>
          <p
            v-if="
              !isEmpty(policy.finalPaymentLimit) &&
              policy.lastMinuteStart < policy.finalPaymentLimit
            "
          >
            {{
              $t("policies.lastMinuteInitialPaymentTotalAmount", locale, {
                time: timeText(policy.finalPaymentLimit),
              })
            }}
          </p>
        </template>
        <p v-else>
          {{ $t("policies.paymentTotalAmount", locale) }}
        </p>
      </b-col>
    </b-row>
    <b-row
      v-if="cancellationPolicySet"
      :class="{ 'mb-2': lastMinutePolicySet }"
    >
      <b-col cols="12">
        <p class="mb-50 font-weight-bold">
          {{ $t("Cancel·lacions", locale) }}
        </p>
        <template v-if="!isEmpty(policy.freeCancellationGracePeriod)">
          <p>
            {{
              $t("policies.freeCancellationAfterBooking", locale, {
                time: timeText(policy.freeCancellationGracePeriod),
              })
            }}
          </p>
          <p v-if="isEmptyOrZero(policy.partialRefundAmount)">
            {{
              $t("policies.ifCancellationAfterBooking", locale, {
                time: timeText(policy.freeCancellationGracePeriod),
              })
            }}
            <span v-if="policy.refundableServices">
              {{ $t("policies.onlyServicesRefunded", locale) }}
            </span>
            <span v-else>
              {{ $t("policies.noRefund", locale) }}
            </span>
          </p>
          <template v-else>
            <p>
              <template v-if="isEmptyOrZero(policy.partialRefundLimit)">
                {{
                  $t("policies.ifCancellationAfterBooking", locale, {
                    time: timeText(policy.freeCancellationGracePeriod),
                  })
                }}
              </template>
              <template v-else>
                {{
                  $t("policies.ifCancellationBetweenBooking", locale, {
                    time1: timeText(policy.freeCancellationGracePeriod),
                    time2: timeText(policy.partialRefundLimit),
                  })
                }}
              </template>
              <span v-if="!policy.refundableServices">
                {{
                  $t("policies.partialRefund", locale, {
                    percentage: policy.partialRefundAmount,
                  })
                }}
              </span>
              <span v-if="policy.refundableServices">
                {{
                  $t("policies.partialRefundAndServices", locale, {
                    percentage: policy.partialRefundAmount,
                  })
                }}
              </span>
            </p>
            <p v-if="!isEmptyOrZero(policy.partialRefundLimit)">
              {{
                $t("policies.ifCancellationBeforeArrival", locale, {
                  time: timeText(policy.partialRefundLimit),
                })
              }}
              <span v-if="policy.refundableServices">
                {{ $t("policies.onlyServicesRefunded", locale) }}
              </span>
              <span v-else>
                {{ $t("policies.noRefund", locale) }}
              </span>
            </p>
          </template>
        </template>
        <template v-else>
          <template v-if="!isEmptyOrZero(policy.freeCancellationLimit)">
            <p>
              {{
                $t("policies.freeCancellationBeforeArrival", locale, {
                  time: timeText(policy.freeCancellationLimit),
                })
              }}
            </p>
            <p v-if="isEmptyOrZero(policy.partialRefundAmount)">
              {{
                $t("policies.ifCancellationBeforeArrival", locale, {
                  time: timeText(policy.freeCancellationLimit),
                })
              }}
              <span v-if="policy.refundableServices">
                {{ $t("policies.onlyServicesRefunded", locale) }}
              </span>
              <span v-else>
                {{ $t("policies.noRefund", locale) }}
              </span>
            </p>
            <template v-else>
              <p>
                <template v-if="isEmptyOrZero(policy.partialRefundLimit)">
                  {{
                    $t("policies.ifCancellationBeforeArrival", locale, {
                      time: timeText(policy.freeCancellationLimit),
                    })
                  }}
                </template>
                <template v-else>
                  {{
                    $t(
                      "policies.ifCancellationBetweenDaysBeforeArrival",
                      locale,
                      {
                        time1: timeText(policy.freeCancellationLimit),
                        time2: timeText(policy.partialRefundLimit),
                      }
                    )
                  }}
                </template>
                <span v-if="!policy.refundableServices">
                  {{
                    $t("policies.partialRefund", locale, {
                      percentage: policy.partialRefundAmount,
                    })
                  }}
                </span>
                <span v-if="policy.refundableServices">
                  {{
                    $t("policies.partialRefundAndServices", locale, {
                      percentage: policy.partialRefundAmount,
                    })
                  }}
                </span>
              </p>
              <p v-if="!isEmptyOrZero(policy.partialRefundLimit)">
                {{
                  $t("policies.ifCancellationBeforeArrival", locale, {
                    time: timeText(policy.partialRefundLimit),
                  })
                }}
                <span v-if="policy.refundableServices">
                  {{ $t("policies.onlyServicesRefunded", locale) }}
                </span>
                <span v-else>
                  {{ $t("policies.noRefund", locale) }}
                </span>
              </p>
            </template>
          </template>
        </template>
      </b-col>
    </b-row>

    <!-- LAST MINUTE -->
    <b-row v-if="lastMinutePolicySet">
      <b-col cols="12">
        <p class="mb-50 font-weight-bold">Last minute</p>
        <p v-if="!isEmptyOrZero(policy.lastMinuteStart)">
          {{
            $t("policies.lastMinuteStart", locale, {
              time: timeText(policy.lastMinuteStart),
            })
          }}
        </p>
        <p>
          {{ $t("policies.paymentTotalAmount", locale) }}
        </p>
        <template v-if="!isEmptyOrZero(policy.lastMinuteFreeCancellationLimit)">
          <p>
            {{
              $t("policies.freeCancellationBeforeArrival", locale, {
                time: timeText(policy.lastMinuteFreeCancellationLimit),
              })
            }}
          </p>
          <p>
            {{
              $t("policies.ifCancellationBeforeArrival", locale, {
                time: timeText(policy.lastMinuteFreeCancellationLimit),
              })
            }}
            <span v-if="policy.lastMinuteRefundableServices">
              {{ $t("policies.onlyServicesRefunded", locale) }}
            </span>
            <span v-else>
              {{ $t("policies.noRefund", locale) }}
            </span>
          </p>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { getHoursText, isEmpty } from "@/utils/methods";

export default {
  name: "BookingPolicyPreview",
  components: {
    BRow,
    BCol,
  },
  props: {
    policy: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: "ca-ES",
    },
  },
  data() {
    return {
      policyText: [],
    };
  },
  computed: {
    cancellationPolicySet() {
      return (
        !this.isEmptyOrZero(this.policy.freeCancellationGracePeriod) ||
        !this.isEmptyOrZero(this.policy.freeCancellationLimit) ||
        (!this.isEmptyOrZero(this.policy.partialRefundLimit) &&
          !this.isEmpty(this.policy.partialRefundAmount))
      );
    },
    lastMinutePolicySet() {
      return (
        !this.isEmptyOrZero(this.policy.lastMinuteStart) &&
        !this.isEmptyOrZero(this.policy.lastMinuteFreeCancellationLimit)
      );
    },
  },
  watch: {
    policyText: {
      deep: true,
      handler() {
        this.$emit("policy-updated", this.policyText);
      },
    },
  },
  created() {
    this.initPolicyText();
  },
  methods: {
    initPolicyText() {
      this.policyText = {
        payments: {
          title: this.$t("Pagaments", this.locale),
          items: [],
        },
        cancellations: {
          title: this.$t("Cancel·lacions", this.locale),
          items: [],
        },
        lastMinute: {
          title: "Last minute",
          items: [],
        },
      };

      // Payments
      if (
        !this.isEmpty(this.policy.initialPaymentAmount) &&
        this.policy.initialPaymentAmount < 100
      ) {
        // Initial payment
        this.policyText.payments.items.push(
          this.$t("policies.initialPaymentAmountPercentage", this.locale, {
            percentage: this.policy.initialPaymentAmount || "XX",
          })
        );
        // Final payment
        let finalPaymentAmountText = this.$t(
          "policies.finalPaymentAmountPercentage",
          this.locale,
          {
            percentage: this.policy.initialPaymentAmount
              ? 100 - this.policy.initialPaymentAmount
              : "XX",
          }
        );

        if (!this.isEmptyOrZero(this.policy.finalPaymentLimit)) {
          finalPaymentAmountText += ` ${this.$t(
            "policies.timeBeforeArrival",
            this.locale,
            { time: this.timeText(this.policy.finalPaymentLimit) }
          )}`;
        } else {
          finalPaymentAmountText += ` ${this.$t(
            "policies.lastMinuteInitialPaymentTotalAmount",
            this.locale,
            { time: this.timeText(this.policy.finalPaymentLimit) }
          )}`;
        }

        this.policyText.payments.items.push(finalPaymentAmountText);

        // Last minute payment
        if (
          !this.isEmpty(this.policy.finalPaymentLimit) &&
          this.policy.lastMinuteStart < this.policy.finalPaymentLimit
        ) {
          this.policyText.payments.items.push(
            this.$t(
              "policies.lastMinuteInitialPaymentTotalAmount",
              this.locale,
              { time: this.timeText(this.policy.finalPaymentLimit) }
            )
          );
        }
      } else {
        this.policyText.payments.items.push(
          this.$t("policies.paymentTotalAmount", this.locale)
        );
      }

      // Cancellations
      if (this.cancellationPolicySet) {
        if (!this.isEmpty(this.policy.freeCancellationGracePeriod)) {
          this.policyText.cancellations.items.push(
            this.$t("policies.freeCancellationAfterBooking", this.locale, {
              time: this.timeText(this.policy.freeCancellationGracePeriod),
            })
          );
          if (this.isEmptyOrZero(this.policy.partialRefundAmount)) {
            this.policyText.cancellations.items.push(
              this.$t("policies.ifCancellationAfterBooking", this.locale, {
                time: this.timeText(this.policy.freeCancellationGracePeriod),
              })
            );
            if (this.policy.refundableServices) {
              this.$t("policies.onlyServicesRefunded", this.locale);
            } else {
              this.$t("policies.noRefund", this.locale);
            }
          } else {
            let notEmptyPartialRefundAmountText = null;
            if (this.isEmptyOrZero(this.policy.partialRefundLimit)) {
              notEmptyPartialRefundAmountText = this.$t(
                "policies.ifCancellationAfterBooking",
                this.locale,
                { time: this.timeText(this.policy.freeCancellationGracePeriod) }
              );
            } else {
              notEmptyPartialRefundAmountText = this.$t(
                "policies.ifCancellationBetweenBooking",
                this.locale,
                {
                  time1: this.timeText(this.policy.freeCancellationGracePeriod),
                  time2: this.timeText(this.policy.partialRefundLimit),
                }
              );
            }
            if (!this.policy.refundableServices) {
              notEmptyPartialRefundAmountText += ` ${this.$t(
                "policies.partialRefund",
                this.locale,
                { percentage: this.policy.partialRefundAmount }
              )}`;
            } else {
              notEmptyPartialRefundAmountText += ` ${this.$t(
                "policies.partialRefundAndServices",
                this.locale,
                { percentage: this.policy.partialRefundAmount }
              )}`;
            }
            this.policyText.cancellations.items.push(
              notEmptyPartialRefundAmountText
            );

            if (!this.isEmptyOrZero(this.policy.partialRefundLimit)) {
              let emptyPartialRefundLimitText = this.$t(
                "policies.ifCancellationBeforeArrival",
                this.locale,
                { time: this.timeText(this.policy.partialRefundLimit) }
              );
              if (this.policy.refundableServices) {
                emptyPartialRefundLimitText += `${this.$t(
                  "policies.onlyServicesRefunded",
                  this.locale
                )}`;
              } else {
                emptyPartialRefundLimitText += `${this.$t(
                  "policies.noRefund",
                  this.locale
                )}`;
              }
              this.policyText.cancellations.items.push(
                emptyPartialRefundLimitText
              );
            }
          }
        } else if (!this.isEmptyOrZero(this.policy.freeCancellationLimit)) {
          this.policyText.cancellations.items.push(
            this.$t("policies.freeCancellationBeforeArrival", this.locale, {
              time: this.timeText(this.policy.freeCancellationLimit),
            })
          );
          if (this.isEmptyOrZero(this.policy.partialRefundAmount)) {
            let emptyPartialRefundAmountText = this.$t(
              "policies.ifCancellationBeforeArrival",
              this.locale,
              { time: this.timeText(this.policy.freeCancellationLimit) }
            );
            if (this.policy.refundableServices) {
              emptyPartialRefundAmountText += ` ${this.$t(
                "policies.onlyServicesRefunded",
                this.locale
              )}`;
            } else {
              emptyPartialRefundAmountText += ` ${this.$t(
                "policies.noRefund",
                this.locale
              )}`;
            }
            this.policyText.cancellations.items.push(
              emptyPartialRefundAmountText
            );
          } else {
            let notEmptyPartialRefundAmountText = null;
            if (this.isEmptyOrZero(this.policy.partialRefundLimit)) {
              notEmptyPartialRefundAmountText = this.$t(
                "policies.ifCancellationBeforeArrival",
                this.locale,
                { time: this.timeText(this.policy.freeCancellationLimit) }
              );
            } else {
              notEmptyPartialRefundAmountText = this.$t(
                "policies.ifCancellationBetweenDaysBeforeArrival",
                this.locale,
                {
                  time1: this.timeText(this.policy.freeCancellationLimit),
                  time2: this.timeText(this.policy.partialRefundLimit),
                }
              );
            }
            if (!this.policy.refundableServices) {
              notEmptyPartialRefundAmountText += ` ${this.$t(
                "policies.partialRefund",
                this.locale,
                { percentage: this.policy.partialRefundAmount }
              )}`;
            } else {
              notEmptyPartialRefundAmountText += ` ${this.$t(
                "policies.partialRefundAndServices",
                this.locale,
                { percentage: this.policy.partialRefundAmount }
              )}`;
            }
            this.policyText.cancellations.items.push(
              notEmptyPartialRefundAmountText
            );

            if (!this.isEmptyOrZero(this.policy.partialRefundLimit)) {
              let notEmptyPartialRefundLimitText = this.$t(
                "policies.ifCancellationBeforeArrival",
                this.locale,
                { time: this.timeText(this.policy.partialRefundLimit) }
              );
              if (!this.policy.refundableServices) {
                notEmptyPartialRefundLimitText += ` ${this.$t(
                  "policies.onlyServicesRefunded",
                  this.locale
                )}`;
              } else {
                notEmptyPartialRefundLimitText += ` ${this.$t(
                  "policies.noRefund",
                  this.locale
                )}`;
              }
              this.policyText.cancellations.items.push(
                notEmptyPartialRefundLimitText
              );
            }
          }
        }
      }

      // Last minute
      if (this.lastMinutePolicySet) {
        if (!this.isEmptyOrZero(this.policy.lastMinuteStart)) {
          this.policyText.lastMinute.items.push(
            this.$t("policies.lastMinuteStart", this.locale, {
              time: this.timeText(this.policy.lastMinuteStart),
            })
          );
        }
        this.policyText.lastMinute.items.push(
          this.$t("policies.paymentTotalAmount", this.locale)
        );
        if (!this.isEmptyOrZero(this.policy.lastMinuteFreeCancellationLimit)) {
          this.policyText.lastMinute.items.push(
            this.$t("policies.freeCancellationBeforeArrival", this.locale, {
              time: this.timeText(this.policy.lastMinuteFreeCancellationLimit),
            })
          );

          let lastMinuteCancellationBeforeArrivalText = this.$t(
            "policies.ifCancellationBeforeArrival",
            this.locale,
            { time: this.timeText(this.policy.lastMinuteFreeCancellationLimit) }
          );
          if (this.policy.lastMinuteRefundableServices) {
            lastMinuteCancellationBeforeArrivalText += ` ${this.$t(
              "policies.onlyServicesRefunded",
              this.locale
            )}`;
          } else {
            lastMinuteCancellationBeforeArrivalText += ` ${this.$t(
              "policies.noRefund",
              this.locale
            )}`;
          }
          this.policyText.lastMinute.items.push(
            lastMinuteCancellationBeforeArrivalText
          );
        }
      }
    },
    isEmpty(value) {
      return isEmpty(value);
    },
    isEmptyOrZero(value) {
      // eslint-disable-next-line eqeqeq
      return isEmpty(value) || value == 0;
    },
    timeText(hours) {
      return getHoursText(hours, this.locale);
    },
  },
};
</script>
