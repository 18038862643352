<template>
  <div class="services-table">
    <!-- NEW SERVICE BUTTON AND SEARCH -->
    <b-row
      v-if="showSearch || showButton"
      class="mb-2 d-flex flex-wrap"
      :class="{
        'justify-content-between': showButton,
        'justify-content-start': !showButton,
      }"
    >
      <b-col v-if="showButton" cols="3" md="auto" class="mb-1 mb-md-0">
        <b-button
          variant="primary"
          block
          class="d-flex justify-content-center"
          @click.prevent="newService"
        >
          <feather-icon icon="PlusIcon" class="d-block d-md-none" />
          <span class="d-none d-md-inline">{{ $t("Afegeix servei") }}</span>
        </b-button>
      </b-col>
      <b-col
        v-if="showSearch"
        cols="9"
        md="auto"
        class="mb-1 mb-md-0"
        :class="{
          'justify-content-between': showButton,
          'justify-content-start': !showButton,
        }"
      >
        <b-form-input
          id="filterInput"
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          :disabled="loading"
        />
      </b-col>
    </b-row>

    <!-- SERVICES TABLE -->
    <b-row>
      <b-col md="12">
        <b-table
          striped
          borderless
          responsive
          class="position-relative"
          :busy="loading"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="services"
          show-empty
          :empty-text="$t('errors.emptyServicesTable.title')"
          :empty-filtered-text="$t('errors.emptyServicesTable.title')"
          :fields="fields"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              v-if="isTouristTax(data.item.code)"
              v-b-tooltip.hover.bottom="'Recalcula import'"
              class="ml-75 cursor-pointer"
              icon="RefreshCwIcon"
              @click="onRecalculateTouristTaxClicked(data.item.uuid)"
            />
            <feather-icon
              v-b-tooltip.hover.bottom="'Edita'"
              class="ml-75 cursor-pointer"
              icon="EditIcon"
              @click="onEditServiceClicked(data.item.uuid)"
            />
            <feather-icon
              v-b-tooltip.hover.bottom="'Elimina'"
              class="ml-75 cursor-pointer text-danger"
              icon="TrashIcon"
              @click="confirmDeleteService(data.item.uuid)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- PAGINATION -->
    <b-row
      v-show="!loading"
      v-if="services.length > pageOptions[0]"
      class="d-flex justify-content-between align-items-center"
    >
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-start"
      >
        <b-form-group
          id="per-page-input"
          :label="`${$t('Per pàgina')}`"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        lg="3"
        class="mb-1 mb-md-0 d-flex justify-content-center justify-content-md-end"
      >
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import {
  calculateTouristTaxPrice,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    services: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "name",
      sortDesc: false,
      perPage: 10,
      pageOptions: [5, 10, 50, 100, 500],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      fields: [
        { key: "name", label: this.$t("Nom"), sortable: true },
        { key: "pvpPrice", label: this.$t("PVP"), sortable: true },
        { key: "costPrice", label: this.$t("Propietari"), sortable: true },
        {
          key: "commissionPercentage",
          label: this.$t("Comissió"),
          sortable: true,
        },
        { key: "provider", label: this.$t("Proveïdor"), sortable: true },
        { key: "responsible", label: this.$t("Cobrador"), sortable: true },
        {
          key: "actions",
          label: this.$t("Accions"),
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  watch: {
    services(services) {
      this.totalRows = services.length;
      this.currentPage = 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.services.length;
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    contractBillingType() {
      return this.contract?.billingType || null;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    newService() {
      this.$emit("button-click");
    },
    onEditServiceClicked(serviceUuid) {
      this.$emit("edit-service-click", serviceUuid);
    },
    onRecalculateTouristTaxClicked(serviceUuid) {
      this.$store.dispatch("app/setLoading", true);
      // todo
      const price = calculateTouristTaxPrice(
        this.booking.checkin,
        this.booking.checkout,
        this.booking.adults,
        this.contractBillingType === "SUBURANCE"
      );

      console.log("new price", price);

      this.$store
        .dispatch("booking/updateService", {
          uuid: serviceUuid,
          pvpPrice: price || price === 0 ? Math.round(price) : null,
          costPrice: price || price === 0 ? Math.round(price) : null,
        })
        .then(() => {
          notifySuccess(
            "Servei actualitzat",
            "L'import de l'impost turístic ha estat recalculat correctament"
          );
        })
        .catch(() => {
          notifyError(
            "Servei no actualitzat",
            "Hi ha hagut un error intentant recalcular l'import de l'impost turístic"
          );
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    confirmDeleteService(serviceUuid) {
      this.$swal({
        title: "Eliminar servei",
        text: "Estàs a punt d'eliminar aquest servei, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteService(serviceUuid);
      });
    },
    deleteService(serviceUuid) {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("booking/deleteService", serviceUuid)
          .then(() => this.$emit("service-deleted"))
          .catch(() => {
            this.$emit("delete-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    isTouristTax(serviceCode) {
      return serviceCode === "TOURIST_TAX";
    },
  },
};
</script>

<style lang="scss">
.services-table {
  #per-page-input__BV_label_ {
    margin-top: 5px;
  }
}
</style>
