<template>
  <div id="summary-card">
    <!-- POLICY ALERT -->
    <b-alert variant="danger" class="mb-2" :show="showBookingPolicyAlert">
      <div class="alert-body">
        <feather-icon icon="AlertTriangleIcon" class="mr-50" />
        Aquesta reserva encara no te cap política de reserva configurada, és
        necessari seleccionar-ne una
      </div>
    </b-alert>

    <!-- FICTITIOUS BOOKING ALERT -->
    <b-alert variant="info" class="mb-2" :show="isFictitious">
      <div class="alert-body">
        <feather-icon icon="AlertTriangleIcon" class="mr-50" />
        Aquesta reserva és <b>fictícia</b>, recorda a cancel·lar-la abans que
        finalitzi el període de cancel·lació gratuïta
      </div>
    </b-alert>

    <!-- SUMMARY CARD -->
    <b-card>
      <!-- BOOKING DATE & STATUS -->
      <b-row class="mb-3 d-flex justify-content-between align-items-center">
        <!-- BOOKING DATE -->
        <b-col cols="12" sm="auto" class="mb-1 mb-sm-0">
          <b-badge variant="primary">
            <feather-icon icon="ClockIcon" class="mr-50" />
            <span>{{ bookingDateTime }}</span>
          </b-badge>
        </b-col>

        <!-- BOOKING STATUS & HIGHLIGHT -->
        <b-col cols="12" sm="auto" class="d-flex">
          <!-- STATUS SELECT -->
          <v-select
            v-if="editMode"
            v-model="status"
            class="status-select"
            :options="bookingStatusOptions"
            :reduce="(option) => option.value"
          />

          <!-- STATUS -->
          <b-status-badge v-else :status="booking.status" />

          <!-- LONG TERM BOOKING -->
          <b-action-icon
            v-if="!isFictitious"
            :value="longTerm"
            icon="CoffeeIcon"
            size="20"
            :tooltip-text="`Reserva ${
              longTerm ? 'de llarga estada' : 'vacacional'
            }`"
            :loading="loadingLongTerm"
            class="ml-1"
            @click="setLongTermBooking"
          />

          <!-- HIGHLIGHT BOOKING -->
          <b-action-icon
            v-if="!isFictitious"
            :value="highlighted"
            icon="StarIcon"
            size="20"
            :tooltip-text="`Reserva ${highlighted ? '' : 'no '} destacada`"
            :loading="loadingHighlighted"
            class="ml-1"
            @click="setBookingHighlighted"
          />

          <!-- FICTITIOUS BOOKING -->
          <b-action-icon
            v-if="isAdmin"
            :value="fictitious"
            icon="UmbrellaIcon"
            iconVariant="info"
            size="20"
            :tooltip-text="`Reserva ${fictitious ? 'especial' : 'no especial'}`"
            :loading="loadingFictitious"
            class="ml-1"
            @click="setBookingFictitious"
            @contextmenu.prevent="$refs.menu.open"
          />
          <vue-context v-if="isAdmin" ref="menu">
            <li>
              <b-link @click="setBookingFromFictitious">
                Reserva obtinguda a partir d'una fictícia
              </b-link>
            </li>
          </vue-context>
        </b-col>
      </b-row>

      <!-- BOOKING SUMMARY -->
      <b-row class="mb-2">
        <!-- ACCOMMODATION -->
        <b-col cols="6" sm="4" class="mb-2 order-0">
          <h6 class="mb-25">Allotjament</h6>
          <b-accommodation
            :name="accommodationName"
            :uuid="accommodationUuid"
          />
        </b-col>
        <!-- CHECKIN -->
        <b-col cols="6" sm="4" class="mb-2 order-2 order-sm-1">
          <h6 class="mb-25">Entrada</h6>
          <b-card-text>{{ bookingCheckin }}</b-card-text>
        </b-col>
        <!-- CHECKOUT -->
        <b-col cols="6" sm="4" class="mb-2 order-3 order-sm-2">
          <h6 class="mb-25">Sortida</h6>
          <b-card-text>{{ bookingCheckout }}</b-card-text>
        </b-col>
        <!-- LOCALIZATOR -->
        <b-col cols="6" sm="4" class="mb-2 order-1 order-sm-3">
          <h6 class="mb-25">Localitzador</h6>
          <span
            :class="{ 'cursor-pointer': canOpenLocalizatorInNewTab }"
            @click="openLocalizatorInNewTab"
          >
            {{ booking.localizator }}
          </span>
          <feather-icon
            v-if="canOpenLocalizatorInNewTab"
            icon="ExternalLinkIcon"
            class="ml-25 mb-25"
          />
        </b-col>
        <!-- SOURCE -->
        <b-col
          :cols="editMode ? 12 : 6"
          :sm="editMode ? 6 : 4"
          xl="4"
          class="mb-2 order-4"
        >
          <h6 class="mb-25">Portal</h6>
          <v-select
            v-if="editMode"
            v-model="source"
            :options="sourceOptions"
            :reduce="(option) => option.value"
          />
          <b-card-text v-else>
            {{ bookingSourceName }}
          </b-card-text>
        </b-col>
        <!-- SOURCE LOCALIZATOR -->
        <b-col
          :cols="editMode ? 12 : 6"
          :sm="editMode ? 6 : 4"
          xl="4"
          class="mb-2 order-5"
        >
          <h6 class="mb-25">Referència</h6>
          <b-form-input
            v-if="editMode"
            v-model="sourceLocalizator"
            type="text"
          />
          <template v-else>
            <span
              :class="{ 'cursor-pointer': canOpenSourceLocalizatorInNewTab }"
              @click="openSourceLocalizatorInNewTab"
            >
              {{ bookingSourceLocalizator }}
            </span>
            <feather-icon
              v-if="canOpenSourceLocalizatorInNewTab"
              icon="ExternalLinkIcon"
              class="ml-25 mb-25"
            />
          </template>
        </b-col>
        <!-- NIGHTS -->
        <b-col v-if="!editMode" cols="12" sm="4" class="mb-2 order-6">
          <h6 class="mb-25">Vespres</h6>
          <b-card-text>{{ bookingNights }}</b-card-text>
        </b-col>
        <!-- GUESTS -->
        <b-col v-if="!editMode" cols="12" sm="8" lg="4" class="mb-2 order-7">
          <h6 class="mb-25">Hostes</h6>
          <b-card-text>
            <feather-icon
              v-show="guestsNumberInconsistency"
              id="guests-inconsistency-icon"
              icon="AlertTriangleIcon"
              class="mr-25 text-danger"
            />
            <b-tooltip
              target="guests-inconsistency-icon"
              :delay="{ show: 100, hide: 0 }"
              placement="left"
              no-fade
            >
              El nombre d'adults de la reserva inicial no coincideix amb el
              nombre d'adults confirmats
            </b-tooltip>
            {{ bookingGuests }}
          </b-card-text>
        </b-col>
        <b-col
          v-if="editMode"
          cols="12"
          :lg="editMode ? 6 : 4"
          xl="4"
          class="mb-2 order-8"
        >
          <h6 class="mb-25">Adults</h6>
          <b-form-spinbutton v-model="adults" min="0" max="50" />
        </b-col>
        <b-col
          v-if="editMode"
          cols="12"
          :lg="editMode ? 6 : 4"
          xl="4"
          class="mb-2 order-9"
        >
          <h6 class="mb-25">Nins/es</h6>
          <b-form-spinbutton v-model="children" min="0" max="10" />
        </b-col>
        <b-col
          v-if="editMode"
          cols="12"
          :lg="editMode ? 6 : 4"
          xl="4"
          class="mb-2 order-10"
        >
          <h6 class="mb-25">Nadons</h6>
          <b-form-spinbutton v-model="babies" min="0" max="10" />
        </b-col>
        <!-- BOOKING EMAIL -->
        <b-col
          cols="12"
          :lg="editMode ? 12 : 4"
          :xl="editMode ? 12 : 4"
          class="mb-2 order-11"
        >
          <h6 class="mb-25">Email reserva</h6>
          <b-form-input v-if="editMode" v-model="bookingEmail" type="text" />
          <b-card-text v-else>
            {{ booking.email || "No definit" }}
          </b-card-text>
        </b-col>
      </b-row>

      <!-- CLIENT -->
      <b-overlay :show="loadingClient" variant="white" rounded="sm">
        <b-row class="mb-2">
          <b-col cols="12">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="mr-2">
                    <feather-icon icon="UserIcon" size="20" />
                  </span>
                  <div>
                    <h6 class="mb-25">
                      Nom del client
                      <small class="ml-50">
                        <b-link @click="$copyText(clientContactInfo)">
                          Copia
                        </b-link>
                      </small>
                    </h6>
                    <div class="d-block d-sm-flex">
                      <b-client :uuid="clientUuid" :name="clientFullName" />
                    </div>
                    <div
                      v-if="!loadingClient && !clientHasIdNumber"
                      class="d-block d-sm-flex"
                    >
                      <b-badge variant="danger" class="mt-50">
                        Falta Passaport/DNI
                      </b-badge>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <template v-if="!clientEditMode">
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mt-50"
                      @click="clientEditMode = true"
                    >
                      <feather-icon icon="EditIcon" class="d-block d-sm-none" />
                      <span class="d-none d-sm-block">
                        Modifica les dades del client
                      </span>
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      v-if="!savingClient"
                      size="sm"
                      variant="light"
                      class="mt-50 mr-1"
                      @click="clientEditMode = false"
                    >
                      Cancel·la
                    </b-button>
                    <b-button
                      v-if="!savingClient"
                      size="sm"
                      variant="primary"
                      class="mt-50"
                      @click="saveClient"
                    >
                      Guarda
                    </b-button>
                    <b-button
                      v-if="savingClient"
                      size="sm"
                      variant="primary"
                      class="mt-50"
                      disabled
                    >
                      Guardant
                      <b-spinner v-if="savingClient" small class="ml-50" />
                    </b-button>
                  </template>
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="clientEditMode"
                class="d-flex align-items-center"
              >
                <span class="mr-2">
                  <feather-icon icon="HashIcon" size="20" />
                </span>
                <div>
                  <h6 class="mb-25">Identificació</h6>
                  <div class="d-block d-sm-flex">
                    <b-form-input
                      v-model="clientIdNumber"
                      placeholder="Nº de document"
                      class="mr-1"
                    />
                    <b-form-radio-group
                      v-model="clientIdType"
                      :options="idTypeOptions"
                      text-field="label"
                      class="d-flex align-items-center"
                    />
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="clientEditMode"
                class="d-flex align-items-center"
              >
                <span class="mr-2">
                  <feather-icon icon="GlobeIcon" size="20" />
                </span>
                <div>
                  <h6 class="mb-25">Nacionalitat</h6>
                  <div class="d-block d-sm-flex">
                    <v-select
                      v-model="clientNationality"
                      placeholder="Nacionalitat"
                      :options="countryOptions"
                      :reduce="(option) => option.value"
                    />
                  </div>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <span class="mr-2">
                  <feather-icon icon="MailIcon" size="20" />
                </span>
                <div>
                  <h6 class="mb-25">Email</h6>
                  <b-form-input
                    v-if="clientEditMode"
                    v-model="clientEmail"
                    type="text"
                  />
                  <b-card-text v-else>
                    {{ displayClientEmailText }}
                  </b-card-text>
                </div>
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center">
                <span class="mr-2">
                  <feather-icon icon="PhoneIcon" size="20" />
                </span>
                <div>
                  <h6 class="mb-25">Telèfon</h6>
                  <b-form-input
                    v-if="clientEditMode"
                    v-model="clientPhone"
                    type="text"
                  />
                  <b-card-text v-else>
                    {{ displayClientPhone }}
                  </b-card-text>
                </div>
              </b-list-group-item>
              <b-list-group-item
                v-if="registerLink"
                class="d-flex align-items-center"
              >
                <span class="mr-2">
                  <feather-icon icon="LinkIcon" size="20" />
                </span>
                <div>
                  <h6 class="mb-25">
                    Enllaç de registre
                    <small class="ml-50">
                      <b-link @click="$copyText(registerLink)"> Copia </b-link>
                    </small>
                  </h6>
                  <b-card-text class="d-none d-sm-block">
                    {{ registerLink }}
                  </b-card-text>
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-overlay>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save"> Guarda </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BBadge,
  BTooltip,
  VBTooltip,
  BButton,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BFormInput,
  BFormSpinbutton,
  BSpinner,
  BAlert,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";
import {
  getGuestsText,
  getNightsText,
  getOtaName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import vSelect from "vue-select";
import {
  bookingStatusOptions,
  otaOptions,
  idTypeOptions,
  countryOptions,
} from "@/utils/select-options";
import { AGENCY_GUESTS_INTRANET_REGISTER } from "@appConfig";
import BStatusBadge from "@foravila-core/components/b-status-badge/BStatusBadge.vue";
import BActionIcon from "@foravila-core/components/b-action-icon/BActionIcon.vue";
import BClient from "@foravila-core/components/b-client/BClient.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import VueContext from "vue-context";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BTooltip,
    BButton,
    BListGroup,
    BListGroupItem,
    BOverlay,
    vSelect,
    BFormInput,
    BFormSpinbutton,
    BSpinner,
    BAlert,
    BLink,
    BFormRadioGroup,
    BStatusBadge,
    BActionIcon,
    BClient,
    BAccommodation,
    VueContext,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      editMode: false,
      clientEditMode: false,
      highlighted: false,
      longTerm: false,
      fictitious: false,
      status: null,
      source: null,
      sourceLocalizator: null,
      bookingEmail: null,
      adults: null,
      children: null,
      babies: null,
      clientEmail: null,
      clientIdType: null,
      clientIdNumber: null,
      clientNationality: null,
      clientPhone: null,
      savingClient: false,
      loadingHighlighted: false,
      loadingLongTerm: false,
      loadingFictitious: false,
      countryOptions,
      bookingStatusOptions,
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    bookingDateTime() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(
          this.booking?.date,
          this.$i18n.locale,
          formatting
        ) || "No definit"
      );
    },
    bookingCheckin() {
      return formatDateStringToDate(this.booking?.checkin) || "No definit";
    },
    bookingCheckout() {
      return formatDateStringToDate(this.booking?.checkout) || "No definit";
    },
    bookingSourceName() {
      return getOtaName(this.booking.source) || "No definit";
    },
    bookingSourceLocalizator() {
      return this.booking?.sourceLocalizator || "No definit";
    },
    bookingNights() {
      return (
        getNightsText(this.booking?.nights, this.$i18n.locale) || "No definit"
      );
    },
    bookingGuests() {
      return (
        getGuestsText(
          {
            adults: this.booking?.adults || 0,
            children: this.booking?.children || 0,
            babies: this.booking?.babies || 0,
          },
          this.$i18n.locale
        ) || "No definit"
      );
    },
    accommodation() {
      return this.booking?.accommodation;
    },
    accommodationName() {
      return this.accommodation?.name || "No definit";
    },
    accommodationUuid() {
      return this.accommodation?.uuid || null;
    },
    loadingClient() {
      return this.$store.getters["booking/loadingClient"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientFullName() {
      return this.client?.fullName || "No definit";
    },
    clientUuid() {
      return this.client?.uuid || null;
    },
    clientHasIdNumber() {
      return !!this.client?.idNumber;
    },
    displayClientEmail() {
      return this.client?.email || null;
    },
    displayClientEmailText() {
      return this.client?.email || "No definit";
    },
    displayClientPhone() {
      return this.client?.phone || "No definit";
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    guestsNumberInconsistency() {
      if (this.guests.length === 0) return false;
      return this.guests.length !== this.booking.adults;
    },
    sourceOptions() {
      return otaOptions;
    },
    showBookingPolicyAlert() {
      if (!this.booking || this.booking.policy) return false;
      return this.booking.source === "FORAVILA";
    },
    isFictitious() {
      return this.booking?.fictitious || false;
    },
    registerLink() {
      if (!this.client) return null;
      const clientLocale = this.client?.language || "en";
      return `${AGENCY_GUESTS_INTRANET_REGISTER}/${this.client.uuid}?l=${clientLocale}`;
    },
    clientContactInfo() {
      const text = [];
      if (this.booking?.accommodation?.name)
        text.push(`(${this.booking.accommodation.name})`);
      if (this.clientFullName) text.push(this.clientFullName);
      if (this.displayClientPhone) text.push(this.displayClientPhone);
      return text.length ? text.join(" ") : null;
    },
    idTypeOptions() {
      return idTypeOptions.filter((option) => option.value !== "CIF");
    },
    canOpenLocalizatorInNewTab() {
      if (!this.booking?.source || !this.booking?.localizator) return false;
      return (
        this.booking.source !== "FORAVILA" ||
        this.booking.localizator.length === 6
      );
    },
    canOpenSourceLocalizatorInNewTab() {
      if (!this.booking?.source || !this.booking?.sourceLocalizator)
        return false;
      return ["AIRBNB", "FORAVILA"].includes(this.booking.source);
    },
  },
  watch: {
    editMode(edit) {
      if (edit) {
        this.initInputs();
      } else {
        this.resetInputs();
      }
    },
    clientEditMode(edit) {
      if (edit) this.initClientInputs();
      else this.resetClientInputs();
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          highlighted: this.highlighted,
          longTerm: this.longTerm,
          fictitious: this.fictitious,
          status: this.status,
          source: this.source,
          sourceLocalizator: this.sourceLocalizator,
          adults: this.adults,
          children: this.children,
          babies: this.babies,
          email: this.bookingEmail,
        })
        .then(() => {
          this.editMode = false;

          // Log the booking update
          this.$store.dispatch("booking/addLog", {
            booking: this.booking["@id"],
            type: "BOOKING_UPDATED",
            data: {
              highlighted: this.highlighted,
              longTerm: this.longTerm,
              fictitious: this.fictitious,
              status: this.status,
              source: this.source,
              sourceLocalizator: this.sourceLocalizator,
              bookingEmail: this.bookingEmail,
              adults: this.adults,
              children: this.children,
              babies: this.babies,
            },
          });
        })
        .catch(() =>
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la reserva`
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    saveClient() {
      try {
        this.savingClient = true;

        const clientObject = {
          uuid: this.client.uuid,
          email: this.clientEmail,
          phone: this.clientPhone,
          idType: this.clientIdType,
          idNumber: this.clientIdNumber,
          nationality: this.clientNationality,
        };

        this.$store
          .dispatch("booking/updateClient", clientObject)
          .then(() => {
            this.clientEditMode = false;
            // Log the client update
            this.$store.dispatch("booking/addLog", {
              booking: this.booking["@id"],
              type: "CLIENT_UPDATED",
              data: clientObject,
            });
          })
          .catch(() =>
            notifyError(
              "Error",
              "Hi ha hagut un error al intentar actualitzar el client"
            )
          )
          .finally(() => {
            this.savingClient = false;
          });
      } catch (error) {
        this.savingClient = false;
      }
    },
    setBookingHighlighted(highlightedValue) {
      this.loadingHighlighted = true;

      const text = `La reserva ha estat marcada com a ${
        highlightedValue ? "destacada" : "no destacada"
      }`;

      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          highlighted: !!highlightedValue,
        })
        .then(() => {
          notifySuccess("Reserva actualitzada", text);
          this.highlighted = !!highlightedValue;
        })
        .catch(() => {
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la reserva`
          );
        })
        .finally(() => {
          this.loadingHighlighted = false;
        });
    },
    setLongTermBooking(longTermValue) {
      this.loadingLongTerm = true;

      const text = `La reserva ha estat marcada com a ${
        longTermValue ? "llarga estada" : "vacacional"
      }`;

      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          longTerm: !!longTermValue,
        })
        .then(() => {
          notifySuccess("Reserva actualitzada", text);
          this.longTerm = !!longTermValue;
        })
        .catch(() =>
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la reserva`
          )
        )
        .finally(() => {
          this.loadingLongTerm = false;
        });
    },
    setBookingFictitious(fictitiousValue) {
      this.loadingFictitious = true;

      const text = `La reserva ha estat marcada com a ${
        fictitiousValue ? "especial" : "no especial"
      }`;

      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          fictitious: !!fictitiousValue,
        })
        .then(() => {
          notifySuccess("Reserva actualitzada", text);
          this.fictitious = !!fictitiousValue;
        })
        .catch(() => {
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la reserva`
          );
        })
        .finally(() => {
          this.loadingFictitious = false;
        });
    },
    setBookingFromFictitious() {
      // todo
    },
    initInputs() {
      this.highlighted = this.booking.highlighted || false;
      this.longTerm = this.booking.longTerm || false;
      this.fictitious = this.booking.fictitious || false;
      this.status = this.booking.status;
      this.source = this.booking.source;
      this.sourceLocalizator = this.booking.sourceLocalizator;
      this.bookingEmail = this.booking.email;
      this.adults = this.booking.adults;
      this.children = this.booking.children;
      this.babies = this.booking.babies;
    },
    initClientInputs() {
      this.clientEmail = this.client.email;
      this.clientPhone = this.client.phone;
      this.clientIdType = this.client.idType;
      this.clientIdNumber = this.client.idNumber;
      this.clientNationality = this.client.nationality;
    },
    resetInputs() {
      this.highlighted = this.booking.highlighted;
      this.longTerm = this.booking.longTerm;
      this.fictitious = this.booking.fictitious;
      this.status = null;
      this.source = null;
      this.sourceLocalizator = null;
      this.bookingEmail = null;
      this.adults = null;
      this.children = null;
      this.babies = null;
    },
    resetClientInputs() {
      this.clientEmail = null;
      this.clientPhone = null;
      this.clientIdType = null;
      this.clientIdNumber = null;
      this.clientNationality = null;
    },
    openLocalizatorInNewTab() {
      if (!this.canOpenLocalizatorInNewTab) return;
      window.open(
        `https://gero.icnea.net/HosOrdReserves.aspx?Recerca=${this.booking.localizator}`,
        "_blank"
      );
    },
    openSourceLocalizatorInNewTab() {
      if (!this.booking?.source || !this.booking?.sourceLocalizator) return;

      switch (this.booking.source) {
        case "AIRBNB":
          window.open(
            `https://www.airbnb.es/hosting/reservations?confirmationCode=${this.booking.sourceLocalizator}`,
            "_blank"
          );
          break;
        case "FORAVILA":
          if (this.booking.sourceLocalizator?.length !== 6) break;
          window.open(
            `https://gero.icnea.net/HosOrdReserves.aspx?Recerca=${this.booking.sourceLocalizator}`,
            "_blank"
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#summary-card {
  .v-select {
    min-width: 175px;
  }
  .status-select {
    margin-top: -7px;
  }
}
</style>
