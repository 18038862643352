<template>
  <div id="guest-form">
    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Informació personal</h4>
      </b-col>
      <!-- GENDER -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Gènere</h5>
          </b-col>
          <b-col cols="12">
            <b-form-radio-group
              v-model="newGuest.gender"
              :options="genderOptions"
              text-field="label"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- FIRST NAME -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Nom</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input
              v-model="newGuest.firstName"
              placeholder="Nom de l'hoste"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- LAST NAME -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Cognoms</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input
              v-model="newGuest.lastName"
              placeholder="Cognom/s de l'hoste"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- BIRTHDATE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Naixement</h5>
          </b-col>
          <b-col cols="12">
            <b-datepicker v-model="newGuest.birthDate" :max="new Date()" />
          </b-col>
        </b-row>
      </b-col>
      <!-- NATIONALITY -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Nacionalitat</h5>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="newGuest.nationality"
              placeholder="Nacionalitat"
              :options="countryOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Identificació</h4>
      </b-col>
      <!-- ID TYPE -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Tipus d'ID</h5>
          </b-col>
          <b-col cols="12">
            <b-form-radio-group
              v-model="newGuest.idType"
              :options="idTypeOptions"
              text-field="label"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- ID NUMBER -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Número</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input
              v-model="newGuest.idNumber"
              placeholder="Nº de document"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- ID ISSUE DATE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Expedició</h5>
          </b-col>
          <b-col cols="12">
            <b-datepicker v-model="newGuest.idIssueDate" :max="new Date()" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- SIGNATURE -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h4>
          Signatura
          <small
            class="cursor-pointer text-primary ml-25"
            @click="clearSignature()"
          >
            esborra
          </small>
        </h4>
      </b-col>
      <b-col v-if="guest && newGuest.signature" cols="12">
        <b-img
          v-if="newGuest.signature"
          class="guest-signature mb-1"
          :src="newGuest.signature"
          alt=""
          fluid
          center
        />
      </b-col>
      <b-col v-else cols="12">
        <vue-signature-pad
          id="signature-pad"
          ref="signaturePad"
          width="100%"
          height="250px"
          class="mb-1 cursor-pointer"
          :options="signaturePadOptions"
        />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BImg,
} from "bootstrap-vue";
import {
  countryOptions,
  genderOptions,
  idTypeOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";
import VueSignaturePad from "vue-signature-pad";
import BDatepicker from "@foravila-core/components/b-datepicker/BDatepicker.vue";

export default {
  name: "GuestForm",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    VueSignaturePad,
    BImg,
    BDatepicker,
  },
  props: {
    guest: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      newGuest: {
        firstName: null,
        lastName: null,
        nationality: null,
        gender: null,
        birthDate: null,
        birthDateInput: null,
        idType: null,
        idNumber: null,
        idIssueDate: null,
        signature: null,
      },
      signaturePadOptions: {
        penColor: "#000",
        onBegin: () => {
          if (this.$refs.signaturePad.isEmpty())
            this.$refs.signaturePad.resizeCanvas();
        },
      },
      datepickerOptions: {
        date: true,
        delimiter: "/",
        datePattern: ["d", "m", "Y"],
      },
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    countryOptions() {
      return countryOptions;
    },
    genderOptions() {
      return genderOptions;
    },
    idTypeOptions() {
      return idTypeOptions.filter((option) => option.value !== "CIF");
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  created() {
    if (this.guest) this.init();
  },
  methods: {
    init() {
      this.newGuest = {
        firstName: this.guest.firstName,
        lastName: this.guest.lastName,
        nationality: this.guest.nationality,
        gender: this.guest.gender,
        birthDate: this.guest.birthDate,
        idType: this.guest.idType,
        idNumber: this.guest.idNumber,
        idIssueDate: this.guest.idIssueDate,
        signature: this.guest.signature,
      };
    },
    reset() {
      this.newGuest = {
        firstName: null,
        lastName: null,
        nationality: null,
        gender: null,
        birthDate: null,
        idType: null,
        idNumber: null,
        idIssueDate: null,
        signature: null,
      };
      this.clearSignature();
    },
    cancel() {
      this.$emit("cancel");
    },
    clearSignature() {
      if (this.$refs.signaturePad) this.$refs.signaturePad.clearSignature();
      this.newGuest.signature = null;
    },
    save() {
      if (this.guest) this.editGuest();
      else this.createGuest();
    },
    createGuest() {
      this.$store.dispatch("app/setLoading", true);

      if (this.$refs.signaturePad) {
        const { data } = this.$refs.signaturePad.saveSignature();
        this.newGuest.signature = data || null;
      }

      setTimeout(() => {
        this.$store
          .dispatch("booking/addGuest", {
            booking: this.booking["@id"],
            ...this.newGuest,
            confirmed: !!this.newGuest.signature,
            confirmedDate: this.newGuest.signature ? new Date() : null,
          })
          .then(() => this.$emit("guest-added"))
          .catch(() => {
            this.$emit("add-guest-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    editGuest() {
      this.$store.dispatch("app/setLoading", true);

      if (this.$refs.signaturePad) {
        const { data } = this.$refs.signaturePad.saveSignature();
        this.newGuest.signature = data || null;
      }

      setTimeout(() => {
        this.$store
          .dispatch("booking/updateGuest", {
            uuid: this.guest.uuid,
            ...this.newGuest,
            confirmed: !!this.newGuest.signature,
            confirmedDate: this.newGuest.signature ? new Date() : null,
          })
          .then(() => this.$emit("guest-edited"))
          .catch(() => {
            this.$emit("edit-guest-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#guest-form {
  .guest-signature {
    max-width: 250px;
  }
  #signature-pad {
    border: solid 1px #d8d6de;
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}
</style>
