<template>
  <div>
    <span
      class="text-nowrap accommodation cursor-pointer align-items-center"
      @click.exact.left.stop="openAccommodation"
      @click.exact.left.meta.stop="openAccommodationInNewTab"
      @click.exact.left.ctrl.stop="openAccommodationInNewTab"
      @click.exact.middle.stop="openAccommodationInNewTab"
    >
      {{ name }}
    </span>
    <feather-icon
      v-if="cleaning"
      icon="FeatherIcon"
      size="14"
      class="ml-25 mb-25 text-info"
      v-b-tooltip.hover.top="'Neteja'"
    />
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
export default {
  props: {
    uuid: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    cleaning: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    accommodationViewRoute() {
      return {
        name: "foravila-accommodation-view",
        params: { accommodationUuid: this.uuid },
      };
    },
  },
  methods: {
    openAccommodation() {
      if (!this.uuid) return null;
      this.$router.push(this.accommodationViewRoute);
    },
    openAccommodationInNewTab() {
      if (!this.uuid) return null;
      const routeData = this.$router.resolve(this.accommodationViewRoute);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
span.accommodation:hover {
  color: #ffcd00;
}
</style>
