<template>
  <div v-if="booking" id="booking-view">
    <b-tabs no-fade>
      <!-- BOOKING TAB -->
      <b-tab active>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="InfoIcon" />
          <span class="d-none d-lg-block">{{ $t("Reserva") }}</span>
        </template>

        <b-row>
          <b-col cols="12" sm="12" md="8" lg="9">
            <summary-card />
            <booking-policy-card />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3">
            <tags-card v-if="!isFictitious" />
            <whats-app-notifications-card />
            <incidences-card v-if="!isFictitious" />
            <online-checkin-card v-if="!isFictitious" />
            <booking-actions-card />
            <comments-card />
            <danger-zone-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- PAYMENTS TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="DollarSignIcon" />
          <span class="d-none d-lg-block">{{ $t("Pagaments") }}</span>
        </template>

        <b-row>
          <b-col cols="12" sm="12" md="8" lg="9">
            <payments-summary-card />
            <security-deposit-card v-if="!isFictitious" />
            <payments-list-card v-if="!isFictitious" />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3">
            <tags-card v-if="!isFictitious" />
            <payments-actions-card v-if="!isFictitious" />
            <comments-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- SERVICES TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="LayersIcon" />
          <span class="d-none d-lg-block">{{ $t("Serveis") }}</span>
        </template>

        <b-row>
          <b-col cols="12" sm="12" md="8" lg="9">
            <services-card />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3">
            <tags-card v-if="!isFictitious" />
            <comments-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- CHECKIN TAB -->
      <b-tab v-if="!isFictitious">
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="UsersIcon" />
          <span class="d-none d-lg-block">{{ $t("Checkin Online") }}</span>
        </template>

        <b-row>
          <b-col cols="12" sm="12" md="8" lg="9">
            <schedules-card />
            <guests-card />
            <requests-card />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3">
            <tags-card />
            <online-checkin-card />
            <guests-actions-card />
            <comments-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- BILLING TAB -->
      <b-tab v-if="isAdmin && !isFictitious">
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="CreditCardIcon" />
          <span class="d-none d-lg-block">{{ $t("Facturació") }}</span>
        </template>

        <b-row>
          <b-col> resum facturació </b-col>
        </b-row>
      </b-tab>

      <!-- REVIEW TAB -->
      <b-tab v-if="!isFictitious">
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="MessageSquareIcon" />
          <span class="d-none d-lg-block">{{ $t("Ressenya") }}</span>
        </template>

        <b-row>
          <b-col cols="12" sm="12" md="8" lg="9">
            <review-card />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3">
            <tags-card />
            <!-- <review-actions-card /> -->
            <comments-card />
          </b-col>
        </b-row>
      </b-tab>

      <!-- LOGS TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="ListIcon" />
          <span class="d-none d-lg-block">{{ $t("Registres") }}</span>
        </template>

        <b-row>
          <b-col cols="12" sm="12" md="8" lg="9">
            <logs-card />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3">
            <tags-card v-if="!isFictitious" />
            <whats-app-notifications-card />
            <logs-actions-card v-if="isAdmin && !isFictitious" />
            <comments-card />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import SummaryCard from "@/views/bookings/booking/components/summary/SummaryCard.vue";
import SchedulesCard from "@/views/bookings/booking/components/checkin/SchedulesCard.vue";
import RequestsCard from "@/views/bookings/booking/components/checkin/RequestsCard.vue";
import BookingPolicyCard from "@/views/bookings/booking/components/summary/BookingPolicyCard.vue";
import GuestsCard from "@/views/bookings/booking/components/checkin/GuestsCard.vue";
import PaymentsSummaryCard from "@/views/bookings/booking/components/payments/PaymentsSummaryCard.vue";
import SecurityDepositCard from "@/views/bookings/booking/components/payments/SecurityDepositCard.vue";
import PaymentsListCard from "@/views/bookings/booking/components/payments/PaymentsListCard.vue";
import PaymentsActionsCard from "@/views/bookings/booking/components/payments/PaymentsActionsCard.vue";
import ReviewCard from "@/views/bookings/booking/components/review/ReviewCard.vue";
import BookingActionsCard from "@/views/bookings/booking/components/summary/BookingActionsCard.vue";
import OnlineCheckinCard from "@/views/bookings/booking/components/summary/OnlineCheckinCard.vue";
import TagsCard from "@/views/bookings/booking/components/TagsCard.vue";
import IncidencesCard from "@/views/bookings/booking/components/incidences/IncidencesCard.vue";
import CommentsCard from "@/views/bookings/booking/components/CommentsCard.vue";
import GuestsActionsCard from "@/views/bookings/booking/components/checkin/GuestsActionsCard.vue";
import ServicesCard from "@/views/bookings/booking/components/services/ServicesCard.vue";
import LogsCard from "@/views/bookings/booking/components/logs/LogsCard.vue";
import LogsActionsCard from "@/views/bookings/booking/components/logs/LogsActionsCard.vue";
import WhatsAppNotificationsCard from "@/views/bookings/booking/components/logs/WhatsAppNotificationsCard.vue";
import DangerZoneCard from "@/views/bookings/booking/components/DangerZoneCard.vue";

import { formatCurrency } from "@/utils/formatters";
// import ReviewActionsCard from "@/views/bookings/booking/components/review/ReviewActionsCard.vue";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    SummaryCard,
    SchedulesCard,
    RequestsCard,
    BookingPolicyCard,
    GuestsCard,
    PaymentsSummaryCard,
    SecurityDepositCard,
    PaymentsListCard,
    PaymentsActionsCard,
    ReviewCard,
    BookingActionsCard,
    OnlineCheckinCard,
    TagsCard,
    IncidencesCard,
    CommentsCard,
    GuestsActionsCard,
    ServicesCard,
    // ReviewActionsCard,
    LogsCard,
    LogsActionsCard,
    WhatsAppNotificationsCard,
    DangerZoneCard,
  },

  computed: {
    loadingBooking() {
      return this.$store.getters["booking/loadingSomething"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    isFictitious() {
      return this.booking?.fictitious || false;
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    loadingAccommodation() {
      return this.$store.getters["accommodation/loadingAccommodation"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    loadingServices() {
      return this.$store.getters["services/loading"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    loadingProviders() {
      return this.$store.getters["providers/loading"];
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    loadingPolicies() {
      return this.$store.getters["bookingPolicies/loading"];
    },
    policies() {
      return this.$store.getters["bookingPolicies/policies"];
    },
    loadingGlobalServices() {
      return this.$store.getters["globalServices/loading"];
    },
    globalServices() {
      return this.$store.getters["globalServices/services"];
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
  watch: {
    booking(booking) {
      this.setPageTitle(booking);
    },
    "$route.params.localizator"() {
      this.fetchBooking();
    },
  },
  mounted() {
    if (!this.loadingBooking) {
      this.fetchBooking();
    }

    if (!this.accommodations?.length && !this.loadingAccommodations) {
      this.fetchAccommodations();
    }

    if (!this.services?.length && !this.loadingServices) {
      this.fetchServices();
    }

    if (!this.providers?.length && !this.loadingProviders) {
      this.fetchProviders();
    }

    if (!this.policies?.length && !this.loadingPolicies) {
      this.fetchPolicies();
    }

    if (!this.globalServices?.length && !this.loadingGlobalServices) {
      this.fetchGlobalServices();
    }

    // Set page title
    this.setPageTitle(this.booking);
  },
  beforeDestroy() {
    // this.resetData();
    this.setPageTitle(null);
  },
  methods: {
    resetData() {
      this.$store.dispatch("booking/reset");
      this.$store.dispatch("accommodation/reset");
      this.setPageTitle(null);
    },
    fetchAccommodations() {
      this.$store
        .dispatch("accommodations/fetchAccommodations", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchAccommodations.title"),
            this.$t("errors.fetchAccommodations.description")
          )
        );
    },
    fetchBooking() {
      this.$store.dispatch("app/setLoading", true);

      this.resetData();

      const { localizator } = this.$route.params;

      this.$store
        .dispatch("booking/fetchBookingByLocalizator", localizator)
        .then(() => {
          // TODO: fetch other data
          this.$store
            .dispatch("booking/fetchServices", this.booking?.uuid)
            .finally(() => {
              this.$store
                .dispatch("booking/fetchClientPayments", this.booking?.uuid)
                .finally(() => this.notifyOverduePayment());
            });
          this.$store.dispatch(
            "booking/fetchClient",
            this.booking?.client?.uuid
          );
          this.$store.dispatch(
            "booking/fetchOwner",
            this.booking?.contract?.owner?.uuid
          );
          this.$store.dispatch("booking/fetchDetails", this.booking?.uuid);
          this.$store.dispatch("booking/fetchStats", this.booking?.uuid);
          this.$store.dispatch("booking/fetchIncidences", this.booking?.uuid);
          this.$store.dispatch("booking/fetchGuests", this.booking?.uuid);
          this.$store.dispatch(
            "booking/fetchReview",
            this.booking?.review?.uuid
          );
          this.$store.dispatch("booking/fetchRates", this.booking?.uuid);
          this.$store.dispatch("booking/fetchOwnerRates", this.booking?.uuid);
          this.$store.dispatch("booking/fetchComments", this.booking?.uuid);
          this.$store.dispatch("booking/fetchInvoices", this.booking?.uuid);
          this.$store.dispatch(
            "booking/fetchOwnerLiquidations",
            this.booking.uuid
          );
          this.$store.dispatch(
            "booking/fetchSpecialRequests",
            this.booking?.uuid
          );
          this.$store
            .dispatch(
              "accommodation/fetchAccommodation",
              this.booking?.accommodation?.uuid
            )
            .then(() => {
              this.$store.dispatch(
                "accommodation/fetchServices",
                this.accommodation.uuid
              );
              this.$store.dispatch(
                "accommodation/fetchAccommodationFaqs",
                this.accommodation.uuid
              );
              if (this.accommodation?.owner) {
                this.$store.dispatch(
                  "accommodation/fetchOwner",
                  this.accommodation.owner.uuid
                );
              }
              if (this.accommodation?.contract) {
                this.$store.dispatch(
                  "accommodation/fetchContract",
                  this.accommodation.contract.uuid
                );
              }
            });
          this.$store.dispatch("booking/fetchLogs", this.booking?.uuid);
        })
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBooking.title"),
            this.$t("errors.fetchBooking.description")
          );
        })
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    fetchServices() {
      this.$store
        .dispatch("services/fetchServices", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els serveis"
          )
        );
    },
    fetchProviders() {
      this.$store
        .dispatch("providers/fetchProviders", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els proveïdors"
          )
        );
    },
    fetchPolicies() {
      this.$store
        .dispatch("bookingPolicies/fetchPolicies", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPolicies.title"),
            this.$t("errors.fetchPolicies.description")
          )
        );
    },
    fetchGlobalServices() {
      this.$store
        .dispatch("globalServices/fetchServices", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchGlobalServices.title"),
            this.$t("errors.fetchGlobalServices.description")
          )
        );
    },
    setPageTitle(booking) {
      this.$store.dispatch("app/setPageTitle", booking?.localizator || null);
      this.$store.dispatch(
        "app/setPageSubtitle",
        booking?.accommodation?.name || null
      );
    },
    notifyOverduePayment() {
      if (
        !this.booking?.finalPaymentDueDate ||
        !this.pending ||
        this.pending <= 0
      )
        return;

      const finalPaymentDueDate = this.$moment(
        this.booking.finalPaymentDueDate.split("T")[0],
        "YYYY-MM-DD"
      );
      const today = this.$moment().startOf("day");

      if (
        !this.booking.managedPayment &&
        today.isSameOrAfter(finalPaymentDueDate, "day")
      ) {
        this.$swal({
          title: "Pagament pendent",
          text: `Hi ha un pagament pendent de ${formatCurrency(this.pending)}`,
          icon: "warning",
          confirmButtonText: "D'acord",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
