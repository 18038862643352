var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-divider"},[_c('div',{staticClass:"border-bottom border-top-0 border-left-0 border-right-0",class:{
      'border-style-dashed': _vm.dashed,
      'my-0': _vm.size === 'none',
      'my-25': _vm.size === 'xxs',
      'my-50': _vm.size === 'xs',
      'my-1': _vm.size === 'sm',
      'my-2': _vm.size === 'md',
      'my-3': _vm.size === 'lg',
      'my-4': _vm.size === 'xl',
      'my-5': _vm.size === 'xxl',
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }