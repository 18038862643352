<template>
  <div class="py-1">
    <!-- RATES SUMMARY -->
    <div
      v-for="rate in rates"
      :key="rate.uuid"
      class="d-flex justify-content-between mb-50"
    >
      <span class="text-grey d-flex align-items-center">
        {{ formatRateDate(rate.date) }}
      </span>
      <span>{{ formatCurrency(rate.price) }}</span>
    </div>

    <!-- UPDATE BOOKING DATES -->
    <b-row class="mt-2 d-flex">
      <b-col cols="12" sm="auto">
        <b-button
          variant="primary"
          size="sm"
          block
          @click="updateBookingDatesButtonClicked()"
        >
          Modifica dies
        </b-button>
      </b-col>
    </b-row>

    <!-- SEPARADOR -->
    <b-divider size="sm" />

    <!-- ACCOMMODATION -->
    <div class="d-flex justify-content-between font-weight-bold">
      <span>Allotjament</span>
      <span>{{ formatCurrency(accommodationPrice) }}</span>
    </div>

    <template v-if="discount">
      <!-- DISCOUNT -->
      <div class="d-flex justify-content-between my-50">
        <span class="text-grey">Descompte</span>
        <span>{{ formatCurrency(-discountPrice) }}</span>
      </div>

      <!-- SEPARADOR -->
      <b-divider size="sm" />

      <!-- SUBTOTAL -->
      <div class="d-flex justify-content-between font-weight-bold">
        <span>Allotjament <small>(amb descompte)</small></span>
        <span>{{ formatCurrency(subtotal) }}</span>
      </div>
    </template>

    <!-- SEPARADOR -->
    <b-divider
      v-if="chargableServices.length || unchargableServices.length"
      size="sm"
    />

    <!-- CHARGABLE SERVICES -->
    <div
      v-for="bookingService in chargableServices"
      :key="bookingService.uuid"
      class="d-flex justify-content-between mb-50"
    >
      <span class="text-grey">
        {{ serviceName(bookingService) }}
      </span>
      <span>{{ bookingServicePriceText(bookingService) }}</span>
    </div>

    <!-- UNCHARGABLE SERVICES -->
    <div
      v-for="bookingService in unchargableServices"
      :key="bookingService.uuid"
      class="d-flex justify-content-between mb-50"
    >
      <span class="text-grey">
        {{ serviceNameWithClientUnitPrice(bookingService) }}
      </span>
      <span v-if="hasProviderPayment(bookingService)">
        A pagar al proveïdor
      </span>
      <span v-else> A pagar a l'allotjament </span>
    </div>

    <!-- SEPARADOR -->
    <b-divider size="sm" />

    <!-- TOTAL -->
    <div class="d-flex justify-content-between font-weight-bold mb-2">
      <span>Total</span>
      <span>{{ formatCurrency(total) }}</span>
    </div>

    <div class="bg-light p-2">
      <!-- CHARGED AMOUNT -->
      <div class="d-flex justify-content-between mb-50">
        <span>Cobrat</span>
        <span>{{ formatCurrency(charged) }}</span>
      </div>

      <!-- REFUNDED AMOUNT -->
      <div class="d-flex justify-content-between mb-50 text">
        <span>Retornat</span>
        <span>{{ refundedText }}</span>
      </div>

      <!-- PENDING AMOUNT -->
      <div
        v-if="!booking.cancelled"
        class="d-flex justify-content-between"
        :class="{ 'text-danger font-weight-bold': pending > 0 }"
      >
        <span>Pendent</span>
        <span>{{ formatCurrency(pending) }}</span>
      </div>
    </div>

    <!-- UPDATE BOOKING DATES MODAL -->
    <b-modal
      :id="'update-booking-dates-modal'"
      title="Modifica les dates de la reserva"
      hide-footer
      centered
    >
      <booking-dates-form @booking-rates-updated="onBookingRatesUpdated" />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BModal, BButton } from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import BookingDatesForm from "@/views/bookings/booking/components/summary/BookingDatesForm.vue";
import {
  getBookingServiceClientTotalPriceText,
  getBookingServiceName,
  getBookingServiceNameWithClientUnitPrice,
  getPaymentFrequencyUnit,
} from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BDivider,
    BookingDatesForm,
    BModal,
    BButton,
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    services() {
      return this.$store.getters["booking/services"];
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    rates() {
      return this.$store.getters["booking/rates"];
    },
    accommodationPrice() {
      return this.$store.getters["booking/subtotal"];
    },
    subtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    total() {
      return this.$store.getters["booking/total"];
    },
    charged() {
      return this.$store.getters["booking/charged"];
    },
    refunded() {
      return this.$store.getters["booking/refunded"];
    },
    refundedText() {
      return this.refunded < 0
        ? formatCurrency(-this.refunded)
        : formatCurrency(this.refunded);
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    discount() {
      return this.$store.getters["booking/discount"];
    },
    discountPrice() {
      return this.$store.getters["booking/discountPrice"];
    },
  },
  methods: {
    formatRateDate(date) {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("No disponible")
      );
    },
    formatCurrency(price) {
      return formatCurrency(price) || this.$t("No disponible");
    },
    fetchServices() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/fetchServices", this.booking.uuid)
        .catch(() => {
          // TODO: log error to Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    updateBookingDatesButtonClicked() {
      this.$bvModal.show("update-booking-dates-modal");
    },
    onBookingRatesUpdated() {
      this.$bvModal.hide("update-booking-dates-modal");
    },
    hasProviderPayment(bookingService) {
      if (!bookingService.paymentTime) return false;
      return bookingService.paymentTime === "PROVIDER";
    },
    paymentFrequencyUnit(code) {
      return getPaymentFrequencyUnit(code, this.$i18n.locale);
    },
    bookingServicePriceText(bookingService) {
      return getBookingServiceClientTotalPriceText(
        bookingService,
        this.booking.nights,
        this.$i18n.locale
      );
    },
    serviceName(bookingService) {
      return getBookingServiceName(bookingService, this.$i18n.locale);
    },
    serviceNameWithClientUnitPrice(bookingService) {
      return getBookingServiceNameWithClientUnitPrice(
        bookingService,
        this.$i18n.locale
      );
    },
  },
};
</script>
