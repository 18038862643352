<template>
  <b-card title="Política de reserva">
    <b-overlay :show="loadingPolicies" variant="white" :opacity="1">
      <!-- POLICY PREVIEW -->
      <b-row>
        <b-col cols="12">
          <b-row class="mb-2">
            <b-col cols="12">
              <template v-if="policy">
                <p class="mb-50 font-weight-bold">Tipus de política</p>
                <p v-if="!editMode">
                  {{ policy.name }}
                  <b-badge
                    v-if="isLastMinute"
                    class="mr-50 mb-50"
                    variant="primary"
                  >
                    Last Minute
                  </b-badge>
                </p>
              </template>
              <template v-else>
                <p v-if="editMode" class="mb-50 font-weight-bold">
                  Tipus de política
                </p>
                <p v-if="!editMode">No hi ha cap política definida</p>
              </template>
              <v-select
                v-if="editMode"
                v-model="selectedPolicy"
                :options="bookingPoliciesOptions"
                :reduce="(option) => option.value"
              />
            </b-col>
          </b-row>
          <booking-policy-preview
            v-if="policy"
            :policy="policy"
            :locale="$i18n.locale"
          />
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save"> Guarda </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BOverlay, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import BookingPolicyPreview from "@/views/booking-policies/list/components/BookingPolicyPreview.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
    BookingPolicyPreview,
    BOverlay,
    BBadge,
  },
  data() {
    return {
      editMode: false,
      selectedPolicy: null,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    isLastMinute() {
      return this.$store.getters["booking/isLastMinute"];
    },
    loadingPolicies() {
      return this.$store.getters["bookingPolicies/loading"];
    },
    policies() {
      return this.$store.getters["bookingPolicies/policies"];
    },
    policy() {
      if (!this.selectedPolicy) return null;
      return this.policies.find((p) => p["@id"] === this.selectedPolicy);
    },
    bookingPoliciesOptions() {
      return this.policies.map((policy) => ({
        label: policy.name,
        value: policy["@id"],
      }));
    },
  },
  watch: {
    editMode() {
      this.initInputs();
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.selectedPolicy = this.booking?.policy
        ? this.booking?.policy["@id"]
        : null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          policy: this.selectedPolicy,
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar la reserva"
          )
        )
        .finally(() => {
          this.editMode = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
