<template>
  <div id="guest-card">
    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Informació personal</h4>
      </b-col>
      <!-- FULL NAME -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Nom complet</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ guest.fullName }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- GENDER -->
      <b-col cols="12" sm="4">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Gènere</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ genderName(guest.gender) }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- BIRTHDATE -->
      <b-col cols="12" sm="4">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Naixement</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ formattedDate(guest.birthDate) }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- NATIONALITY -->
      <b-col cols="12" sm="4">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Nacionalitat</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ countryName(guest.nationality) }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="12" class="mb-1">
        <h4>Identificació</h4>
      </b-col>
      <!-- ID TYPE -->
      <b-col cols="12" sm="4">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Tipus d'ID</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ idTypeName(guest.idType) }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- ID NUMBER -->
      <b-col cols="12" sm="4">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Número</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ guest.idNumber }}</span>
          </b-col>
        </b-row>
      </b-col>
      <!-- ID ISSUE DATE -->
      <b-col cols="12" sm="4">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Expedició</h5>
          </b-col>
          <b-col cols="12">
            <span>{{ formattedDate(guest.idIssueDate) }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- SIGNATURE -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h4>Signatura</h4>
      </b-col>
      <b-col cols="12">
        <b-img
          v-if="guest.signature"
          class="guest-signature"
          :src="guest.signature"
          alt=""
          fluid
          center
        />
        <b-alert class="mb-0" variant="warning" :show="!guest.signature">
          <div class="alert-body">Pendent de signatura</div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="4" sm="auto">
        <b-button block variant="success" @click="onConfirmGuestClicked()">
          Confirma
        </b-button>
      </b-col>
      <b-col cols="4" sm="auto">
        <b-button block variant="danger" @click="onDeleteGuestClicked()">
          Elimina
        </b-button>
      </b-col>
      <b-col cols="4" sm="auto">
        <b-button block variant="primary" @click="edit()"> Edita </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BImg, BAlert } from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import { getCountryName, getGenderName, getIdTypeName } from "@/utils/methods";

export default {
  name: "GuestCard",
  components: {
    BRow,
    BCol,
    BButton,
    BImg,
    BAlert,
  },
  props: {
    guest: {
      type: Object,
      required: true,
    },
  },
  methods: {
    edit() {
      this.$emit("edit", this.guest);
    },
    formattedDate(date) {
      return formatDateStringToDate(date);
    },
    countryName(code) {
      return getCountryName(code);
    },
    genderName(code) {
      return getGenderName(code);
    },
    idTypeName(code) {
      return getIdTypeName(code);
    },
    onDeleteGuestClicked() {
      this.$swal({
        title: "Eliminar hoste",
        text: "Estàs a punt d'eliminar aquest hoste, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteGuest();
      });
    },
    deleteGuest() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("booking/deleteGuest", this.guest.uuid)
          .then(() => this.$emit("guest-deleted"))
          .catch(() => {
            this.$emit("delete-guest-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    onConfirmGuestClicked() {
      this.$store
        .dispatch("booking/updateGuest", {
          uuid: this.guest.uuid,
          confirmed: true,
          confirmedDate: formatDateObjectToDatabaseDate(new Date()),
        })
        .then(() => this.$emit("guest-confirmed"))
        .catch(() => {
          this.$emit("confirm-guest-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        });
    },
  },
};
</script>

<style lang="scss">
#guest-card {
  .guest-signature {
    max-width: 250px;
  }
}
</style>
