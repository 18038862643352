<template>
  <b-badge :pill="pill" :variant="statusColor">
    {{ statusName }}
  </b-badge>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import { getBookingStatusName, getBookingStatusColor } from "@/utils/methods";

export default {
  components: {
    BBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    pill: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusName() {
      return getBookingStatusName(
        this.status,
        this.locale || this.$i18n.locale
      );
    },
    statusColor() {
      return getBookingStatusColor(
        this.status,
        this.locale || this.$i18n.locale
      );
    },
  },
};
</script>
