<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card title="Sumari">
      <b-tabs no-fade>
        <b-tab active>
          <template #title>
            <feather-icon class="mb-25 mb-lg-none" icon="BriefcaseIcon" />
            <span class="d-none d-lg-block">{{ $t("Client") }}</span>
          </template>
          <client-accounting-summary-tab />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon class="mb-25 mb-lg-none" icon="KeyIcon" />
            <span class="d-none d-lg-block">{{ $t("Propietari") }}</span>
          </template>
          <owner-accounting-summary-tab />
        </b-tab>
        <b-tab v-if="isAdmin && !isFictitious">
          <template #title>
            <feather-icon class="mb-25 mb-lg-none" icon="GlobeIcon" />
            <span class="d-none d-lg-block">{{ $t("Intermediari") }}</span>
          </template>
          <source-accounting-summary-tab />
        </b-tab>
        <b-tab v-if="isAdmin && !isFictitious">
          <template #title>
            <feather-icon class="mb-25 mb-lg-none" icon="PercentIcon" />
            <span class="d-none d-lg-block">{{ $t("Marges") }}</span>
          </template>
          <margins-accounting-summary-tab />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BTabs, BTab, BOverlay } from "bootstrap-vue";
import ClientAccountingSummaryTab from "@/views/bookings/booking/components/payments/ClientAccountingSummaryTab.vue";
import OwnerAccountingSummaryTab from "@/views/bookings/booking/components/payments/OwnerAccountingSummaryTab.vue";
import SourceAccountingSummaryTab from "@/views/bookings/booking/components/payments/SourceAccountingSummaryTab.vue";
import MarginsAccountingSummaryTab from "@/views/bookings/booking/components/payments/MarginsAccountingSummaryTab.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BTabs,
    BTab,
    ClientAccountingSummaryTab,
    OwnerAccountingSummaryTab,
    SourceAccountingSummaryTab,
    MarginsAccountingSummaryTab,
  },
  computed: {
    loadingRates() {
      return this.$store.getters["booking/loadingRates"];
    },
    loadingOwnerRates() {
      return this.$store.getters["booking/loadingOwnerRates"];
    },
    loadingPayments() {
      return this.$store.getters["booking/loadingClientPayments"];
    },
    loading() {
      return (
        this.loadingRates || this.loadingOwnerRates || this.loadingPayments
      );
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    isFictitious() {
      return this.booking?.fictitious || false;
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
  },
};
</script>
