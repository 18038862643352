<template>
  <div id="booking-accommodation-form">
    <!-- FORM -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Nou allotjament</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="accommodation"
          :options="accommodationOptions"
          :reduce="(option) => option.value"
          :loading="loadingAccommodations"
        />
      </b-col>
      <b-col cols="12" class="mt-2">
        <h6>ID de contracte</h6>
      </b-col>
      <b-col>
        {{ contractIri || "Sense contracte" }}
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="!accommodation"
          @click="save"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      accommodation: null,
    };
  },
  computed: {
    loadingAccommodations() {
      return this.$store.getters["accommodations/loading"];
    },
    accommodations() {
      return this.$store.getters["accommodations/accommodations"];
    },
    accommodationOptions() {
      if (!this.accommodations.length) {
        return [];
      }

      return this.accommodations.map((a) => ({
        label: a.name,
        value: a["@id"],
      }));
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      const accommodationObject = this.accommodations.find(
        (accommodation) => accommodation["@id"] === this.accommodation
      );
      return this.getAccommodationContract(accommodationObject);
    },
    contractIri() {
      return this.contract ? this.contract["@id"] : null;
    },
  },
  created() {
    this.initAccommodation();
  },
  methods: {
    initAccommodation() {
      this.accommodation = this.booking?.accommodation
        ? this.booking.accommodation["@id"]
        : null;
    },
    getAccommodationContract(accommodation) {
      if (!accommodation?.contract) {
        return null;
      }
      return accommodation.contract;
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          accommodation: this.accommodation,
          contract: this.contractIri,
        })
        .then(() => {
          this.editMode = false;

          // Log the booking update
          this.$store
            .dispatch("booking/addLog", {
              booking: this.booking["@id"],
              type: "BOOKING_UPDATED",
              data: {
                accommodation: this.accommodation,
                contract: this.contractIri,
              },
            })
            .finally(() => {
              this.$store.dispatch("app/setLoading", false);
              this.$emit("updated");
            });
        })
        .catch(() => {
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la reserva`
          );
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
