<template>
  <b-card title="Check-in online">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <b-col
          cols="4"
          class="d-flex justify-content-center flex-column text-center"
        >
          <span class="mb-50"> Client </span>
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner v-if="loadingClientOnlineCheckin" />
            <div
              v-else
              class="cursor-pointer"
              @click="toogleClientOnlineCheckin"
            >
              <feather-icon
                v-if="clientOnlineCheckinCompleted"
                icon="CheckIcon"
                size="28"
                class="text-success"
              />
              <feather-icon v-else icon="XIcon" size="28" class="text-danger" />
            </div>
          </div>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-center flex-column text-center"
        >
          <span class="mb-50"> Hostes </span>
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner v-if="loadingGuestsOnlineCheckin" />
            <div
              v-else
              class="cursor-pointer"
              @click="toogleGuestsOnlineCheckin"
            >
              <feather-icon
                v-if="guestsOnlineCheckinCompleted"
                icon="CheckIcon"
                size="28"
                class="text-success"
              />
              <feather-icon v-else icon="XIcon" size="28" class="text-danger" />
            </div>
          </div>
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-center flex-column text-center"
        >
          <span class="mb-50"> Horaris </span>
          <div class="d-flex justify-content-center align-items-center">
            <b-spinner v-if="loadingArrivalOnlineCheckin" />
            <div
              v-else
              class="cursor-pointer"
              @click="toogleArrivalOnlineCheckin"
            >
              <feather-icon
                v-if="arrivalOnlineCheckinCompleted"
                icon="CheckIcon"
                size="28"
                class="text-success"
              />
              <feather-icon v-else icon="XIcon" size="28" class="text-danger" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BSpinner } from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BSpinner,
  },
  data() {
    return {
      loadingClientOnlineCheckin: false,
      loadingGuestsOnlineCheckin: false,
      loadingArrivalOnlineCheckin: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loadingStats"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    clientOnlineCheckinCompleted() {
      return this.stats?.clientOnlineCheckinCompleted || false;
    },
    guestsOnlineCheckinCompleted() {
      return this.stats?.guestsOnlineCheckinCompleted || false;
    },
    arrivalOnlineCheckinCompleted() {
      return this.stats?.arrivalOnlineCheckinCompleted || false;
    },
  },
  methods: {
    toogleClientOnlineCheckin() {
      this.loadingClientOnlineCheckin = true;

      const completed = !this.clientOnlineCheckinCompleted;
      const completedDate = !completed
        ? null
        : formatDateObjectToDatabaseDate(new Date());

      this.$store
        .dispatch("booking/updateStats", {
          uuid: this.stats.uuid,
          clientOnlineCheckinCompleted: completed,
          clientOnlineCheckinCompletedAt: completedDate,
        })
        .then(() =>
          notifySuccess(
            `Actualitzat`,
            `La secció del client en el check-in online ha estat marcada com a ${
              completed ? "completada" : "no completada"
            }`
          )
        )
        .catch(() =>
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la secció del client en el check-in online, contacta amb en Pedro`
          )
        )
        .finally(() => (this.loadingClientOnlineCheckin = false));
    },
    toogleGuestsOnlineCheckin() {
      this.loadingGuestsOnlineCheckin = true;

      const completed = !this.guestsOnlineCheckinCompleted;
      const completedDate = !completed
        ? null
        : formatDateObjectToDatabaseDate(new Date());

      this.$store
        .dispatch("booking/updateStats", {
          uuid: this.stats.uuid,
          guestsOnlineCheckinCompleted: completed,
          guestsOnlineCheckinCompletedAt: completedDate,
        })
        .then(() =>
          notifySuccess(
            `Actualitzat`,
            `La secció dels hostes en el check-in online ha estat marcada com a ${
              completed ? "completada" : "no completada"
            }`
          )
        )
        .catch(() =>
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la secció dels hostes en el check-in online, contacta amb en Pedro`
          )
        )
        .finally(() => (this.loadingGuestsOnlineCheckin = false));
    },
    toogleArrivalOnlineCheckin() {
      this.loadingArrivalOnlineCheckin = true;

      const completed = !this.arrivalOnlineCheckinCompleted;
      const completedDate = !completed
        ? null
        : formatDateObjectToDatabaseDate(new Date());

      this.$store
        .dispatch("booking/updateStats", {
          uuid: this.stats.uuid,
          arrivalOnlineCheckinCompleted: completed,
          arrivalOnlineCheckinCompletedAt: completedDate,
        })
        .then(() =>
          notifySuccess(
            `Actualitzat`,
            `La secció dels horaris en el check-in online ha estat marcada com a ${
              completed ? "completada" : "no completada"
            }`
          )
        )
        .catch(() =>
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar actualitzar la secció dels horaris en el check-in online, contacta amb en Pedro`
          )
        )
        .finally(() => (this.loadingArrivalOnlineCheckin = false));
    },
  },
};
</script>
