<template>
  <b-card id="comments-card" title="Comentaris">
    <b-overlay :show="loadingSomething" variant="white" :opacity="1">
      <!-- COMMENTS -->
      <b-row>
        <b-col class="mb-2" cols="12">
          <div
            v-for="comment in comments"
            :key="`comment-${comment.uuid}`"
            class="mb-2"
          >
            <div class="font-weight-bold mb-50">
              <div>
                {{ getCommentAuthor(comment) }}
                <feather-icon
                  v-b-tooltip.hover.right="'Esborra aquest comentari'"
                  icon="Trash2Icon"
                  class="comment-action-icon cursor-pointer text-danger"
                  size="13"
                  @click="onDeleteCommentButtonClicked(comment)"
                />
              </div>
              <small class="text-muted">
                <span>
                  {{ formatPostedDate(comment.postedAt) }}
                </span>
              </small>
              <div v-if="getCommentScopeName(comment.scope)">
                <small>
                  <b-badge>
                    {{ getCommentScopeName(comment.scope) }}
                  </b-badge>
                </small>
              </div>
            </div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="bg-light p-1" v-html="comment.content" />
          </div>
        </b-col>
      </b-row>

      <!-- INPUTS -->
      <b-row class="mb-1">
        <b-col cols="12">
          <quill-editor
            v-model="newCommentContent"
            :options="editorOption"
            class="mb-2"
          />
        </b-col>
        <b-col cols="12">
          <v-select
            v-model="newCommentScope"
            :options="commentScopeOptions"
            :reduce="(option) => option.value"
            :searchable="false"
            class="w-100 mr-3"
          />
        </b-col>
      </b-row>

      <!-- BUTTON -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="12" md="auto">
          <b-button
            variant="primary"
            :disabled="!newCommentContent || loading"
            block
            @click="addComment"
          >
            Publicar
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BButton,
  BBadge,
} from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import {
  getBookingCommentAuthor,
  getCommentScopeName,
  notifyError,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";
import { commentScopeOptions } from "@/utils/select-options";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    quillEditor,
    BButton,
    vSelect,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      newCommentAuthor: "AGENCY",
      newCommentContent: null,
      newCommentScope: "GENERAL",
      selectedComment: null,
      editorOption: {
        placeholder: "Afegeix un comentari",
        modules: {
          toolbar: false,
        },
      },
      loading: false,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingSomething() {
      return this.loading || this.loadingComments;
    },
    loadingComments() {
      return this.$store.getters["booking/loadingComments"];
    },
    comments() {
      return this.$store.getters["booking/comments"];
    },
    sanitizedCommentContent() {
      if (!this.newCommentContent) return null;
      return this.newCommentContent.replace(/<p><br><\/p>/g, "").trim();
    },
    commentScopeOptions() {
      return commentScopeOptions;
    },
  },
  methods: {
    formatPostedDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    getCommentAuthor(comment) {
      return getBookingCommentAuthor(comment.author);
    },
    addComment() {
      this.loading = true;
      this.$store
        .dispatch("booking/addComment", {
          booking: this.booking["@id"],
          author: this.newCommentAuthor,
          content: this.sanitizedCommentContent,
          scope: this.newCommentScope,
        })
        .then(() => {
          this.newCommentContent = null;
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar afegir el comentari"
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    onDeleteCommentButtonClicked(comment) {
      this.selectedComment = comment;
      this.$swal({
        title: "Eliminar comentari",
        text: "Estàs a punt d'eliminar aquest comentari, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteComment();
      });
    },
    deleteComment() {
      this.loading = true;
      this.$store
        .dispatch("booking/deleteComment", this.selectedComment.uuid)
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar el comentari"
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getCommentScopeName(scope) {
      return getCommentScopeName(scope);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
