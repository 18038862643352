<template>
  <b-row class="p-1">
    <b-col cols="12">
      <b-tabs v-model="selectedTab" no-fade lazy align="center">
        <!-- CATALAN TAB -->
        <b-tab active title="Català" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="ca-ES"
            @content-updated="onContentUpdated"
          />
        </b-tab>

        <!-- SPANISH TAB -->
        <b-tab title="Castellano" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="es-ES"
            @content-updated="onContentUpdated"
          />
        </b-tab>

        <!-- ENGLISH TAB -->
        <b-tab title="English" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="en-GB"
            @content-updated="onContentUpdated"
          />
        </b-tab>

        <!-- DEUTSCH TAB -->
        <b-tab title="Deutsch" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="de-DE"
            @content-updated="onContentUpdated"
          />
        </b-tab>
      </b-tabs>
    </b-col>
    <b-col
      cols="12"
      class="send-button d-flex justify-content-end py-2 px-0 mt-2"
    >
      <b-button
        variant="secondary"
        :disabled="!contentWhatsAppText"
        @click="copyToClipboard(contentWhatsAppText)"
        class="mr-1"
      >
        Copia per WhatsApp
      </b-button>
      <b-button
        :disabled="!contentOwnerEmail"
        variant="primary"
        @click="sendEmail"
      >
        Envia
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BTabs, BTab, BButton } from "bootstrap-vue";
import {
  notifyError,
  copyToClipboard,
  getAdultsText,
  getBabiesText,
  getChildrensText,
} from "@/utils/methods";
import axios from "@/axios";

const OwnerEmailNotificationFormBookingConfirmation = () =>
  import(
    "@/views/bookings/booking/components/notifications/OwnerEmailNotificationFormBookingConfirmation.vue"
  );
const OwnerEmailNotificationFormBookingModification = () =>
  import(
    "@/views/bookings/booking/components/notifications/OwnerEmailNotificationFormBookingModification.vue"
  );
const OwnerEmailNotificationFormBookingCancellation = () =>
  import(
    "@/views/bookings/booking/components/notifications/OwnerEmailNotificationFormBookingCancellation.vue"
  );
const OwnerEmailNotificationFormCheckinInformation = () =>
  import(
    "@/views/bookings/booking/components/notifications/OwnerEmailNotificationFormCheckinInformation.vue"
  );
const OwnerEmailNotificationFormGuestsReport = () =>
  import(
    "@/views/bookings/booking/components/notifications/OwnerEmailNotificationFormGuestsReport.vue"
  );

export default {
  props: {
    notificationType: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return [
          "booking-confirmation",
          "booking-modification",
          "booking-cancellation",
          "checkin-information",
          "guests-report",
        ].includes(value);
      },
    },
  },
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
  },
  data() {
    return {
      selectedTab: null,
      content: null,
      copyToClipboard,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
    contentOwnerEmail() {
      return this.content?.ownerEmail || null;
    },
    tabComponent() {
      switch (this.notificationType) {
        case "booking-confirmation":
          return OwnerEmailNotificationFormBookingConfirmation;
        case "booking-modification":
          return OwnerEmailNotificationFormBookingModification;
        case "booking-cancellation":
          return OwnerEmailNotificationFormBookingCancellation;
        case "checkin-information":
          return OwnerEmailNotificationFormCheckinInformation;
        case "guests-report":
          return OwnerEmailNotificationFormGuestsReport;
        default:
          return null;
      }
    },
    postUrl() {
      const { locale = "ca-ES" } = this.content || {};

      switch (this.notificationType) {
        case "booking-confirmation":
          return `/mail/owner/booking-confirmation/${locale}`;
        case "booking-modification":
          return `/mail/owner/booking-modification/${locale}`;
        case "booking-cancellation":
          return `/notifications/owner/booking-cancellation`;
        case "checkin-information":
          return `/mail/owner/checkin-information/${locale}`;
        case "guests-report":
          return `/mail/owner/guests-report/${locale}`;
        default:
          return null;
      }
    },
    logType() {
      switch (this.notificationType) {
        case "booking-confirmation":
          return "OWNER_BOOKING_CONFIRMATION_SENT";
        case "booking-modification":
          return "OWNER_BOOKING_MODIFICATION_SENT";
        case "booking-cancellation":
          return "OWNER_BOOKING_CANCELLATION_SENT";
        case "checkin-information":
          return "OWNER_CHECKIN_INFORMATION_SENT";
        case "guests-report":
          return "OWNER_GUESTS_REPORT_SENT";
        default:
          return null;
      }
    },
    contentWhatsAppText() {
      switch (this.notificationType) {
        case "booking-confirmation":
          return this.bookingConfirmationWhatsAppText || null;
        case "booking-modification":
          return this.bookingModificationWhatsAppText || null;
        case "booking-cancellation":
          return this.bookingCancellationWhatsAppText || null;
        case "checkin-information":
          return this.checkinInformationWhatsAppText || null;
        case "guests-report":
          return this.guestsReportWhatsAppText || null;
        default:
          return null;
      }
    },
    checkinInformationWhatsAppText() {
      let text = [];
      const {
        locale,
        ownerName,
        clientName,
        accommodationName,
        localizator,
        checkinDay,
        checkinTime,
        checkoutDay,
        checkoutTime,
        adults,
        adultsAges,
        children,
        babies,
        selfCheckin,
        lockboxCode,
        requests,
        payments,
      } = this.content || {};

      // Intro
      const introText = [];
      introText.push(`Hola${ownerName ? " " + ownerName : ""} 👋\r\n`);
      introText.push(
        this.$t("emails.ownerInstructions.intro", locale, {
          clientName,
          localizator,
          accommodationName,
        })
      );
      text.push(introText.join("\r\n"));

      // Times
      const timesText = [];
      timesText.push(
        `*📆 ${this.$t("emails.ownerInstructions.timesTitle", locale)}*\r\n`
      );
      timesText.push(
        `- ${this.$t("Checkin", locale)}: ${checkinDay}${
          checkinTime ? " a les " + checkinTime : ""
        }`
      );
      timesText.push(
        `- ${this.$t("Checkout", locale)}: ${checkoutDay}${
          checkoutTime ? " a les " + checkoutTime : ""
        }`
      );
      timesText.push(
        `\r\n_${this.$t("emails.ownerInstructions.timesNote", locale)}_`
      );
      text.push(timesText.join("\r\n"));

      // Guests
      const guestsText = [];
      guestsText.push(
        `*👨‍👩‍👧‍👦 ${this.$t("emails.ownerInstructions.guestsTitle", locale)}*\r\n`
      );
      if (adults) {
        guestsText.push(
          `- ${getAdultsText(adults, locale)}${
            adultsAges ? " (" + adultsAges + ")" : ""
          }`
        );
      }
      if (children) {
        guestsText.push(`- ${getChildrensText(children, locale)}`);
      }
      if (babies) {
        guestsText.push(`- ${getBabiesText(babies, locale)}`);
      }
      text.push(guestsText.join("\r\n"));

      // Keys
      const keysText = [];
      keysText.push(
        `*🔑 ${this.$t("emails.ownerInstructions.keysTitle", locale)}*\r\n`
      );
      if (selfCheckin) {
        keysText.push(
          `- ${this.$t("emails.ownerInstructions.keysSelfCheckin", locale)}${
            lockboxCode
              ? " (" +
                this.$t("emails.ownerInstructions.keysLockboxCode", locale) +
                ": " +
                lockboxCode +
                ")"
              : ""
          }`
        );
      } else {
        keysText.push(
          `- ${this.$t("emails.ownerInstructions.keysOwnerCheckin", locale)}`
        );
      }
      text.push(keysText.join("\r\n"));

      // Requests
      if (requests?.length) {
        const requestsText = [];
        requestsText.push(
          `*🙋 ${this.$t(
            "emails.ownerInstructions.requestsTitle",
            locale
          )}*\r\n`
        );
        requests.forEach((request) => {
          requestsText.push(`- ${request.title}`);
        });
        text.push(requestsText.join("\r\n"));
      }

      // Payments
      if (payments?.length) {
        const paymentsText = [];
        paymentsText.push(
          `*💳 ${this.$t(
            "emails.ownerInstructions.paymentsTitle",
            locale
          )}*\r\n`
        );
        payments.forEach((payment) => {
          const { title, price, badgeText } = payment || {};
          paymentsText.push(
            `- ${title}: ${price}${badgeText ? " (_" + badgeText + "_)" : ""}`
          );
        });
        text.push(paymentsText.join("\r\n"));
      }

      // Result text
      text = text.length ? text.join("\r\n\r\n") : null;

      return text;
    },
  },
  mounted() {
    setTimeout(() => {
      // Timeout to give time to the modal to appear
      this.initTab();
    }, 50);
  },
  methods: {
    initTab() {
      if (!this.owner) {
        return;
      }

      const language = this.owner?.language || "ca";

      switch (language) {
        case "ca":
          this.selectedTab = 0;
          break;
        case "es":
          this.selectedTab = 1;
          break;
        case "en":
          this.selectedTab = 2;
          break;
        case "de":
          this.selectedTab = 3;
          break;
        default:
          this.selectedTab = 0;
          break;
      }
    },
    onContentUpdated(content) {
      this.content = content;
    },
    sendEmail() {
      try {
        this.$store.dispatch("app/setLoading", true);

        if (!this.contentOwnerEmail) {
          throw new Error(
            "El propietari d'aquesta reserva no disposa de correu electrònic"
          );
        }

        if (!this.postUrl) {
          throw new Error(
            "No hi ha cap url definida per aquest tipus de notificació"
          );
        }

        axios
          .post(this.postUrl, this.content)
          .then(() => {
            // Save the log entry, if needed
            if (this.logType) {
              this.$store.dispatch("booking/addLog", {
                booking: this.booking["@id"],
                type: this.logType,
                data: this.content,
              });
            }

            // Emit the success
            this.$emit("sent");
          })
          .catch(() => this.$emit("send-error"))
          .finally(() => this.$store.dispatch("app/setLoading", false));
      } catch (error) {
        notifyError("Error", error.message);
        this.$emit("send-error");
        this.$store.dispatch("app/setLoading", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-content,
.send-button {
  margin: 0 auto;
  max-width: 600px;
}
</style>
