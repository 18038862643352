<template>
  <div id="client-payment-form">
    <b-row class="mb-2">
      <!-- PAYMENT METHOD -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Mètode de pagament</h5>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="newPayment.method"
              placeholder="Selecciona un mètode"
              :options="paymentMethodOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- PAYMENT DATE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Data de pagament</h5>
          </b-col>
          <b-col cols="12">
            <b-form-datepicker
              v-model="newPayment.date"
              placeholder="Data depagament"
              :locale="locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- PAYMENT AMOUNT -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Import</h5>
          </b-col>
          <b-col cols="12">
            <b-input-group append="€" class="input-group-merge">
              <b-form-input
                v-model="newPayment.pvpAmount"
                type="number"
                placeholder="p.e. 1280"
                debounce="750"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- LOCALIZATOR -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Localitzador</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input
              v-model="newPayment.localizator"
              placeholder="p.e. 1624421309"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- SCOPE -->
      <b-col cols="12" :sm="showStatusSelect ? 6 : 12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Àmbit</h5>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="newPayment.scope"
              placeholder="Àmbit del pagament"
              :options="paymentScopeOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- STATUS -->
      <b-col v-if="showStatusSelect" cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Estat</h5>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="newPayment.status"
              placeholder="Estat del pagament"
              :options="paymentStatusOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- BOOKING SERVICE -->
      <b-col v-if="newPayment.scope === 'SERVICE'" cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Servei</h5>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="newPayment.bookingService"
              placeholder="Servei relacionat"
              :options="bookingServiceOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- CONFIRMED AT DATE -->
      <b-col v-if="payment" cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Data de confirmació</h5>
          </b-col>
          <b-col cols="12">
            <b-form-datepicker
              v-model="newPayment.confirmedAt"
              placeholder="Data confirmació"
              :locale="locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- VERIFIED AT DATE -->
      <b-col v-if="payment" cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Data de verificació</h5>
          </b-col>
          <b-col cols="12">
            <b-form-datepicker
              :disabled="!showVerifiedAtDate"
              v-model="newPayment.verifiedAt"
              placeholder="Data verificació"
              :locale="locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- COMMENT -->
      <b-col cols="12">
        <b-row class="mb-1">
          <b-col cols="12">
            <h5>Comentari</h5>
          </b-col>
          <b-col cols="12">
            <b-form-textarea
              v-model="newPayment.comment"
              placeholder="Comentari sobre el pagament"
              rows="2"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import {
  paymentMethodOptions,
  paymentScopeOptions,
  paymentStatusOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";
import {
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDatabaseDate,
} from "@/utils/formatters";
import { OTAS_WITH_SELF_MANAGED_PAYMENTS } from "@appConfig";
import { getServiceName } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BFormDatepicker,
    vSelect,
  },
  props: {
    payment: {
      type: Object,
      required: false,
      default: null,
    },
    refundForm: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      newPayment: {
        pvpAmount: null,
        method: null,
        date: new Date(),
        localizator: null,
        scope: null,
        comment: null,
        bookingService: null,
        confirmedAt: null,
        verifiedAt: null,
        status: null,
      },
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    services() {
      return this.$store.getters["booking/services"];
    },
    securityDepositServices() {
      return this.$store.getters["booking/securityDepositServices"];
    },
    touristTaxServices() {
      return this.$store.getters["booking/touristTaxServices"];
    },
    paymentMethodOptions() {
      return paymentMethodOptions;
    },
    paymentScopeOptions() {
      return paymentScopeOptions.filter((option) => {
        const isTouristTaxOption = option.value === "TOURIST_TAX";
        const hasTouristTaxServices = this.touristTaxServices.length > 0;

        const isSecurityDepositOption = option.value === "SECURITY_DEPOSIT";
        const hasSecurityDepositServices =
          this.securityDepositServices.length > 0;

        const isOtherOption = !["TOURIST_TAX", "SECURITY_DEPOSIT"].includes(
          option.value
        );

        return (
          (isTouristTaxOption && hasTouristTaxServices) ||
          (isSecurityDepositOption && hasSecurityDepositServices) ||
          isOtherOption
        );
      });
    },
    paymentStatusOptions() {
      return paymentStatusOptions;
    },
    bookingServiceOptions() {
      if (!this.services.length) {
        return [];
      }
      return this.services.map((bookingService) => ({
        label: getServiceName(bookingService.service, this.$i18n.locale),
        value: bookingService["@id"],
      }));
    },
    transformedPvpAmount() {
      if (!this.newPayment.pvpAmount && this.newPayment.pvpAmount !== 0)
        return null;
      return Math.round(this.newPayment.pvpAmount * 100);
    },
    selectedService() {
      const { scope, bookingService } = this.newPayment || {};

      if (scope === "SECURITY_DEPOSIT" && this.securityDepositServices.length) {
        return this.securityDepositServices[0]["@id"];
      }

      if (scope === "TOURIST_TAX" && this.touristTaxServices.length) {
        return this.touristTaxServices[0]["@id"];
      }

      if (scope === "SERVICE") {
        return bookingService;
      }

      return null;
    },
    selectedScope() {
      const { scope } = this.newPayment || {};

      if (scope !== "SERVICE") {
        return scope;
      }

      const isSecurityDeposit =
        this.securityDepositServices.length &&
        this.selectedService === this.securityDepositServices[0]["@id"];

      if (isSecurityDeposit) {
        return "SECURITY_DEPOSIT";
      }

      const isTouristTax =
        this.touristTaxServices.length &&
        this.selectedService === this.touristTaxServices[0]["@id"];

      if (isTouristTax) {
        return "TOURIST_TAX";
      }

      return scope;
    },
    paymentStatus() {
      if (this.newPayment.method === "VIRTUAL_CREDIT_CARD")
        return "PRE_CONFIRMED";
      return "CONFIRMED";
    },
    showStatusSelect() {
      return this.payment && !this.refundForm;
    },
    showVerifiedAtDate() {
      return ["VERIFIED", "ACCOUNTED"].includes(this.newPayment.status);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "newPayment.scope": function (value) {
      if (value !== "SERVICE") this.newPayment.bookingService = null;
    },
    // eslint-disable-next-line func-names
    "newPayment.method": function (value) {
      if (value !== "SOURCE_PAYMENT" || !this.booking) return;

      if (OTAS_WITH_SELF_MANAGED_PAYMENTS.includes(this.booking.source)) {
        const checkinStringDate = this.booking.checkin.split("T")[0];
        const dayAfterCheckin = this.$moment(
          checkinStringDate,
          "YYYY-MM-DD"
        ).add(1, "d");
        this.newPayment.date = dayAfterCheckin.format("YYYY-MM-DD");
      }
    },
  },
  created() {
    if (this.payment) {
      this.initPayment();
    }
    if (this.refundForm) {
      this.initRefund();
    }
  },
  methods: {
    initPayment() {
      const {
        pvpAmount = 0,
        method = null,
        date = null,
        localizator = null,
        scope = null,
        comment = null,
        bookingService = null,
        status = null,
        confirmedAt = null,
        verifiedAt = null,
      } = this.payment || {};

      this.newPayment = {
        pvpAmount: pvpAmount / 100,
        method,
        date: formatDateStringToDatabaseDate(date),
        localizator,
        scope,
        comment,
        bookingService: bookingService ? bookingService["@id"] : null,
        status,
        confirmedAt: formatDateStringToDatabaseDate(confirmedAt),
        verifiedAt: formatDateStringToDatabaseDate(verifiedAt),
      };
    },
    initRefund() {
      this.newPayment.comment = this.newPayment.comment
        ? `Devolució: ${this.newPayment.comment}`
        : "Devolució";
      this.newPayment.date = new Date();
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (this.payment && !this.refundForm) {
        this.editPayment();
      } else {
        this.createPayment();
      }
    },
    createPayment() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/addClientPayment", {
          booking: this.booking["@id"],
          ...this.newPayment,
          bookingService: this.selectedService,
          scope: this.selectedScope,
          pvpAmount: this.refundForm
            ? -this.transformedPvpAmount
            : this.transformedPvpAmount,
          status: this.paymentStatus,
          confirmedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          this.$emit("client-payment-added");
          this.$store.dispatch("booking/fetchServices", this.booking.uuid);
        })
        .catch(() => {
          this.$emit("client-payment-add-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    editPayment() {
      this.$store.dispatch("app/setLoading", true);

      setTimeout(() => {
        this.$store
          .dispatch("booking/updateClientPayment", {
            uuid: this.payment.uuid,
            ...this.newPayment,
            bookingService: this.selectedService,
            scope: this.selectedScope,
            pvpAmount: this.transformedPvpAmount,
            confirmedAt: formatDateObjectToDatabaseDateTime(new Date()),
          })
          .then(() => {
            this.$emit("client-payment-edited");
            this.$store.dispatch("booking/fetchServices", this.booking.uuid);
          })
          .catch(() => {
            this.$emit("client-payment-edit-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
