<template>
  <div class="b-action-icon d-inline">
    <feather-icon
      v-if="!loading"
      :id="`b-action-icon-${componentID}`"
      :ref="`b-action-icon-${componentID}`"
      v-b-tooltip.hover.top="tooltipText"
      :icon="icon"
      :size="size"
      :class="[computedClasses]"
      @click="toogleIcon"
    />
    <b-spinner
      v-else
      :style="`width: ${size - 2}px; height: ${size - 2}px; margin: 0 1px;`"
    />
  </div>
</template>

<script>
import { BSpinner, VBTooltip } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    iconVariant: {
      type: String,
      required: false,
      default: "primary",
    },
    size: {
      type: String,
      required: false,
      default: "20",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      componentID: "",
    };
  },
  created() {
    this.componentID = uuidv4();
  },
  computed: {
    computedClasses() {
      const classes = ["cursor-pointer"];

      if (this.value) {
        classes.push("fill-current");
        classes.push(`text-${this.iconVariant}`);
      }

      return classes.join(" ");
    },
  },
  methods: {
    toogleIcon() {
      this.$emit("click", !this.value);
    },
  },
};
</script>
