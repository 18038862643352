<template>
  <b-overlay :show="loadingLogs" rounded="sm" variant="white">
    <b-card title="Registre d'activitat">
      <b-row>
        <b-col cols="12">
          <app-timeline class="mt-1">
            <app-timeline-item
              v-for="(item, index) in timelineItems"
              :key="`timeline-item-${index}`"
              :variant="item.variant"
              :icon="item.icon"
            >
              <div class="font-weight-bold mb-50">
                <div>
                  {{ item.title }}
                </div>
                <div>
                  <small class="text-muted">
                    {{ formatItemDate(item.date) }}
                  </small>
                  <small
                    v-if="item.data"
                    class="text-muted cursor-pointer"
                    @click="showItemData(item.data)"
                  >
                    · Veure detalls
                  </small>
                </div>
              </div>
            </app-timeline-item>
          </app-timeline>

          <!-- DATA MODAL -->
          <b-modal
            id="timeline-item-data-modal"
            size="lg"
            hide-footer
            scrollable
          >
            <pre>{{ selectedItemData }}</pre>
          </b-modal>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BRow, BCol, BOverlay } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {
  getBookingLogTypeName,
  getBookingLogVariant,
  getBookingLogIcon,
  getOtaName,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  name: "TimelineCard",
  components: {
    BCard,
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BOverlay,
  },
  data() {
    return {
      timelineItems: [],
      selectedItemData: null,
    };
  },
  computed: {
    loadingLogs() {
      return this.$store.getters["booking/loadingLogs"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    logs() {
      return this.$store.getters["booking/logs"];
    },
  },
  watch: {
    booking() {
      this.setTimelineItems();
    },
    logs() {
      this.setTimelineItems();
    },
  },
  mounted() {
    this.setTimelineItems();
  },
  beforeDestroy() {
    this.timelineItems = [];
  },
  methods: {
    formatItemDate(date) {
      const formatting = {
        month: "long",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    addTimelineItem(item, sortType = "asc") {
      this.timelineItems.push(item);
      switch (sortType.toLowerCase()) {
        case "asc":
          this.timelineItems = this.timelineItems.sort((a, b) => {
            if (a.date < b.date) return 1;
            return b.date < a.date ? -1 : 0;
          });
          break;
        case "desc":
          this.timelineItems = this.timelineItems.sort((a, b) => {
            if (a.date > b.date) return 1;
            return b.date > a.date ? -1 : 0;
          });
          break;
        default:
          break;
      }
    },
    setTimelineItems() {
      this.timelineItems = [];

      // Booking date
      this.addTimelineItem({
        title: `Reserva realitzada a ${getOtaName(this.booking.source)}`,
        date: this.booking.date,
        variant: "success",
        icon: "BookIcon",
      });

      if (!this.logs.length) return;

      // Rest of booking logs
      this.logs.forEach((log) => {
        this.addTimelineItem({
          title: getBookingLogTypeName(log.type) || "Acció sense identificar",
          date: log.createdAt,
          variant: getBookingLogVariant(log.type),
          icon: getBookingLogIcon(log.type),
          data: log.data,
        });
      });
    },
    showItemData(data) {
      this.selectedItemData = data;
      this.$bvModal.show("timeline-item-data-modal");
    },
  },
};
</script>

<style lang="scss">
#booking-timeline {
  .timeline-item-time {
    margin-bottom: 5px;
  }
}
</style>
