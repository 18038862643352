<template>
  <b-card title="Accions">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-dropdown text="Modifica registres" variant="primary" block>
            <b-dropdown-item @click="toggleBookingFromFictitious">
              {{ receivedFromFictitious ? "Desmarca" : "Marca" }} com a rebuda a
              partir de fictícia
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    loadingBookingData(value) {
      this.loading = value;
    },
  },
  computed: {
    loadingBookingData() {
      return this.$store.getters["booking/loadingSomething"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    receivedFromFictitious() {
      return this.stats?.receivedFromFictitious || false;
    },
  },
  methods: {
    toggleBookingFromFictitious() {
      this.loading = true;
      this.$store
        .dispatch("booking/updateStats", {
          uuid: this.stats.uuid,
          receivedFromFictitious: !this.receivedFromFictitious,
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
