<template>
  <div id="new-incidence-form" class="pb-1">
    <b-row class="mb-1">
      <!-- ESTAT -->
      <b-col cols="12" lg="4">
        <b-form-group :label="$t('Estat')" label-for="status">
          <v-select
            id="status"
            v-model="status"
            :options="bookingIncidenceStatusOptions"
            :reduce="(option) => option.value"
            :searchable="false"
            class="w-100 mb-1"
          />
        </b-form-group>
      </b-col>

      <!-- TITOL -->
      <b-col cols="12">
        <b-form-group :label="$t('Títol')" label-for="title">
          <b-form-input
            id="title"
            v-model="title"
            placeholder="Títol de la incidència"
            class="mb-1"
          />
        </b-form-group>
      </b-col>

      <!-- COMENTARIS -->
      <b-col cols="12" class="mb-1">
        <b-form-group :label="$t('Descripció')" label-for="comments">
          <quill-editor
            id="comment"
            v-model="comment"
            :options="editorOption"
            class="mb-1 w-100"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-end">
      <b-col cols="12" md="auto" class="mb-1 mb-md-0">
        <b-button variant="secondary" block @click="close"> Tanca </b-button>
      </b-col>
      <b-col cols="12" md="auto">
        <b-button variant="primary" :disabled="!title" block @click="save">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import { bookingIncidenceStatusOptions } from "@/utils/select-options";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    quillEditor,
    vSelect,
  },
  props: {
    incidence: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      status: null,
      title: null,
      comment: null,
      editorOption: {
        placeholder: this.$t("Descripció de la incidència"),
        modules: {
          toolbar: false,
        },
      },
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loggedUserEmail() {
      return this.$store.getters["auth/loggedUserEmail"];
    },
    sanitizedComment() {
      if (!this.comment) return null;
      return this.comment.replace(/<p><br><\/p>/g, "").trim();
    },
    bookingIncidenceStatusOptions() {
      return bookingIncidenceStatusOptions.filter(
        (option) => option.value !== "CLOSED"
      );
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.status = "OPEN";
      this.title = null;
      this.comment = null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      const comments = this.comment?.length
        ? [
            {
              author: this.loggedUserEmail,
              content: this.sanitizedComment,
              isSolution: false,
              updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
            },
          ]
        : [];

      this.$store
        .dispatch("booking/addIncidence", {
          booking: this.booking["@id"],
          title: this.title,
          status: this.status,
          updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
          comments,
        })
        .then(() => {
          notifySuccess(
            "Incidència creada",
            "La incidència ha estat creada correctament"
          );
          this.$emit("new-incidence-close");
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar crear la incidència"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    close() {
      this.$emit("new-incidence-close");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
