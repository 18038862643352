<template>
  <b-overlay :show="loadingPayments || loading" rounded="sm" variant="white">
    <b-card id="security-deposit-card" title="Fiança">
      <!-- NO SECURITY DEPOSIT ALERTS -->
      <b-alert
        v-if="hasManagedSecurityDeposit"
        variant="warning"
        :show="true"
        class="my-2"
      >
        <div class="alert-body font-weight-normal">
          El portal intermediari gestiona la fiança d'aquesta reserva
        </div>
      </b-alert>

      <template v-else>
        <template
          v-if="
            securityDepositCondoned &&
            (!hasSecurityDeposit ||
              (hasSecurityDeposit && !hasChargableSecurityDeposit))
          "
        >
          <b-alert variant="warning" :show="true" class="my-2">
            <div class="alert-body font-weight-normal">
              La fiança d'aquesta reserva ha estat condonada i els clients no
              l'han de pagar
            </div>
          </b-alert>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="toogleCondonedSecurityDeposit">
              Descondonar fiança
            </b-button>
          </div>
        </template>

        <template v-if="!securityDepositCondoned">
          <b-alert
            variant="warning"
            :show="true"
            class="my-2"
            v-if="!hasSecurityDeposit"
          >
            <div class="alert-body font-weight-normal">
              El propietari d'aquest allotjament no requereix fiança per a
              aquesta reserva
            </div>
          </b-alert>

          <b-alert
            v-if="hasSecurityDeposit && !hasChargableSecurityDeposit"
            variant="warning"
            :show="true"
            class="my-2"
          >
            <div class="alert-body font-weight-normal">
              El propietari d'aquest allotjament gestiona la fiança pel seu
              compte
            </div>
          </b-alert>

          <div
            v-if="
              !hasSecurityDeposit ||
              (hasSecurityDeposit && !hasChargableSecurityDeposit)
            "
          >
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                @click="toogleCondonedSecurityDeposit"
              >
                Condonar fiança
              </b-button>
            </div>
          </div>
        </template>

        <template v-if="hasSecurityDeposit && hasChargableSecurityDeposit">
          <b-list-group class="mb-1" flush>
            <!-- TOTAL PRICE -->
            <b-list-group-item class="d-flex justify-content-sm-between">
              <div>
                <h6 class="mb-25">Import</h6>
                <b-card-text>
                  {{ formatCurrency(securityDepositPrice) }}
                </b-card-text>
              </div>
              <div class="d-flex align-items-center">
                <!-- NO REFUNDS MADE -->
                <template v-if="securityDepositRefunds.length === 0">
                  <b-badge
                    v-if="securityDepositChargedPrice === 0"
                    variant="danger"
                    class="mx-25"
                  >
                    Pendent
                  </b-badge>
                  <b-badge
                    v-if="
                      securityDepositChargedPrice > 0 &&
                      securityDepositChargedPrice < securityDepositPrice
                    "
                    variant="danger"
                    class="mx-25"
                  >
                    Insuficient
                  </b-badge>
                  <b-badge
                    v-if="securityDepositChargedPrice === securityDepositPrice"
                    variant="success"
                    class="mx-25"
                  >
                    Cobrada
                  </b-badge>
                  <b-badge
                    v-if="securityDepositChargedPrice > securityDepositPrice"
                    variant="success"
                    class="mx-25"
                  >
                    Cobrada en excés
                  </b-badge>
                </template>
                <!-- REFUNDS MADE -->
                <template v-if="securityDepositRefunds.length > 0">
                  <b-badge
                    v-if="
                      securityDepositRefundedPrice < securityDepositChargedPrice
                    "
                    variant="warning"
                    class="mx-25"
                  >
                    Parcialment retornada
                  </b-badge>
                  <b-badge
                    v-if="
                      securityDepositRefundedPrice ===
                      securityDepositChargedPrice
                    "
                    variant="success"
                    class="mx-25"
                  >
                    Retornada
                  </b-badge>
                  <b-badge
                    v-if="
                      securityDepositRefundedPrice > securityDepositChargedPrice
                    "
                    variant="danger"
                    class="mx-25"
                  >
                    Retornada en excés
                  </b-badge>
                </template>
              </div>
            </b-list-group-item>

            <!-- CHARGED PRICE -->
            <b-list-group-item
              id="deposit-payments-summary"
              class="d-flex justify-content-sm-between"
              :class="{ 'cursor-pointer': securityDepositPayments.length > 0 }"
              @click="showSecurityDepositPaymentsDetail"
            >
              <div>
                <h6 class="mb-25">Import cobrat</h6>
                <b-card-text>
                  {{ formatCurrency(securityDepositChargedPrice) }}
                  <template v-if="securityDepositChargedPrice > 0">
                    <span v-if="securityDepositPaymentDifference > 0">
                      (pagats
                      {{ formatCurrency(securityDepositPaymentDifference) }}
                      de més)
                    </span>
                    <span v-if="securityDepositPaymentDifference < 0">
                      (pagats
                      {{ formatCurrency(-securityDepositPaymentDifference) }}
                      de menys)
                    </span>
                  </template>
                </b-card-text>
              </div>
              <div
                v-if="securityDepositComments.length"
                class="d-flex align-items-center mr-2"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  class="text-primary"
                  size="25"
                />
              </div>
            </b-list-group-item>
            <b-tooltip
              v-if="securityDepositPayments.length > 0"
              target="deposit-payments-summary"
              triggers="hover"
              no-fade
              placement="top"
              :delay="{ show: 1000, hide: 0 }"
            >
              Clicka per veure el llistat de pagaments realitzats
            </b-tooltip>

            <!-- REFUNDED PRICE -->
            <b-list-group-item
              :class="{ 'cursor-pointer': securityDepositRefunds.length > 0 }"
              @click="showSecurityDepositRefundsDetail"
            >
              <div id="deposit-refunds-summary">
                <h6 class="mb-25">Import retornat</h6>
                <b-card-text>
                  {{ formatCurrency(securityDepositRefundedPrice) }}
                  <template v-if="securityDepositRefundedPrice > 0">
                    <span v-if="securityDepositRefundDifference > 0">
                      (retornats
                      {{ formatCurrency(securityDepositRefundDifference) }}
                      de menys)
                    </span>
                    <span v-if="securityDepositRefundDifference < 0">
                      (retornats
                      {{ formatCurrency(-securityDepositRefundDifference) }}
                      de més)
                    </span>
                  </template>
                </b-card-text>
              </div>
            </b-list-group-item>
            <b-tooltip
              v-if="securityDepositRefunds.length > 0"
              target="deposit-refunds-summary"
              triggers="hover"
              no-fade
              placement="top"
              :delay="{ show: 1000, hide: 0 }"
            >
              Clicka per veure el llistat de devolucions realitzades
            </b-tooltip>
          </b-list-group>

          <!-- BUTTONS -->
          <b-row class="d-flex justify-content-between justify-content-sm-end">
            <b-col cols="6" sm="auto">
              <b-button block variant="primary" @click="onAddPaymentClicked">
                Afegeix fiança
              </b-button>
            </b-col>
            <b-col cols="6" sm="auto">
              <b-button block variant="primary" @click="onAddRefundClicked">
                Retorna fiança
              </b-button>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>

    <!-- SECURITY DEPOSIT PAYMENTS MODAL -->
    <b-modal
      :id="'security-deposit-payments-modal'"
      title="Import cobrat"
      scrollable
      hide-footer
      centered
      size="lg"
    >
      <h4 class="my-2">Llistat de pagaments</h4>
      <client-payments-table
        :payments="securityDepositPayments"
        localizator
        details-button
        refund-button
        confirm-button
        delete-button
        @payment-details="onShowPaymentDetails"
        @payment-delete="onDeletePayment"
        @payment-refund="onGenerateRefund"
        @payment-confirm="onConfirmPayment"
      />
      <h4 class="my-2">Comentaris sobre la fiança</h4>
      <b-alert
        class="mb-2"
        variant="warning"
        :show="!securityDepositComments.length"
      >
        <div class="alert-body">Sense comentaris</div>
      </b-alert>
      <template v-if="securityDepositComments.length">
        <div
          v-for="comment in securityDepositComments"
          :key="`comment-${comment.uuid}`"
          class="mb-2"
        >
          <div class="font-weight-bold mb-50">
            <div>
              {{ getCommentAuthor(comment) }}
            </div>
            <small class="text-muted">
              <span>
                {{ formatPostedDate(comment.postedAt) }}
              </span>
            </small>
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="comment.content" />
        </div>
      </template>
    </b-modal>

    <!-- SECURITY DEPOSIT REFUNDS MODAL -->
    <b-modal
      :id="'security-deposit-refunds-modal'"
      title="Llistat de devolucions"
      scrollable
      hide-footer
      centered
      size="lg"
    >
      <client-payments-table
        :payments="securityDepositRefunds"
        localizator
        details-button
        delete-button
        @payment-details="onShowPaymentDetails"
        @payment-delete="onDeletePayment"
      />
    </b-modal>

    <!-- ADD SECURITY DEPOSIT MODAL -->
    <b-modal
      :id="'security-deposit-payment-modal'"
      title="Afegeix un pagament de fiança"
      scrollable
      hide-footer
      centered
    >
      <security-deposit-payment-form
        :service="securityDeposit"
        @security-deposit-added="onSecurityDepositAdded"
        @cancel="onAddSecurityDepositCancel"
      />
    </b-modal>

    <!-- REFUND SECURITY DEPOSIT MODAL -->
    <b-modal
      :id="'security-deposit-refund-modal'"
      title="Afegeix una devolució de fiança"
      scrollable
      hide-footer
      centered
    >
      <security-deposit-refund-form
        :service="securityDeposit"
        :base-payment="selectedPayment"
        @security-deposit-refunded="onSecurityDepositRefunded"
        @cancel="onRefundSecurityDepositCancel"
      />
    </b-modal>

    <!-- CLIENT PAYMENT DETAILS MODAL -->
    <b-modal
      :id="'security-deposit-details-modal'"
      title="Detalls de l'operació"
      hide-footer
      centered
    >
      <client-payment-details :payment="selectedPayment" />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BCardText,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BModal,
  BTooltip,
  BButton,
} from "bootstrap-vue";
import {
  formatCurrency,
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";
import ClientPaymentsTable from "@/views/bookings/booking/components/payments/ClientPaymentsTable.vue";
import SecurityDepositPaymentForm from "@/views/bookings/booking/components/payments/SecurityDepositPaymentForm.vue";
import SecurityDepositRefundForm from "@/views/bookings/booking/components/payments/SecurityDepositRefundForm.vue";
import ClientPaymentDetails from "@/views/bookings/booking/components/payments/ClientPaymentDetails.vue";
import {
  getBookingCommentAuthor,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  name: "SecurityDepositCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardText,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    ClientPaymentsTable,
    BModal,
    BTooltip,
    BButton,
    SecurityDepositPaymentForm,
    SecurityDepositRefundForm,
    ClientPaymentDetails,
  },
  data() {
    return {
      loading: false,
      selectedPayment: null,
      formatCurrency,
    };
  },
  computed: {
    loadingPayments() {
      return this.$store.getters["booking/loadingClientPayments"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    hasManagedSecurityDeposit() {
      return this.$store.getters["booking/hasManagedSecurityDeposit"];
    },
    securityDepositServices() {
      return this.$store.getters["booking/securityDepositServices"];
    },
    securityDeposit() {
      return this.securityDepositServices.length
        ? this.securityDepositServices[0]
        : null;
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    securityDepositPayments() {
      return this.$store.getters["booking/securityDepositPayments"];
    },
    securityDepositRefunds() {
      return this.$store.getters["booking/securityDepositRefunds"];
    },
    securityDepositChargedPrice() {
      return this.$store.getters["booking/securityDepositChargedPrice"];
    },
    securityDepositRefundedPrice() {
      return this.$store.getters["booking/securityDepositRefundedPrice"];
    },
    securityDepositPaymentDifference() {
      return this.securityDepositChargedPrice - this.securityDepositPrice;
    },
    securityDepositRefundDifference() {
      return (
        this.securityDepositChargedPrice - this.securityDepositRefundedPrice
      );
    },
    securityDepositComments() {
      return this.$store.getters["booking/comments"].filter(
        (comment) => comment.scope === "SECURITY_DEPOSIT"
      );
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    securityDepositCondoned() {
      return this.stats?.securityDepositCondoned || false;
    },
  },
  methods: {
    showSecurityDepositPaymentsDetail() {
      if (this.securityDepositPayments.length === 0) return;
      this.$bvModal.show("security-deposit-payments-modal");
    },
    showSecurityDepositRefundsDetail() {
      if (this.securityDepositRefunds.length === 0) return;
      this.$bvModal.show("security-deposit-refunds-modal");
    },
    onAddPaymentClicked() {
      this.selectedPayment = null;
      this.$bvModal.show("security-deposit-payment-modal");
    },
    onSecurityDepositAdded() {
      this.$bvModal.hide("security-deposit-payment-modal");
    },
    onAddSecurityDepositCancel() {
      this.$bvModal.hide("security-deposit-payment-modal");
    },
    onAddRefundClicked() {
      this.selectedPayment = null;
      this.$bvModal.show("security-deposit-refund-modal");
    },
    onSecurityDepositRefunded() {
      this.$bvModal.hide("security-deposit-refund-modal");
    },
    onRefundSecurityDepositCancel() {
      this.$bvModal.hide("security-deposit-refund-modal");
    },
    onGenerateRefund(payment) {
      this.selectedPayment = payment;
      this.$bvModal.hide("security-deposit-payments-modal");
      this.$bvModal.show("security-deposit-refund-modal");
    },
    onShowPaymentDetails(payment) {
      this.selectedPayment = payment;
      this.$bvModal.show("security-deposit-details-modal");
    },
    onConfirmPayment(payment) {
      this.selectedPayment = payment;
    },
    onDeletePayment(payment) {
      this.selectedPayment = payment;
      this.$swal({
        title: "Eliminar pagament",
        text: "Estàs a punt d'eliminar aquest pagament, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deletePayment();
      });
    },
    deletePayment() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/deleteClientPayment", this.selectedPayment.uuid)
        .then(() => {
          notifySuccess(
            "Pagament eliminat",
            "El pagament ha estat eliminat correctament."
          );
          this.$store.dispatch("booking/fetchServices", this.booking.uuid);
        })
        .catch(() => {
          notifyError(
            "Pagament no eliminat",
            "Hi ha hagut un error al eliminar el pagament."
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    getCommentAuthor(comment) {
      return getBookingCommentAuthor(comment.author);
    },
    formatPostedDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    toogleCondonedSecurityDeposit() {
      this.loading = true;

      const condoned = !this.securityDepositCondoned;
      const condonedDate = !condoned
        ? null
        : formatDateObjectToDatabaseDateTime(new Date());

      this.$store
        .dispatch("booking/updateStats", {
          uuid: this.stats.uuid,
          securityDepositCondoned: condoned,
          securityDepositCondonedAt: condonedDate,
        })
        .then(() =>
          notifySuccess(
            `Actualitzat`,
            `La fiança ha estat marcada com a ${
              condoned ? "condonada" : "no condonada"
            }`
          )
        )
        .catch(() =>
          notifyError(
            "Error",
            `Hi ha hagut un error al intentar marcar aquesta fiança com a ${
              condoned ? "condonada" : "no condonada"
            }, contacta amb en Pedro`
          )
        )
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss">
#security-deposit-card {
  .list-group-item {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
