<template>
  <div class="py-1">
    <!-- RATES SUMMARY -->
    <b-alert class="mb-0" variant="danger" :show="!rates.length">
      <div class="alert-body">Tarifes propietari pendents de definir</div>
    </b-alert>

    <div
      v-for="(rate, rateIndex) in rates"
      :key="`booking-rate-${rateIndex}`"
      class="d-flex justify-content-between mb-50"
    >
      <span class="text-grey d-flex align-items-center">
        {{ formatRateDate(rate.date) }}
      </span>
      <span>{{ formatCurrency(rate.ownerPrice) }}</span>
    </div>

    <!-- UPDATE BOOKING DATES -->
    <b-row class="mt-2 d-flex">
      <b-col cols="12" sm="auto" class="d-flex align-items-center">
        <b-button
          variant="primary"
          size="sm"
          @click="modifyBookingOwnerRatesButtonClicked()"
          class="mr-1"
        >
          Modifica imports
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          @click="updateBookingOwnerRatesButtonClicked()"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-col>
    </b-row>

    <!-- SEPARADOR -->
    <b-divider class="my-1" />

    <!-- SUBTOTAL -->
    <div
      class="d-flex justify-content-between font-weight-bold"
      :class="{
        'mb-2':
          ownerChargableServices.length > 0 ||
          ownerUnchargableServices.length > 0,
      }"
    >
      <span>Subtotal</span>
      <span>{{ formatCurrency(ownerSubtotal) }}</span>
    </div>

    <!-- CHARGABLE SERVICES -->
    <div
      v-for="bookingService in ownerChargableServices"
      :key="`servei-${bookingService.uuid}`"
      class="d-flex justify-content-between mb-50"
    >
      <span class="text-grey">
        {{ serviceName(bookingService) }}
      </span>
      <span>{{ bookingServiceCostPriceText(bookingService) }}</span>
    </div>

    <!-- UNCHARGABLE SERVICES -->
    <div
      v-for="bookingService in ownerUnchargableServices"
      :key="`servei-${bookingService.uuid}`"
      class="d-flex justify-content-between mb-50"
    >
      <span class="text-grey">
        {{ serviceNameWithOwnerUnitPrice(bookingService) }}
      </span>
      <span> A pagar a l'allotjament </span>
    </div>

    <!-- SEPARADOR -->
    <b-divider class="my-1" />

    <!-- TOTAL -->
    <div class="d-flex justify-content-between font-weight-bold mb-2">
      <span>Total</span>
      <span>{{ formatCurrency(ownerTotal) }}</span>
    </div>

    <!-- UPDATE BOOKING OWNER RATES MODAL -->
    <b-modal
      :id="'modify-booking-rates-owner-price-modal'"
      title="Modifica els imports del propietari"
      hide-footer
      centered
    >
      <booking-rates-owner-price-form
        @price-updated="onBookingRatesOwnerPriceUpdated"
        @cancel="onCancelUpdateBookingRatesOwnerPrice"
      />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BModal, BButton, BAlert } from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import BookingRatesOwnerPriceForm from "@/views/bookings/booking/components/payments/BookingRatesOwnerPriceForm.vue";
import {
  getBookingServiceName,
  getBookingServiceNameWithOwnerUnitPrice,
  getBookingServiceOwnerTotalPriceText,
} from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BDivider,
    BookingRatesOwnerPriceForm,
    BModal,
    BButton,
    BAlert,
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    rates() {
      return this.$store.getters["booking/rates"];
    },
    ownerSubtotal() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    ownerTotal() {
      return this.$store.getters["booking/ownerTotal"];
    },
    services() {
      return this.$store.getters["booking/services"];
    },
    ownerChargableServices() {
      return this.$store.getters["booking/ownerChargableServices"];
    },
    ownerUnchargableServices() {
      return this.$store.getters["booking/ownerUnchargableServices"];
    },
  },
  methods: {
    modifyBookingOwnerRatesButtonClicked() {
      this.$bvModal.show("modify-booking-rates-owner-price-modal");
    },
    updateBookingOwnerRatesButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/updateOwnerRates", { booking: this.booking["@id"] })
        .then(() => this.$emit("booking-owner-price-updated"))
        .catch(() => this.$emit("booking-owner-price-update-error"))
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
          this.$store.dispatch("booking/fetchBooking", this.booking?.uuid);
          this.$store.dispatch("booking/fetchRates", this.booking?.uuid);
        });
    },
    onBookingRatesOwnerPriceUpdated() {
      this.$bvModal.hide("modify-booking-rates-owner-price-modal");
      this.$store.dispatch("booking/fetchBooking", this.booking?.uuid);
      this.$store.dispatch("booking/fetchRates", this.booking?.uuid);
    },
    onCancelUpdateBookingRatesOwnerPrice() {
      this.$bvModal.hide("modify-booking-rates-owner-price-modal");
    },
    formatRateDate(date) {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("No disponible")
      );
    },
    formatCurrency(price) {
      return formatCurrency(price) || this.$t("No disponible");
    },
    bookingServiceCostPriceText(bookingService) {
      return getBookingServiceOwnerTotalPriceText(
        bookingService,
        this.booking.nights,
        this.$i18n.locale
      );
    },
    serviceName(bookingService) {
      return getBookingServiceName(bookingService, this.$i18n.locale);
    },
    serviceNameWithOwnerUnitPrice(bookingService) {
      return getBookingServiceNameWithOwnerUnitPrice(
        bookingService,
        this.$i18n.locale
      );
    },
  },
};
</script>
