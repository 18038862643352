<template>
  <div class="py-1">
    <b-alert variant="danger" :show="showComissionAlert">
      <div class="alert-body">
        Falta definir la comissió del portal intermediari
      </div>
    </b-alert>

    <!-- SOURCE COMISSION -->
    <div class="d-flex justify-content-between font-weight-bold">
      <span>Comissió portal intermediari</span>
      <span>{{ formatCurrency(sourceComission) }}</span>
    </div>

    <!-- SEPARADOR -->
    <b-divider class="my-1" />

    <!-- SOURCE COMISSION PERCENTAGE -->
    <div class="d-flex justify-content-between font-weight-bold">
      <span>% Comissió portal intermediari</span>
      <span>{{ sourceComissionPercentage }}</span>
    </div>

    <!-- UPDATE SOURCE COMISSION -->
    <b-row class="mt-2 d-flex">
      <b-col cols="12" sm="auto">
        <b-button
          variant="primary"
          size="sm"
          block
          @click="updateBookingSourceComissionButtonClicked()"
        >
          Modifica comissió
        </b-button>
      </b-col>
    </b-row>

    <!-- UPDATE BOOKING SOURCE COMISSION MODAL -->
    <b-modal
      :id="'update-source-comission-modal'"
      title="Modifica la comissió del portal intermediari"
      hide-footer
      centered
      scrollable
    >
      <booking-source-comission-form
        @updated="onBookingSourceComissionUpdated"
        @cancel="onCancelUpdateBookingSourceComission"
        @error="onUpdateBookingSourceComissionError"
      />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BModal, BButton, BAlert } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import BookingSourceComissionForm from "@/views/bookings/booking/components/payments/BookingSourceComissionForm.vue";
import { AGENCY_CODE } from "@appConfig";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BButton,
    BDivider,
    BookingSourceComissionForm,
    BAlert,
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    pvpPrice() {
      return this.booking?.pvpPrice || null;
    },
    sourceComission() {
      return Number.isInteger(this.booking?.sourceComissionPrice)
        ? this.booking?.sourceComissionPrice
        : null;
    },
    sourceComissionPercentage() {
      if (!this.pvpPrice || !this.sourceComission) return "0 %";
      const percentage =
        Math.round((100 / this.pvpPrice) * this.sourceComission * 100) / 100;
      return `${percentage} %`;
    },
    showComissionAlert() {
      if (!this.booking?.source) return true;
      return this.booking.source !== AGENCY_CODE && this.sourceComission === 0;
    },
  },
  methods: {
    updateBookingSourceComissionButtonClicked() {
      this.$bvModal.show("update-source-comission-modal");
    },
    onBookingSourceComissionUpdated() {
      this.$bvModal.hide("update-source-comission-modal");
    },
    onCancelUpdateBookingSourceComission() {
      this.$bvModal.hide("update-source-comission-modal");
    },
    onUpdateBookingSourceComissionError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar actulaitzar la comissió del portal intermediari"
      );
    },
    formatCurrency(price) {
      return formatCurrency(price) || this.$t("No disponible");
    },
  },
};
</script>
