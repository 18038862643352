<template>
  <b-row class="p-1">
    <b-col cols="12">
      <b-tabs v-model="selectedTab" no-fade lazy align="center">
        <!-- ENGLISH TAB -->
        <b-tab active title="English" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="en-GB"
            v-bind="tabProps"
            @content-updated="onContentUpdated"
          />
        </b-tab>

        <!-- DEUTSCH TAB -->
        <b-tab active title="Deutsch" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="de-DE"
            v-bind="tabProps"
            @content-updated="onContentUpdated"
          />
        </b-tab>

        <!-- SPANISH TAB -->
        <b-tab title="Castellano" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="es-ES"
            v-bind="tabProps"
            @content-updated="onContentUpdated"
          />
        </b-tab>

        <!-- CATALAN TAB -->
        <b-tab title="Català" class="mt-3 border email-content">
          <component
            :is="tabComponent"
            locale="ca-ES"
            v-bind="tabProps"
            @content-updated="onContentUpdated"
          />
        </b-tab>
      </b-tabs>
    </b-col>
    <b-col
      cols="12"
      class="send-button d-flex justify-content-end py-2 px-0 mt-2"
    >
      <b-button
        :disabled="!contentClientEmail"
        variant="primary"
        @click="sendEmail"
      >
        Envia
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BTabs, BTab, BButton } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import axios from "@/axios";

const ClientEmailNotificationFormPrebookingConfirmation = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormPrebookingConfirmation.vue"
  );
const ClientEmailNotificationFormBookingConfirmation = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormBookingConfirmation.vue"
  );
const ClientEmailNotificationFormBookingModification = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormBookingModification.vue"
  );
const ClientEmailNotificationFormBookingCancellation = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormBookingCancellation.vue"
  );
const ClientEmailNotificationFormCheckinInstructions = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormCheckinInstructions.vue"
  );
const ClientEmailNotificationFormManualNotification = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormManualNotification.vue"
  );
const ClientEmailNotificationFormInitialPaymentRequest = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormInitialPaymentRequest.vue"
  );
const ClientEmailNotificationFormFinalPaymentRequest = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormFinalPaymentRequest.vue"
  );
const ClientEmailNotificationFormOnlineCheckinRequest = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormOnlineCheckinRequest.vue"
  );
const ClientEmailNotificationFormReviewRequest = () =>
  import(
    "@/views/bookings/booking/components/notifications/ClientEmailNotificationFormReviewRequest.vue"
  );

export default {
  props: {
    notificationType: {
      type: String,
      required: true,
      validator(value) {
        // The value must match one of these strings
        return [
          "prebooking-confirmation",
          "booking-confirmation",
          "booking-modification",
          "booking-cancellation",
          "checkin-instructions",
          "manual",
          "initial-payment-request",
          "final-payment-request",
          "last-final-payment-request",
          "online-checkin-request",
          "review-request",
        ].includes(value);
      },
    },
  },
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BButton,
  },
  data() {
    return {
      selectedTab: null,
      content: null,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    contentClientEmail() {
      return this.content?.clientEmail || null;
    },
    tabComponent() {
      switch (this.notificationType) {
        case "prebooking-confirmation":
          return ClientEmailNotificationFormPrebookingConfirmation;
        case "booking-confirmation":
          return ClientEmailNotificationFormBookingConfirmation;
        case "booking-modification":
          return ClientEmailNotificationFormBookingModification;
        case "booking-cancellation":
          return ClientEmailNotificationFormBookingCancellation;
        case "checkin-instructions":
          return ClientEmailNotificationFormCheckinInstructions;
        case "manual":
          return ClientEmailNotificationFormManualNotification;
        case "initial-payment-request":
          return ClientEmailNotificationFormInitialPaymentRequest;
        case "final-payment-request":
          return ClientEmailNotificationFormFinalPaymentRequest;
        case "last-final-payment-request":
          return ClientEmailNotificationFormFinalPaymentRequest;
        case "online-checkin-request":
          return ClientEmailNotificationFormOnlineCheckinRequest;
        case "review-request":
          return ClientEmailNotificationFormReviewRequest;
        default:
          return null;
      }
    },
    tabProps() {
      switch (this.notificationType) {
        case "prebooking-confirmation":
          return null;
        case "booking-confirmation":
          return null;
        case "booking-modification":
          return null;
        case "booking-cancellation":
          return null;
        case "checkin-instructions":
          return null;
        case "manual":
          return null;
        case "initial-payment-request":
          return null;
        case "final-payment-request":
          return { last: false };
        case "last-final-payment-request":
          return { last: true };
        case "online-checkin-request":
          return null;
        case "review-request":
          return null;
        default:
          return null;
      }
    },
    postUrl() {
      switch (this.notificationType) {
        case "prebooking-confirmation":
          return `/mail/client/prebooking-confirmation/${this.content.locale}`;
        case "booking-confirmation":
          return `/mail/client/booking-confirmation/${this.content.locale}`;
        case "booking-modification":
          return `/mail/client/booking-modification/${this.content.locale}`;
        case "booking-cancellation":
          return `/mail/client/booking-cancellation/${this.content.locale}`;
        case "checkin-instructions":
          return `/mail/client/checkin-instructions/${this.content.locale}`;
        case "manual":
          return `/mail/client/manual-notification/${this.content.locale}`;
        case "initial-payment-request":
          return `/mail/client/initial-payment-request/${this.content.locale}`;
        case "final-payment-request":
          return `/mail/client/final-payment-request/${this.content.locale}`;
        case "last-final-payment-request":
          return `/mail/client/final-payment-request/${this.content.locale}`;
        case "online-checkin-request":
          return `/mail/client/online-checkin-request/${this.content.locale}`;
        case "review-request":
          return null;
        default:
          return null;
      }
    },
    logType() {
      switch (this.notificationType) {
        case "prebooking-confirmation":
          return "CLIENT_PREBOOKING_CONFIRMATION_SENT";
        case "booking-confirmation":
          return "CLIENT_BOOKING_CONFIRMATION_SENT";
        case "booking-modification":
          return "CLIENT_BOOKING_MODIFICATION_SENT";
        case "booking-cancellation":
          return "CLIENT_BOOKING_CANCELLATION_SENT";
        case "checkin-instructions":
          return "CLIENT_CHECKIN_INSTRUCTIONS_SENT";
        case "manual":
          return "CLIENT_MANUAL_NOTIFICATION_SENT";
        case "initial-payment-request":
          return "CLIENT_INITIAL_PAYMENT_REQUEST_SENT";
        case "final-payment-request":
          return "CLIENT_FINAL_PAYMENT_REQUEST_SENT";
        case "last-final-payment-request":
          return "CLIENT_LAST_FINAL_PAYMENT_REQUEST_SENT";
        case "online-checkin-request":
          return "CLIENT_ONLINE_CHECKIN_REQUEST_SENT";
        case "review-request":
          return null;
        default:
          return null;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      // Timeout to give time to the modal to appear
      this.initTab();
    }, 50);
  },
  methods: {
    initTab() {
      if (!this.client) {
        return;
      }

      const language = this.client?.language || "en";

      switch (language) {
        case "en":
          this.selectedTab = 0;
          break;
        case "de":
          this.selectedTab = 1;
          break;
        case "es":
          this.selectedTab = 2;
          break;
        case "ca":
          this.selectedTab = 3;
          break;
        default:
          this.selectedTab = 0;
          break;
      }
    },
    onContentUpdated(content) {
      this.content = content;
    },
    sendEmail() {
      try {
        this.$store.dispatch("app/setLoading", true);

        if (!this.contentClientEmail) {
          throw new Error(
            "El client d'aquesta reserva no disposa de correu electrònic"
          );
        }

        if (!this.postUrl) {
          throw new Error(
            "No hi ha cap url definida per aquest tipus de notificació"
          );
        }

        axios
          .post(this.postUrl, this.content)
          .then(() => {
            // Save the log entry, if needed
            if (this.logType) {
              this.$store.dispatch("booking/addLog", {
                booking: this.booking["@id"],
                type: this.logType,
                data: this.content,
              });
            }

            // Emit the success
            this.$emit("sent");
          })
          .catch(() => this.$emit("send-error"))
          .finally(() => this.$store.dispatch("app/setLoading", false));
      } catch (error) {
        notifyError("Error", error.message);
        this.$emit("send-error");
        this.$store.dispatch("app/setLoading", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-content,
.send-button {
  margin: 0 auto;
  max-width: 600px;
}
</style>
