<template>
  <b-input-group>
    <b-input-group-prepend v-if="showButton && buttonLeft">
      <b-form-datepicker
        v-model="datepickerInput"
        :locale="locale || $i18n.locale"
        :start-weekday="1"
        show-decade-nav
        button-only
        left
        :date-format-options="datepickerOptions.dateFormat"
        :min="min"
        :max="max"
        hide-header
        size="sm"
        :button-variant="state === false ? 'danger' : 'primary'"
      />
    </b-input-group-prepend>
    <cleave
      v-model="cleaveInput"
      class="form-control"
      :raw="false"
      :options="cleaveOptions"
      :placeholder="placeholder"
      :class="{ 'has-error': state === false }"
    />
    <b-input-group-append v-if="showButton && !buttonLeft">
      <b-form-datepicker
        v-model="datepickerInput"
        :locale="locale || $i18n.locale"
        :start-weekday="1"
        show-decade-nav
        button-only
        right
        :date-format-options="datepickerOptions.dateFormat"
        :max="max"
        hide-header
        size="sm"
        :button-variant="state === false ? 'danger' : 'primary'"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormDatepicker,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    Cleave,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "DD/MM/YYYY",
    },
    locale: {
      type: String,
      default: null,
    },
    min: {
      type: Date,
      default: null,
    },
    max: {
      type: Date,
      default: null,
    },
    state: {
      required: false,
      default: null,
      validator: (prop) => typeof prop === "boolean" || prop === null,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    buttonLocation: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {
      cleaveOptions: {
        date: true,
        delimiter: "/",
        datePattern: ["d", "m", "Y"],
      },
      datepickerOptions: {
        dateFormat: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        },
      },
      cleaveInput: null,
      datepickerInput: null,
    };
  },
  computed: {
    showButton() {
      return !this.hideButton;
    },
    buttonLeft() {
      return this.buttonLocation === "left";
    },
  },
  watch: {
    cleaveInput(value) {
      const formattedValue = this.formatCleaveInput(value);
      this.datepickerInput = formattedValue;
      this.$emit("input", formattedValue);
    },
    datepickerInput(value) {
      const formattedValue = this.formatDatepickerInput(value);
      this.cleaveInput = formattedValue;
      this.$emit("input", value);
    },
  },
  created() {
    if (this.value) {
      const splittedValue = this.value.split("T")[0].split("-");
      this.cleaveInput = `${splittedValue[2]}/${splittedValue[1]}/${splittedValue[0]}`;
      this.datepickerInput = `${splittedValue[0]}-${splittedValue[1]}-${splittedValue[2]}`;
    }
  },
  methods: {
    formatCleaveInput(value) {
      if (!value?.length) return null;
      const splittedValue = value.split("/");
      if (splittedValue.length !== 3) return null;
      if (splittedValue[0].length !== 2) return null;
      if (splittedValue[1].length !== 2) return null;
      if (splittedValue[2].length !== 4) return null;
      return `${splittedValue[2]}-${splittedValue[1]}-${splittedValue[0]}`;
    },
    formatDatepickerInput(value) {
      if (!value?.length) return null;
      const splittedValue = value.split("-");
      if (splittedValue.length !== 3) return null;
      return `${splittedValue[2]}/${splittedValue[1]}/${splittedValue[0]}`;
    },
  },
};
</script>

<style scoped lang="scss">
.has-error {
  border-color: red;
}
</style>
