<template>
  <b-card title="Horaris">
    <b-overlay :show="loadingDetails" variant="white" :opacity="1">
      <!-- ARRIVAL -->
      <b-row>
        <b-col cols="12" class="mb-1">
          <h5>Arribada</h5>
        </b-col>
        <b-col cols="12">
          <b-row>
            <!-- ACCOMMODATION ARRIVAL TIME -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Entrada a la casa</h6>
              <cleave
                v-if="editMode"
                v-model="accommodationArrivalTime"
                class="form-control"
                :raw="false"
                :options="timeMaskOptions"
                placeholder="hh:mm"
              />
              <b-card-text v-else>
                {{ accommodationArrivalTimeText }}
              </b-card-text>
            </b-col>
            <!-- ARRIVAL TRANSPORT -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Mitjà de transport</h6>
              <v-select
                v-if="editMode"
                v-model="arrivalTransport"
                :options="transportMethodOptions"
                :reduce="(option) => option.value"
              />
              <b-card-text v-else>
                {{ arrivalTransportText }}
              </b-card-text>
            </b-col>
            <!-- ARRIVAL TRANSPORT LOCALIZATOR -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Localitzador</h6>
              <b-form-input
                v-if="editMode"
                v-model="arrivalTransportLocalizator"
                type="text"
              />
              <b-card-text v-else>
                {{ arrivalTransportLocalizatorText }}
              </b-card-text>
            </b-col>
            <!-- TRANSPORT ARRIVAL TIME -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Hora d'arribada</h6>
              <cleave
                v-if="editMode"
                v-model="transportArrivalTime"
                class="form-control"
                :raw="false"
                :options="timeMaskOptions"
                placeholder="hh:mm"
              />
              <b-card-text v-else>
                {{ transportArrivalTimeText }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- DEPARTURE -->
      <b-row>
        <b-col cols="12" class="mb-1">
          <h5>Partida</h5>
        </b-col>
        <b-col cols="12">
          <b-row>
            <!-- ACCOMMODATION DEPARTURE TIME -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Sortida de la casa</h6>
              <cleave
                v-if="editMode"
                v-model="accommodationDepartureTime"
                class="form-control"
                :raw="false"
                :options="timeMaskOptions"
                placeholder="hh:mm"
              />
              <b-card-text v-else>
                {{ accommodationDepartureTimeText }}
              </b-card-text>
            </b-col>
            <!-- DEPARTURE TRANSPORT -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Mitjà de transport</h6>
              <v-select
                v-if="editMode"
                v-model="departureTransport"
                :options="transportMethodOptions"
                :reduce="(option) => option.value"
              />
              <b-card-text v-else>
                {{ departureTransportText }}
              </b-card-text>
            </b-col>
            <!-- DEPARTURE TRANSPORT LOCALIZATOR -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Localitzador</h6>
              <b-form-input
                v-if="editMode"
                v-model="departureTransportLocalizator"
                type="text"
              />
              <b-card-text v-else>
                {{ departureTransportLocalizatorText }}
              </b-card-text>
            </b-col>
            <!-- TRANSPORT DEPARTURE TIME -->
            <b-col cols="12" md="6" lg="3" class="mb-2">
              <h6 class="mb-25">Hora de partida</h6>
              <cleave
                v-if="editMode"
                v-model="transportDepartureTime"
                class="form-control"
                :raw="false"
                :options="timeMaskOptions"
                placeholder="hh:mm"
              />
              <b-card-text v-else>
                {{ transportDepartureTimeText }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save"> Guarda </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BFormInput,
  BOverlay,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { getTransportName, notifyError } from "@/utils/methods";
import vSelect from "vue-select";
import { transportMethodOptions } from "@/utils/select-options";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    vSelect,
    Cleave,
    BFormInput,
    BOverlay,
  },
  data() {
    return {
      editMode: false,
      accommodationArrivalTime: null,
      arrivalTransport: null,
      arrivalTransportLocalizator: null,
      transportArrivalTime: null,
      accommodationDepartureTime: null,
      departureTransport: null,
      departureTransportLocalizator: null,
      transportDepartureTime: null,
      timeMaskOptions: {
        time: true,
        timePattern: ["h", "m"],
      },
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingDetails() {
      return this.$store.getters["booking/loadingDetails"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    accommodationArrivalTimeText() {
      return this.details?.accommodationArrivalTime
        ? `${this.details.accommodationArrivalTime}h`
        : "No definit";
    },
    arrivalTransportText() {
      return getTransportName(this.details?.arrivalTransport) || "No definit";
    },
    arrivalTransportLocalizatorText() {
      return this.details?.arrivalTransportLocalizator || "No definit";
    },
    transportArrivalTimeText() {
      return this.details?.transportArrivalTime
        ? `${this.details.transportArrivalTime}h`
        : "No definit";
    },
    accommodationDepartureTimeText() {
      return this.details?.accommodationDepartureTime
        ? `${this.details.accommodationDepartureTime}h`
        : "No definit";
    },
    departureTransportText() {
      return getTransportName(this.details?.departureTransport) || "No definit";
    },
    departureTransportLocalizatorText() {
      return this.details?.departureTransportLocalizator || "No definit";
    },
    transportDepartureTimeText() {
      return this.details?.transportDepartureTime
        ? `${this.details.transportDepartureTime}h`
        : "No definit";
    },
    transportMethodOptions() {
      return transportMethodOptions;
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  methods: {
    initInputs() {
      this.accommodationArrivalTime = this.details.accommodationArrivalTime;
      this.arrivalTransport = this.details.arrivalTransport;
      this.arrivalTransportLocalizator =
        this.details.arrivalTransportLocalizator;
      this.transportArrivalTime = this.details.transportArrivalTime;
      this.accommodationDepartureTime = this.details.accommodationDepartureTime;
      this.departureTransport = this.details.departureTransport;
      this.departureTransportLocalizator =
        this.details.departureTransportLocalizator;
      this.transportDepartureTime = this.details.transportDepartureTime;
    },
    resetInputs() {
      this.accommodationArrivalTime = null;
      this.arrivalTransport = null;
      this.arrivalTransportLocalizator = null;
      this.transportArrivalTime = null;
      this.accommodationDepartureTime = null;
      this.departureTransport = null;
      this.departureTransportLocalizator = null;
      this.transportDepartureTime = null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/updateDetails", {
          uuid: this.details.uuid,
          accommodationArrivalTime: this.accommodationArrivalTime,
          arrivalTransport: this.arrivalTransport,
          arrivalTransportLocalizator: this.arrivalTransportLocalizator,
          transportArrivalTime: this.transportArrivalTime,
          accommodationDepartureTime: this.accommodationDepartureTime,
          departureTransport: this.departureTransport,
          departureTransportLocalizator: this.departureTransportLocalizator,
          transportDepartureTime: this.transportDepartureTime,
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar els detalls de la reserva"
          )
        )
        .finally(() => {
          this.editMode = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
