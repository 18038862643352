<template>
  <b-overlay :show="loadingServices" rounded="sm" variant="white" opacity=".9">
    <b-card id="booking-services-card">
      <!-- SERVICES TABLE -->
      <booking-services-table
        :services="mappedServices"
        :loading="loadingServices"
        show-button
        show-search
        @button-click="onAddServiceClicked"
        @edit-service-click="onEditServiceClicked"
      />

      <!-- ADD SERVICE MODAL -->
      <b-modal
        :id="'add-booking-service-modal'"
        title="Afegeix un servei"
        scrollable
        hide-footer
        size="lg"
      >
        <booking-service-form
          @cancel="onAddServiceCancel"
          @service-added="onServiceAdded"
          @add-service-error="onAddServiceError"
        />
      </b-modal>

      <!-- EDIT SERVICE MODAL -->
      <b-modal
        :id="'edit-service-modal'"
        title="Edita el servei"
        scrollable
        hide-footer
        size="lg"
      >
        <booking-service-form
          :booking-service="selectedService"
          @cancel="onEditServiceCancel"
          @service-edited="onServiceEdited"
          @edit-service-error="onEditServiceError"
          @service-deleted="onServiceDeleted"
          @delete-service-error="onDeleteServiceError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard } from "bootstrap-vue";
import BookingServicesTable from "@/views/bookings/booking/components/services/BookingServicesTable.vue";
import BookingServiceForm from "@/views/bookings/booking/components/services/BookingServiceForm.vue";
import {
  getBookingServiceClientUnitPriceText,
  getBookingServiceOwnerUnitPriceText,
  getServiceName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { formatPercentage } from "@/utils/formatters";

export default {
  name: "ServicesCard",
  components: {
    BOverlay,
    BCard,
    BookingServicesTable,
    BookingServiceForm,
  },
  data() {
    return {
      selectedService: null,
    };
  },
  computed: {
    loadingServices() {
      return this.$store.getters["booking/loadingServices"];
    },
    bookingServices() {
      return this.$store.getters["booking/services"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    mappedServices() {
      if (this.bookingServices.length > 0) {
        return this.bookingServices.map((bookingService) => {
          let responsibleText = null;
          if (bookingService.chargable) {
            responsibleText = this.$t("Nosaltres");
          } else if (bookingService.paymentTime === "PROVIDER") {
            responsibleText = this.$t("Proveïdor");
          } else {
            responsibleText = this.$t("Propietari");
          }
          return {
            uuid: bookingService.uuid || this.$t("No definit"),
            name:
              getServiceName(bookingService.service, this.$i18n.locale) ||
              this.$t("No definit"),
            code: bookingService.service.code,
            pvpPrice:
              getBookingServiceClientUnitPriceText(
                bookingService,
                this.$i18n.locale
              ) || this.$t("No definit"),
            costPrice:
              !!bookingService.costPrice || bookingService.costPrice === 0
                ? getBookingServiceOwnerUnitPriceText(
                    bookingService,
                    this.$i18n.locale
                  )
                : this.$t("No definit"),
            commissionPercentage:
              formatPercentage(bookingService.commissionPercentage) ||
              this.$t("No definit"),
            provider:
              this.getProviderNameText(bookingService.provider) ||
              this.$t("No definit"),
            responsible: responsibleText,
          };
        });
      }
      return [];
    },
  },
  methods: {
    getProviderNameText(provier) {
      if (!provier) return null;
      return provier.fullName || provier.uuid;
    },
    fetchServices() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/fetchServices", this.booking.uuid)
        .catch(() => {
          // TODO: log error to Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddServiceClicked() {
      this.$bvModal.show("add-booking-service-modal");
    },
    onAddServiceCancel() {
      this.$bvModal.hide("add-booking-service-modal");
    },
    onServiceAdded() {
      this.fetchServices();
      this.$bvModal.hide("add-booking-service-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditServiceCancel() {
      this.$bvModal.hide("edit-service-modal");
    },
    onServiceEdited() {
      this.fetchServices();
      this.$bvModal.hide("edit-service-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onEditServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
    onServiceDeleted() {
      this.fetchServices();
      notifySuccess(
        "Servei eliminat",
        "El servei ha estat eliminat correctament"
      );
    },
    onDeleteServiceError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el servei"
      );
    },
    onEditServiceClicked(serviceUuid) {
      this.selectedService = this.bookingServices.find(
        (s) => s.uuid === serviceUuid
      );
      this.$bvModal.show("edit-service-modal");
    },
  },
};
</script>
