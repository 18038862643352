<template>
  <b-overlay :show="loadingReview" rounded="sm">
    <b-card>
      <template v-if="!review">
        <!-- NO REVIEW ALERT -->
        <b-alert variant="warning" :show="true" class="my-2">
          <div class="alert-body font-weight-normal">
            Aquesta reserva encara no ha rebut cap ressenya.
          </div>
        </b-alert>

        <!-- BUTTONS -->
        <b-row class="d-flex justify-content-between justify-content-sm-end">
          <b-col cols="12" sm="auto">
            <b-button block variant="primary" @click="onAddReviewClicked">
              Afegeix ressenya
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template v-else>
        <!-- POST REVIEW -->
        <b-row class="mb-1">
          <b-col>
            <b-form-checkbox
              v-model="approved"
              switch
              color="success"
              @change="onToggleApproved"
            >
              {{ approved ? "Publicada" : "No publicada" }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <!-- TITLE -->
        <b-row class="d-flex justify-content-between mb-25">
          <!-- TITLE & SUBTITLE -->
          <b-col cols="auto">
            <b-card-title
              v-if="reviewAuthor"
              :class="{ 'mb-1': reviewSubtitle }"
            >
              {{ reviewAuthor }}
            </b-card-title>
            <b-card-sub-title v-if="reviewSubtitle">
              {{ reviewSubtitle }}
            </b-card-sub-title>
          </b-col>
          <!-- SCORE -->
          <b-col cols="auto">
            <b-card-title class="mt-1">
              {{ reviewAverageRating }}
              <span class="d-none d-sm-inline"> / {{ maxRating }} </span>
            </b-card-title>
          </b-col>
        </b-row>

        <!-- REVIEW SUMMARY -->
        <b-row class="mb-2 pt-2 border-top border-bottom">
          <!-- FACILITIES -->
          <b-col cols="12" md="2" class="mb-1"> Instal·lacions </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateFacilities)">
                <strong>{{ getRatingPercent(review.rateFacilities) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- LOCATION -->
          <b-col cols="12" md="2" class="mb-1"> Ubicació </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateLocation)">
                <strong>{{ getRatingPercent(review.rateLocation) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- CLEANING -->
          <b-col cols="12" md="2" class="mb-1"> Neteja </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateCleaning)">
                <strong>{{ getRatingPercent(review.rateCleaning) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- COMMUNICATION -->
          <b-col cols="12" md="2" class="mb-1"> Comunicació </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar
                :value="getRatingPercent(review.rateCommunication)"
              >
                <strong>
                  {{ getRatingPercent(review.rateCommunication) }}%
                </strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- CHECKIN -->
          <b-col cols="12" md="2" class="mb-1"> Arribada </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateCheckin)">
                <strong>{{ getRatingPercent(review.rateCheckin) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- ACCURACY -->
          <b-col cols="12" md="2" class="mb-1"> Veracitat </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateAccuracy)">
                <strong>{{ getRatingPercent(review.rateAccuracy) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>

        <!-- COMMENTS -->
        <b-row>
          <!-- PUBLIC COMMENT -->
          <b-col cols="12" class="mb-2">
            <h5 class="mb-1">Comentari públic</h5>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="reviewPublicComment" />
          </b-col>
          <!-- PRIVATE COMMENT -->
          <b-col cols="12" class="mb-2">
            <h5 class="mb-1">Comentari privat</h5>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="reviewPrivateComment" />
          </b-col>
          <!-- PUBLIC ANSWER -->
          <b-col cols="12">
            <h5 class="mb-1">
              Resposta pública
              <small v-if="reviewPrivateComment && reviewPublicReplyAuthor">
                ({{ reviewPublicReplyAuthor }})
              </small>
            </h5>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="reviewPublicReply" v-html="reviewPublicReply" />
            <b-alert
              v-else
              class="mb-2"
              variant="primary"
              :show="!reviewPublicReply"
            >
              <div class="alert-body">
                Encara no s'ha contestat aquesta ressenya
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <!-- BUTTONS -->
        <b-row class="d-flex justify-content-between justify-content-sm-end">
          <b-col cols="6" sm="auto">
            <b-button block variant="danger" @click="confirmDeleteReview">
              Elimina
            </b-button>
          </b-col>
          <b-col cols="6" sm="auto">
            <b-button block variant="primary" @click="onEditButtonClicked">
              Edita
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-card>

    <!-- ADD REVIEW MODAL -->
    <b-modal
      :id="`add-review-actions-modal`"
      title="Afegeix una ressenya"
      scrollable
      hide-footer
    >
      <review-form
        @cancel="onAddReviewCancel"
        @review-added="onReviewAdded"
        @add-review-error="onAddReviewError"
      />
    </b-modal>

    <!-- EDIT REVIEW MODAL -->
    <b-modal
      :id="`edit-review-modal`"
      title="Edita la ressenya"
      scrollable
      hide-footer
    >
      <review-form
        ref="edit-review-form"
        :review="review"
        @cancel="onEditReviewCancel"
        @review-edited="onReviewEdited"
        @edit-review-error="onEditReviewError"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BProgress,
  BProgressBar,
  BButton,
  BAlert,
} from "bootstrap-vue";
import {
  getOtaName,
  getTeamMemberName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import {
  formatDateObjectToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import ReviewForm from "@/views/bookings/booking/components/review/ReviewForm.vue";

export default {
  name: "ReviewCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BProgress,
    BProgressBar,
    BButton,
    ReviewForm,
    BAlert,
  },
  data() {
    return {
      maxRating: 5,
      approved: false,
      reply: null,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingReview() {
      return this.$store.getters["booking/loadingReview"];
    },
    review() {
      return this.$store.getters["booking/review"];
    },
    reviewAuthor() {
      return this.review?.author || "Anònim";
    },
    reviewPostedDate() {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return formatDateStringToDate(
        this.review?.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
    reviewSource() {
      return getOtaName(this.review?.source);
    },
    reviewSubtitle() {
      const subtitle = [];
      if (this.reviewPostedDate) subtitle.push(this.reviewPostedDate);
      if (this.reviewSource) subtitle.push(this.reviewSource);
      return subtitle.length ? subtitle.join(" · ") : null;
    },
    reviewPublicComment() {
      return this.review?.comment || "No disponible";
    },
    reviewPrivateComment() {
      return this.review?.privateComment || "No disponible";
    },
    reviewPublicReplyAuthor() {
      return getTeamMemberName(this.review?.replyAuthor);
    },
    reviewPublicReply() {
      return this.review?.reply || null;
    },
    reviewAverageRating() {
      return this.review?.averageRating || "?";
    },
  },
  watch: {
    review(review) {
      if (review) this.init();
    },
  },
  created() {
    if (this.review) this.init();
  },
  methods: {
    init() {
      this.approved = this.review.approved;
    },
    onToggleApproved() {
      this.$swal({
        title: `${this.approved ? "Aprova ressenya" : "Rebutja ressenya"}`,
        text: `Estàs a punt de ${
          this.approved ? "aprovar" : "rebutjar"
        } aquesta ressenya, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        customClass: {
          confirmButton: `btn ${this.approved ? "btn-primary" : "btn-danger"}`,
          cancelButton: `btn ${
            this.approved ? "btn-outline-primary" : "btn-outline-danger"
          } ml-1`,
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.confirmToggleApproved();
        else this.rejectToggleApproved();
      });
    },
    confirmToggleApproved() {
      this.$store
        .dispatch("booking/updateReview", {
          uuid: this.review.uuid,
          approved: this.approved,
          reviewedAt: formatDateObjectToDatabaseDate(new Date()),
        })
        .catch(() =>
          notifyError(
            "Ressenya no actualitzada",
            "Hi ha hagut un error al intentar actualitzar la ressenya"
          )
        );
    },
    rejectToggleApproved() {
      this.approved = !this.approved;
    },
    getRatingPercent(rating) {
      if (!rating) return 0;
      return (100 / this.maxRating) * rating;
    },
    onAddReviewClicked() {
      this.$bvModal.show("add-review-actions-modal");
    },
    onAddReviewCancel() {
      this.$bvModal.hide("add-review-actions-modal");
    },
    onReviewAdded() {
      this.$bvModal.hide("add-review-actions-modal");
    },
    onAddReviewError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar afegir la ressenya"
      );
    },
    onEditButtonClicked() {
      this.$bvModal.show("edit-review-modal");
    },
    onEditReviewCancel() {
      this.$bvModal.hide("edit-review-modal");
    },
    onReviewEdited() {
      this.$bvModal.hide("edit-review-modal");
    },
    onEditReviewError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar editar la ressenya"
      );
    },
    confirmDeleteReview() {
      this.$swal({
        title: "Eliminar ressenya",
        text: "Estàs a punt d'eliminar aquesta ressenya, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteReview();
      });
    },
    deleteReview() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("booking/deleteReview", this.review.uuid)
          .then(() =>
            notifySuccess(
              "Ressenya eliminada",
              "La ressenya ha estat eliminada correctament."
            )
          )
          .catch(() => {
            notifyError(
              "Ressenya no eliminada",
              "Hi ha hagut un error al eliminar la ressenya."
            );
            // TODO: log error in Sentry
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>
