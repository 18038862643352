<template>
  <b-card title="Danger Zone">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <b-col cols="12">
          <b-button
            variant="danger"
            block
            @click="confirmDeleteBooking"
            :disabled="hasAccountedClientPayments"
          >
            Elimina Reserva
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BOverlay } from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BOverlay,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loadingSomething"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    hasAccountedClientPayments() {
      return this.$store.getters["booking/hasAccountedClientPayments"];
    },
  },
  methods: {
    confirmDeleteBooking() {
      this.$swal({
        title: "Eliminar reserva",
        text: "Estàs a punt d'eliminar aquesta reserva i totes les seves dades relacionades (incloent comentaris, incidències, hostes, factures, etc.), estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteBooking();
      });
    },
    deleteBooking() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/deleteBooking", this.booking["@id"])
        .then(() => {
          notifySuccess(
            "Reserva eliminada",
            "La reserva ha estat eliminada correctament"
          );
          this.$router.push({ name: "foravila-bookings-list" });
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar la reserva"
          );
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
