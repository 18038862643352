<template>
  <b-card title="Accions">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <!-- ADD PAYMENT -->
        <b-col class="mb-1" cols="12">
          <b-button block variant="primary" @click="onAddClientPaymentClicked">
            Afegeix pagament
          </b-button>
        </b-col>

        <!-- ADD REFUND -->
        <b-col class="mb-1" cols="12">
          <b-button block variant="primary" @click="onAddRefundClicked">
            Afegeix devolució
          </b-button>
        </b-col>

        <!-- CLIENT NOTIFICATIONS -->
        <b-col cols="12" class="mb-1">
          <b-dropdown
            text="Sol·licita pagament"
            variant="primary"
            :disabled="!clientHasEmail"
            block
          >
            <b-dropdown-item
              :disabled="initialPaymentPendingAmount <= 0"
              @click="clientInitialPaymentRequestButtonClicked"
            >
              Pagament inicial
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="pending <= 0 && !hasChargableSecurityDeposit"
              @click="clientFinalPaymentRequestButtonClicked"
            >
              Pagament final
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="pending <= 0 && !hasChargableSecurityDeposit"
              @click="clientLastFinalPaymentRequestButtonClicked"
            >
              Amenaça final
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <!-- ADD CLIENT PAYMENT MODAL -->
        <b-modal
          :id="'action-add-client-payment-modal'"
          title="Afegeix un pagament"
          scrollable
          hide-footer
          centered
        >
          <client-payment-form
            @client-payment-added="onClientPaymentAdded"
            @cancel="onAddClientPaymentCancel"
          />
        </b-modal>

        <!-- REFUND CLIENT PAYMENT MODAL -->
        <b-modal
          :id="'action-refund-client-payment-modal'"
          title="Afegeix una devolució"
          scrollable
          hide-footer
          centered
        >
          <client-payment-form
            refund-form
            @client-payment-added="onClientPaymentRefunded"
            @cancel="onRefundClientPaymentCancel"
          />
        </b-modal>

        <!-- INITIAL PAYMENT REQUEST MODAL -->
        <b-modal
          :id="'action-send-client-initial-payment-request-modal'"
          title="Sol·licita el pagament inicial al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="initial-payment-request"
            @sent="onClientInitialPaymentRequestSent"
            @send-error="onClientInitialPaymentRequestSendError"
          />
        </b-modal>

        <!-- FINAL PAYMENT REQUEST MODAL -->
        <b-modal
          :id="'action-send-client-final-payment-request-modal'"
          title="Sol·licita el pagament final al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="final-payment-request"
            @sent="onClientFinalPaymentRequestSent"
            @send-error="onClientFinalPaymentRequestSendError"
          />
        </b-modal>

        <!-- LAST FINAL PAYMENT REQUEST MODAL -->
        <b-modal
          :id="'action-send-client-last-final-payment-request-modal'"
          title="Amenaça de pagament final al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="last-final-payment-request"
            @sent="onClientFinalPaymentRequestSent"
            @send-error="onClientFinalPaymentRequestSendError"
          />
        </b-modal>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ClientEmailNotificationForm from "@/views/bookings/booking/components/notifications/ClientEmailNotificationForm.vue";
import ClientPaymentForm from "@/views/bookings/booking/components/payments/ClientPaymentForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  name: "PaymentsActionsCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    ClientEmailNotificationForm,
    ClientPaymentForm,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loadingSomething"];
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientHasEmail() {
      if (!this.client) return false;
      return !!this.client.email;
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    hasManagedSecurityDeposit() {
      return this.$store.getters["booking/hasManagedSecurityDeposit"];
    },
    initialPaymentPendingAmount() {
      return this.$store.getters["booking/initialPaymentPendingAmount"];
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
  },
  methods: {
    onAddClientPaymentClicked() {
      this.$bvModal.show("action-add-client-payment-modal");
    },
    onClientPaymentAdded() {
      this.$bvModal.hide("action-add-client-payment-modal");
      this.selectedPayment = null;
    },
    onAddClientPaymentCancel() {
      this.$bvModal.hide("action-add-client-payment-modal");
      this.selectedPayment = null;
    },
    onAddRefundClicked() {
      this.$bvModal.show("action-refund-client-payment-modal");
    },
    onClientPaymentRefunded() {
      this.$bvModal.hide("action-refund-client-payment-modal");
    },
    onRefundClientPaymentCancel() {
      this.$bvModal.hide("action-refund-client-payment-modal");
    },
    clientInitialPaymentRequestButtonClicked() {
      this.$bvModal.show("action-send-client-initial-payment-request-modal");
    },
    onClientInitialPaymentRequestSent() {
      this.$bvModal.hide("action-send-client-initial-payment-request-modal");
      notifySuccess(
        "Sol·licitut enviada",
        "La sol·licitut de pagament inicial ha estat enviada correctament al client"
      );
    },
    onClientInitialPaymentRequestSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la sol·licitut de pagament inicial al client, contacta amb en Pedro"
      );
    },
    clientFinalPaymentRequestButtonClicked() {
      this.$bvModal.show("action-send-client-final-payment-request-modal");
    },
    onClientFinalPaymentRequestSent() {
      this.$bvModal.hide("action-send-client-final-payment-request-modal");
      this.$bvModal.hide("action-send-client-last-final-payment-request-modal");
      notifySuccess(
        "Sol·licitut enviada",
        "La sol·licitut de pagament final ha estat enviada correctament al client"
      );
    },
    onClientFinalPaymentRequestSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la sol·licitut de pagament final al client, contacta amb en Pedro"
      );
    },
    clientLastFinalPaymentRequestButtonClicked() {
      this.$bvModal.show("action-send-client-last-final-payment-request-modal");
    },
  },
};
</script>
