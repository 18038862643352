<template>
  <div id="edit-incidence-form" class="pb-1">
    <b-row class="mb-1">
      <!-- TITOL -->
      <b-col cols="12">
        <b-form-group :label="$t('Títol')" label-for="title">
          <b>{{ incidence.title || $t("No disponible") }}</b>
        </b-form-group>
      </b-col>

      <!-- COMENTARIS -->
      <b-col cols="12" class="mb-1">
        <b-form-group :label="$t('Comentaris')" label-for="comments">
          <div id="comments">
            <!-- TIMELINE DE COMENTARIS -->
            <div v-if="incidenceHasComments">
              <b-overlay :show="loading" variant="white" :opacity="0.8">
                <div
                  v-for="incidenceComment in incidence.comments"
                  :key="`incidence-comment-${incidenceComment.uuid}`"
                  class="bg-light p-50 my-1"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <i>
                      {{ getCommentDate(incidenceComment) }}
                    </i>
                    <b-button
                      v-if="canDeleteComment(incidenceComment)"
                      size="sm"
                      variant="danger"
                      class="btn-icon ml-50 p-25"
                      @click="onDeleteCommentButtonClicked(incidenceComment)"
                    >
                      <feather-icon icon="Trash2Icon" size="13" />
                    </b-button>
                  </div>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div class="mt-50" v-html="incidenceComment.content" />
                </div>
              </b-overlay>
            </div>

            <!-- FORM NOU COMENTARI -->
            <quill-editor
              v-if="canAddComments"
              id="comment"
              v-model="comment"
              :options="editorOption"
              class="w-100"
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- BUTTON -->
    <b-row class="d-flex justify-content-end">
      <b-col cols="12" md="auto" :class="{ 'mb-1 mb-md-0': canAddComments }">
        <b-button variant="secondary" block @click="close"> Tanca </b-button>
      </b-col>
      <b-col v-if="canAddComments" cols="12" md="auto">
        <b-button
          variant="primary"
          :disabled="!comment"
          block
          @click="addComment"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BButton, BOverlay } from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import {
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    quillEditor,
    BOverlay,
  },
  props: {
    incidence: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      comment: null,
      selectedComment: null,
      editorOption: {
        placeholder: this.$t("Afegeix un comentari"),
        modules: {
          toolbar: false,
        },
      },
    };
  },
  computed: {
    loggedUserEmail() {
      return this.$store.getters["auth/loggedUserEmail"];
    },
    sanitizedComment() {
      if (!this.comment) return null;
      return this.comment.replace(/<p><br><\/p>/g, "").trim();
    },
    incidenceHasComments() {
      return !!this.incidence?.comments?.length;
    },
    canAddComments() {
      return ["OPEN", "PENDING"].includes(this.incidence?.status);
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.comment = null;
    },
    getCommentDate(comment) {
      if (!comment?.postedAt) return null;
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(
        comment.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
    addComment() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/addIncidenceComment", {
          incidence: this.incidence["@id"],
          author: this.loggedUserEmail,
          content: this.sanitizedComment,
          isSolution: false,
          postedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          this.$emit("edit-incidence-close");
          notifySuccess(
            "Comentari afegit",
            "El comentari ha estat afegit correctament a la incidència"
          );
          this.$store.dispatch("booking/updateIncidence", {
            uuid: this.incidence.uuid,
            updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
          });
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar afegir un comentari a la incidència"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    canDeleteComment(comment) {
      if (!comment?.postedAt || !this.canAddComments) return false;

      const postDate = this.$moment(comment.postedAt);
      const now = this.$moment();

      return now.isBefore(postDate.add(1, "day"), "hours");
    },
    onDeleteCommentButtonClicked(comment) {
      this.selectedComment = comment;
      this.$swal({
        title: "Eliminar comentari",
        text: "Estàs a punt d'eliminar aquest comentari, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteComment();
      });
    },
    deleteComment() {
      this.loading = true;
      this.$store
        .dispatch("booking/deleteIncidenceComment", {
          ...this.selectedComment,
          incidence: {
            uuid: this.incidence.uuid,
          },
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar el comentari"
          )
        )
        .finally(() => {
          this.loading = false;
          notifySuccess(
            "Comentari eliminat",
            "El comentari ha estat eliminat correctament"
          );
        });
    },
    close() {
      this.$emit("edit-incidence-close");
    },
  },
};
</script>
