<template>
  <div id="accommodation-service-form">
    <!-- SERVICE & CHARGABLE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Servei</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-if="!bookingService"
              v-model="service"
              :options="serviceOptions"
              :reduce="(option) => option.value"
            />
            <p v-else>
              {{ getServiceName(bookingService.service, $i18n.locale) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Cobrem el servei nosaltres?</h6>
          </b-col>
          <b-col cols="12">
            <b-form-checkbox
              v-model="chargable"
              :disabled="paymentTime === 'PROVIDER'"
              switch
              inline
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PAYMENT FREQUENCY & TIME -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Freqüència pagament</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="paymentFrequency"
              :options="selectPaymentFrequencyOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Moment pagament</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="paymentTime"
              :options="selectPaymentTimeOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BILLING -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h5 class="mb-1 p-25 bg-secondary text-white">Facturació</h5>
      </b-col>

      <!-- PVP -->
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>PVP</h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input v-model="pvp" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- INVOICE HOLDER -->
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Emissor de la factura <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="invoiceHolder"
              :options="invoiceHolderOptions"
              :reduce="(option) => option.value"
              :disabled="selectedServiceCode === 'SECURITY_DEPOSIT'"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- SETTLEMENT -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h5 class="mb-1 p-25 bg-secondary text-white">Liquidació</h5>
      </b-col>

      <!-- COST PRICE -->
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Preu propietari <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input v-model="costPrice" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- VAT PERCENTAGE -->
      <b-col cols="12" sm="3">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>% IVA <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="vatPercentage"
              :options="vatPercentageOptions"
              :reduce="(option) => option.value"
              :clearable="false"
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- IRPF PERCENTAGE -->
      <b-col cols="12" sm="3">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>% IRPF <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="irpfPercentage"
              :options="irpfPercentageOptions"
              :reduce="(option) => option.value"
              :clearable="false"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PROVIDER & COMISSION -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h5 class="mb-1 p-25 bg-secondary text-white">
          Proveïdor <small>(opcional)</small>
        </h5>
      </b-col>

      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Nom <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="provider"
              :options="providerOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-25">
            <h6>Comissió <small>(opcional)</small></h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="%">
              <b-form-input v-model="commissionPercentage" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  calculateTouristTaxPrice,
  getServiceName,
  getInitialServiceProps,
} from "@/utils/methods";
import {
  paymentFrequencyOptions,
  paymentTimeOptions,
  invoiceHolderOptions,
  vatPercentageOptions,
  irpfPercentageOptions,
} from "@/utils/select-options";
import {
  DEFAULT_SUPERMARKET_PROVIDER_ID_NUMBER,
  DEFAULT_TRANSFER_PROVIDER_ID_NUMBER,
} from "@appConfig";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BInputGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
  },
  props: {
    bookingService: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      service: null,
      paymentFrequency: "ONCE",
      paymentTime: "INITIAL_PAYMENT",
      provider: null,
      pvp: null,
      costPrice: null,
      commissionPercentage: 0,
      chargable: false,
      invoiceHolder: null,
      vatPercentage: 0,
      irpfPercentage: 0,
      invoiceHolderOptions,
      vatPercentageOptions,
      irpfPercentageOptions,
      getServiceName,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    contractBillingType() {
      return this.contract?.billingType || null;
    },
    contractOwnerVatPercentage() {
      return this.contract?.ownerVatPercentage ?? 0;
    },
    contractOwnerIrpfPercentage() {
      return this.contract?.ownerIrpfPercentage ?? 0;
    },
    services() {
      return this.$store.getters["services/services"];
    },
    serviceOptions() {
      return this.services.map((service) => ({
        label: getServiceName(service, this.$i18n.locale),
        value: service["@id"],
      }));
    },
    selectPaymentFrequencyOptions() {
      return paymentFrequencyOptions;
    },
    selectPaymentTimeOptions() {
      return paymentTimeOptions;
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    providerOptions() {
      return this.providers.map((provider) => ({
        label: provider.fullName,
        value: provider["@id"],
      }));
    },
    defaultSupermarketProvider() {
      return this.providers.find(
        (provider) =>
          provider.idNumber === DEFAULT_SUPERMARKET_PROVIDER_ID_NUMBER
      );
    },
    defaultTransferProvider() {
      return this.providers.find(
        (provider) => provider.idNumber === DEFAULT_TRANSFER_PROVIDER_ID_NUMBER
      );
    },
    selectedService() {
      return this.services.find((s) => s["@id"] === this.service);
    },
    selectedServiceCode() {
      return this.selectedService?.code || null;
    },
  },
  watch: {
    service(service) {
      if (this.bookingService || !this.selectedService) {
        return;
      }

      this.initFormFromServiceCode(this.selectedService.code);

      switch (this.selectedService.code) {
        case "SUPERMARKET":
          if (this.defaultSupermarketProvider) {
            this.provider = this.defaultSupermarketProvider["@id"];
          }
          break;
        case "TRANSFER":
        case "AIRPORT_TRANSFER":
          if (this.defaultTransferProvider) {
            this.provider = this.defaultTransferProvider["@id"];
          }
          break;
        default:
          break;
      }
    },
    paymentTime(value) {
      if (value === "PROVIDER") {
        this.chargable = false;
      }
    },
    selectedServiceCode(value) {
      if (value === "SECURITY_DEPOSIT") {
        this.invoiceHolder = null;
      }
    },
  },
  created() {
    this.initFormSettlementFields();

    if (this.bookingService) {
      this.initFormWithBookingServiceDetails();
    }
  },
  methods: {
    initFormWithBookingServiceDetails() {
      if (!this.bookingService) {
        return;
      }

      const {
        service,
        paymentFrequency,
        paymentTime,
        provider,
        pvpPrice,
        costPrice,
        commissionPercentage,
        chargable,
        invoiceHolder,
        vatPercentage,
        irpfPercentage,
      } = this.bookingService || {};

      this.service = service ? service["@id"] : null;
      this.paymentFrequency = paymentFrequency || null;
      this.paymentTime = paymentTime || null;
      this.provider = provider ? provider["@id"] : null;
      this.pvp = pvpPrice || pvpPrice === 0 ? pvpPrice / 100 : null;
      this.costPrice = costPrice || costPrice === 0 ? costPrice / 100 : null;
      this.commissionPercentage = commissionPercentage ?? 0;
      this.chargable = !!chargable;
      this.invoiceHolder = invoiceHolder || null;
      this.vatPercentage = vatPercentage ?? 0;
      this.irpfPercentage = irpfPercentage ?? 0;
    },
    initFormSettlementFields() {
      this.vatPercentage = this.contractOwnerVatPercentage ?? 0;
      this.irpfPercentage = this.contractOwnerIrpfPercentage ?? 0;
    },
    initFormFromServiceCode(serviceCode) {
      const {
        chargable,
        paymentFrequency,
        paymentTime,
        pvp,
        costPrice,
        commissionPercentage,
        invoiceHolder,
      } = getInitialServiceProps(serviceCode) || {};

      this.chargable = chargable;
      this.paymentFrequency = paymentFrequency;
      this.paymentTime = paymentTime;
      this.pvp = pvp;
      this.costPrice = costPrice;
      this.commissionPercentage = commissionPercentage ?? 0;
      this.invoiceHolder = invoiceHolder;

      if (serviceCode === "TOURIST_TAX") {
        const touristTaxPrice =
          calculateTouristTaxPrice(
            this.booking.checkin,
            this.booking.checkout,
            this.booking.adults,
            this.contractBillingType === "SUBURANCE"
          ) / 100;

        this.pvp = touristTaxPrice;
        this.costPrice = touristTaxPrice;
        this.invoiceHolder =
          this.contractBillingType === "SUBURANCE" ? "AGENCY" : "OWNER";
      }
    },
    resetForm() {
      this.service = null;
      this.paymentFrequency = null;
      this.paymentTime = null;
      this.provider = null;
      this.pvp = null;
      this.costPrice = null;
      this.commissionPercentage = 0;
      this.chargable = false;
      this.invoiceHolder = null;
      this.vatPercentage = 0;
      this.irpfPercentage = 0;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.bookingService) this.updateBookingService();
      else this.createBookingService();
    },
    createBookingService() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("booking/addService", {
            booking: this.booking["@id"],
            paymentFrequency: this.paymentFrequency,
            paymentTime: this.paymentTime,
            service: this.service,
            provider: this.provider,
            pvpPrice:
              this.pvp || this.pvp === 0 ? Math.round(this.pvp * 100) : null,
            costPrice:
              this.costPrice || this.costPrice === 0
                ? Math.round(this.costPrice * 100)
                : null,
            commissionPercentage: isNaN(parseFloat(this.commissionPercentage))
              ? 0
              : parseFloat(this.commissionPercentage),
            chargable: this.chargable,
            invoiceHolder: this.invoiceHolder,
            vatPercentage: isNaN(parseFloat(this.vatPercentage))
              ? 0
              : parseFloat(this.vatPercentage),
            irpfPercentage: isNaN(parseFloat(this.irpfPercentage))
              ? 0
              : parseFloat(this.irpfPercentage),
          })
          .then(() => this.$emit("service-added"))
          .catch(() => {
            this.$emit("add-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    updateBookingService() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("booking/updateService", {
            uuid: this.bookingService.uuid,
            service: this.service,
            paymentFrequency: this.paymentFrequency,
            paymentTime: this.paymentTime,
            provider: this.provider,
            pvpPrice:
              this.pvp || this.pvp === 0 ? Math.round(this.pvp * 100) : null,
            costPrice:
              this.costPrice || this.costPrice === 0
                ? Math.round(this.costPrice * 100)
                : null,
            commissionPercentage: isNaN(parseFloat(this.commissionPercentage))
              ? 0
              : parseFloat(this.commissionPercentage),
            chargable: this.chargable,
            invoiceHolder: this.invoiceHolder,
            vatPercentage: isNaN(parseFloat(this.vatPercentage))
              ? 0
              : parseFloat(this.vatPercentage),
            irpfPercentage: isNaN(parseFloat(this.irpfPercentage))
              ? 0
              : parseFloat(this.irpfPercentage),
          })
          .then(() => this.$emit("service-edited"))
          .catch(() => {
            this.$emit("edit-service-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
