<template>
  <div class="client-payment-details">
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Data de creació</h6>
        <b-card-text>{{ paymentDate }}</b-card-text>
      </b-col>
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Import</h6>
        <b-card-text>{{ pvpAmount }}</b-card-text>
      </b-col>
      <b-col cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Estat</h6>
        <b-card-text>{{ paymentStatus }}</b-card-text>
      </b-col>
      <b-col v-if="paymentConfirmedAt" cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Data de confirmació</h6>
        <b-card-text>{{ paymentConfirmedAt }}</b-card-text>
      </b-col>
      <b-col v-if="paymentConfirmedAt" cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Mètode de pagament</h6>
        <b-card-text>{{ paymentMethod }}</b-card-text>
      </b-col>
      <b-col v-if="paymentConfirmedAt" cols="12" sm="6" class="mb-2">
        <h6 class="mb-25">Localitzador</h6>
        <b-card-text>
          {{ paymentLocalizator }}
          <!-- <feather-icon
            v-if="payment.localizator"
            icon="ClipboardIcon"
            class="ml-25 cursor-pointer"
            @click="copyToClipboard()"
          /> -->
        </b-card-text>
      </b-col>
      <b-col v-if="paymentConfirmedAt" cols="12" class="mb-2">
        <h6 class="mb-25">Comentari</h6>
        <b-card-text>{{ paymentComment }}</b-card-text>
      </b-col>
      <b-col v-if="paymentConfirmedAt" cols="12" class="mb-2">
        <h6 class="mb-25">ID Foravila</h6>
        <b-card-text>{{ payment.uuid }}</b-card-text>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCardText } from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import {
  getClientPaymentStatusName,
  getPaymentMethodName,
} from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BCardText,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pvpAmount() {
      return formatCurrency(this.payment?.pvpAmount) || "No disponible";
    },
    paymentDate() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(
          this.payment?.date,
          this.$i18n.locale,
          formatting
        ) || "No disponible"
      );
    },
    paymentStatus() {
      return (
        getClientPaymentStatusName(this.payment?.status, this.$i18n.locale) ||
        "No disponible"
      );
    },
    paymentConfirmedAt() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(
          this.payment?.confirmedAt,
          this.$i18n.locale,
          formatting
        ) || "No disponible"
      );
    },
    paymentMethod() {
      return getPaymentMethodName(this.payment?.method) || "No disponible";
    },
    paymentLocalizator() {
      return this.payment?.localizator || "No disponible";
    },
    paymentComment() {
      return this.payment?.comment || "No disponible";
    },
  },
  methods: {
    // copyToClipboard() {
    //   this.$copyText(this.paymentLocalizator)
    //     .then(result => console.log('copied!', result))
    //     .catch(error => console.error(error))
    // },
  },
};
</script>

<style></style>
