<template>
  <b-form @submit.prevent="save()">
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Comissió portal intermediari</h6>
        <b-form-input
          v-model="comission"
          type="number"
          placeholder="Comissió en €"
          min="0"
          step="0.01"
          number
        />
      </b-col>
      <b-col cols="12" class="mt-2">
        <h6>% Comissió</h6>
        <span> {{ comissionPercentage }}</span>
      </b-col>
    </b-row>

    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" :disabled="!canSave" type="submit">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BForm, BRow, BCol, BButton, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
  },
  data() {
    return {
      comission: null,
      application: null,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    pvpPrice() {
      return this.booking?.pvpPrice || null;
    },
    databaseComission() {
      if (!this.comission && this.comission !== 0) return null;
      return parseInt(this.comission * 100, 10);
    },
    comissionPercentage() {
      if (!this.pvpPrice || !this.comission) return "0 %";
      const percentage =
        Math.round((100 / this.pvpPrice) * (this.comission * 100) * 100) / 100;
      return `${percentage} %`;
    },
    canSave() {
      return this.comission || this.comission === 0;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (!this.comission && this.comission !== 0) return;

      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          sourceComissionPrice: this.databaseComission,
        })
        .then(() => {
          this.$emit("updated");

          // Log the booking update
          this.$store.dispatch("booking/addLog", {
            booking: this.booking["@id"],
            type: "BOOKING_UPDATED",
            data: {
              sourceComissionPrice: this.databaseComission,
            },
          });
        })
        .catch(() => {
          this.$emit("error");
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
