<template>
  <div class="security-deposit-payments-table">
    <!-- CLIENT PAYMENTS TABLE -->
    <b-table
      striped
      borderless
      responsive
      class="position-relative"
      :busy="loading"
      :per-page="100"
      :current-page="1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      :items="payments"
      show-empty
      :empty-text="$t('errors.emptyTable.title')"
      :empty-filtered-text="$t('errors.emptyTable.title')"
      :fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ $t("Carregant") }}...</strong>
        </div>
      </template>

      <template #cell(date)="data">
        {{ formattedDate(data.value) }}
      </template>

      <template #cell(pvpAmount)="data">
        {{ formattedCurrency(data.value) }}
      </template>

      <template #cell(localizator)="data">
        {{ paymentLocalizator(data.value) }}
      </template>

      <template #cell(method)="data">
        {{ formattedMethod(data.value) }}
      </template>

      <template #cell(status)="data">
        {{ formattedStatus(data.value) }}
      </template>

      <template #cell(type)="data">
        <b-badge v-if="data.item.pvpAmount > 0" variant="success">
          Cobrament
        </b-badge>
        <b-badge v-else variant="danger"> Devolució </b-badge>
      </template>

      <template #cell(scope)="data">
        {{ formattedScope(data.item) }}
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            v-if="showAccountedAlertIcon(data.item.status)"
            v-b-tooltip.hover.bottom="
              'El pagament ja ha estat comptabilitzat, no es pot editar ni esborrar. Contacta amb en Pedro en cas de necessitar-ho'
            "
            class="ml-75 text-info"
            icon="AlertTriangleIcon"
          />
          <feather-icon
            v-if="detailsButton"
            v-b-tooltip.hover.bottom="'Veure detalls'"
            class="ml-75 cursor-pointer"
            icon="EyeIcon"
            @click="showPaymentDetails(data.item)"
          />
          <feather-icon
            v-if="showConfirmButton(data.item.status)"
            v-b-tooltip.hover.bottom="'Confirmar pagament'"
            class="ml-75 cursor-pointer"
            icon="CheckCircleIcon"
            @click="confirmPayment(data.item)"
          />
          <feather-icon
            v-if="showVerifyButton(data.item.status)"
            v-b-tooltip.hover.bottom="'Verificar pagament'"
            class="ml-75 cursor-pointer"
            icon="UserCheckIcon"
            @click="verifyPayment(data.item)"
          />
          <feather-icon
            v-if="refundButton"
            v-b-tooltip.hover.bottom="'Generar devolució'"
            class="ml-75 cursor-pointer"
            icon="CornerUpLeftIcon"
            @click="refundPayment(data.item)"
          />
          <feather-icon
            v-if="showEditButton(data.item.status)"
            v-b-tooltip.hover.bottom="'Editar pagament'"
            class="ml-75 cursor-pointer"
            icon="EditIcon"
            @click="editPayment(data.item)"
          />
          <feather-icon
            v-if="showDeleteButton(data.item.status)"
            v-b-tooltip.hover.bottom="'Esborrar pagament'"
            class="ml-75 cursor-pointer text-danger"
            icon="Trash2Icon"
            @click="deletePayment(data.item)"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BBadge, VBTooltip } from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import {
  getClientPaymentScopeName,
  getClientPaymentStatusName,
  getPaymentMethodName,
  getServiceName,
} from "@/utils/methods";

export default {
  components: {
    BTable,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    payments: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    localizator: {
      type: Boolean,
      default: () => false,
    },
    status: {
      type: Boolean,
      default: () => false,
    },
    scope: {
      type: Boolean,
      default: () => false,
    },
    method: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: Boolean,
      default: () => false,
    },
    detailsButton: {
      type: Boolean,
      default: () => false,
    },
    refundButton: {
      type: Boolean,
      default: () => false,
    },
    confirmButton: {
      type: Boolean,
      default: () => false,
    },
    verifyButton: {
      type: Boolean,
      default: () => false,
    },
    editButton: {
      type: Boolean,
      default: () => false,
    },
    deleteButton: {
      type: Boolean,
      default: () => false,
    },
    hideActions: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      sortBy: "date",
      sortDesc: true,
      fields: [],
      selectedPayment: null,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
  },
  created() {
    this.initTableFields();
  },
  methods: {
    initTableFields() {
      if (this.type) {
        this.fields.push({
          key: "type",
          label: this.$t("Tipus"),
          sortable: true,
        });
      }
      this.fields.push({ key: "date", label: this.$t("Data"), sortable: true });
      this.fields.push({
        key: "pvpAmount",
        label: this.$t("Import"),
        sortable: true,
      });
      if (this.scope) {
        this.fields.push({
          key: "scope",
          label: this.$t("Motiu"),
          sortable: true,
        });
      }
      if (this.localizator) {
        this.fields.push({
          key: "localizator",
          label: this.$t("Localitzador"),
        });
      }
      if (this.method) {
        this.fields.push({ key: "method", label: this.$t("Mètode") });
      }
      if (this.status) {
        this.fields.push({
          key: "status",
          label: this.$t("Estat"),
          sortable: true,
        });
      }
      if (!this.hideActions) {
        this.fields.push({ key: "actions", label: this.$t("Accions") });
      }
    },
    showPaymentDetails(payment) {
      this.$emit("payment-details", payment);
    },
    editPayment(payment) {
      this.$emit("payment-edit", payment);
    },
    deletePayment(payment) {
      this.$emit("payment-delete", payment);
    },
    refundPayment(payment) {
      this.$emit("payment-refund", payment);
    },
    showConfirmButton(status) {
      return this.confirmButton && status === "PRE_CONFIRMED";
    },
    confirmPayment(payment) {
      this.$emit("payment-confirm", payment);
    },
    showVerifyButton(status) {
      return this.verifyButton && status === "CONFIRMED";
    },
    verifyPayment(payment) {
      this.$emit("payment-verify", payment);
    },
    showEditButton(status) {
      return this.editButton && status !== "ACCOUNTED";
    },
    showDeleteButton(status) {
      return this.deleteButton && status !== "ACCOUNTED";
    },
    showAccountedAlertIcon(status) {
      return status === "ACCOUNTED";
    },
    paymentLocalizator(localizator) {
      return localizator || "No disponible";
    },
    formattedDate(date) {
      return formatDateStringToDate(date);
    },
    formattedCurrency(price) {
      return formatCurrency(price);
    },
    formattedStatus(status) {
      return getClientPaymentStatusName(status, this.$i18n.locale);
    },
    formattedScope(item) {
      if (item.scope === "SERVICE") {
        const service = item.bookingService?.service || null;
        return service
          ? getServiceName(service, this.$i18n.locale)
          : "Sense servei relacionat";
      }
      return getClientPaymentScopeName(item.scope);
    },
    formattedMethod(method) {
      return getPaymentMethodName(method);
    },
  },
};
</script>
