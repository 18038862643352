<template>
  <b-card title="Peticions">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <!-- REQUESTS -->
      <b-row>
        <b-col cols="12">
          <b-row>
            <!-- ACCOMMODATION DEPARTURE TIME -->
            <b-col cols="12" sm="3" class="mb-2">
              <h6 class="mb-25">Cunes</h6>
              <b-form-spinbutton v-if="editMode" v-model="babyCots" min="0" />
              <b-card-text v-else>
                {{ babyCotsText }}
              </b-card-text>
            </b-col>
            <!-- DEPARTURE TRANSPORT -->
            <b-col cols="12" sm="3" class="mb-2">
              <h6 class="mb-25">Trones</h6>
              <b-form-spinbutton v-if="editMode" v-model="babyChairs" min="0" />
              <b-card-text v-else>
                {{ babyChairsText }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- SPECIAL REQUESTS -->
      <b-row>
        <b-col cols="12" class="mb-2">
          <h6 class="mb-25">Peticions especials</h6>

          <!-- NO SPECIAL REQUESTS MESSAGE -->
          <span
            v-if="!editMode && specialRequests.length === 0"
            class="text-mutted"
          >
            No hi ha peticions especials
          </span>

          <!-- SPECIAL REQUESTS LIST -->
          <b-list-group
            v-if="specialRequests.length > 0"
            flush
            :class="{ 'mb-1': editMode }"
          >
            <b-list-group-item
              v-for="spr in specialRequests"
              :key="`special-request-${spr.uuid}`"
              class="d-flex align-items-center"
            >
              <feather-icon icon="CheckIcon" size="12" class="mr-2" />
              <b-card-text class="mb-0">
                {{ spr.request }}
              </b-card-text>
              <feather-icon
                v-if="editMode"
                icon="Trash2Icon"
                size="12"
                class="ml-50 cursor-pointer"
                @click="onDeleteSpecialRequestButtonClicked(spr)"
              />
            </b-list-group-item>
          </b-list-group>

          <!-- ADD SPECIAL REQUEST FORM -->
          <b-input-group v-if="editMode">
            <b-form-input
              v-model="specialRequest"
              @keypress.enter="addSpecialRequest()"
            />
            <b-input-group-append>
              <b-button variant="primary" @click="addSpecialRequest()">
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save"> Guarda </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormSpinbutton,
  BOverlay,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";

export default {
  name: "RequestsCard",
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormSpinbutton,
    BOverlay,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  data() {
    return {
      loadingSpecialRequest: false,
      editMode: false,
      babyCots: null,
      babyChairs: null,
      specialRequest: null,
      selectedSpecialRequest: null,
    };
  },
  computed: {
    loading() {
      return (
        this.loadingDetails ||
        this.loadingSpecialRequests ||
        this.loadingSpecialRequest
      );
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingDetails() {
      return this.$store.getters["booking/loadingDetails"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    loadingSpecialRequests() {
      return this.$store.getters["booking/loadingSpecialRequests"];
    },
    specialRequests() {
      return this.$store.getters["booking/specialRequests"];
    },
    babyCotsText() {
      if (!this.details?.babyCots && this.details?.babyCots !== 0)
        return "No definit";
      return this.details.babyCots;
    },
    babyChairsText() {
      if (!this.details?.babyChairs && this.details?.babyChairs !== 0)
        return "No definit";
      return this.details.babyChairs;
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  methods: {
    initInputs() {
      this.babyCots = this.details?.babyCots || 0;
      this.babyChairs = this.details?.babyChairs || 0;
    },
    resetInputs() {
      this.babyCots = null;
      this.babyChairs = null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/updateDetails", {
          uuid: this.details.uuid,
          babyCots: this.babyCots,
          babyChairs: this.babyChairs,
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar els detalls de la reserva"
          )
        )
        .finally(() => {
          this.editMode = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    addSpecialRequest() {
      if (this.specialRequest) {
        this.loadingSpecialRequest = true;
        this.$store
          .dispatch("booking/addSpecialRequest", {
            booking: this.booking["@id"],
            request: this.specialRequest.trim(),
          })
          .catch(() =>
            notifyError(
              "Error",
              "Hi ha hagut un error al intentar afegir la petició"
            )
          )
          .finally(() => {
            this.specialRequest = null;
            this.loadingSpecialRequest = false;
          });
      }
    },
    onDeleteSpecialRequestButtonClicked(specialRequest) {
      this.selectedSpecialRequest = specialRequest;
      this.$swal({
        title: "Eliminar petició especial",
        text: "Estàs a punt d'eliminar aquesta petició especial, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteSpecialRequest();
      });
    },
    deleteSpecialRequest() {
      this.loadingSpecialRequest = true;
      this.$store
        .dispatch(
          "booking/deleteSpecialRequest",
          this.selectedSpecialRequest.uuid
        )
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar la petició especial"
          )
        )
        .finally(() => {
          this.loadingSpecialRequest = false;
        });
    },
  },
};
</script>
