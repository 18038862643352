<template>
  <div id="booking-dates-form">
    <!-- FORM -->
    <b-row class="mb-2">
      <!-- CHECK-IN -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h6>Entrada</h6>
          </b-col>
          <b-col cols="12">
            <b-form-datepicker
              v-model="period.checkin"
              placeholder="Data d'entrada"
              :locale="locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- CHECK OUT -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h6>Sortida</h6>
          </b-col>
          <b-col cols="12">
            <b-form-datepicker
              v-model="period.checkout"
              placeholder="Data de sortida"
              :locale="locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              :min="period.checkin"
              hide-header
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- RATE -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h6>Tarifa</h6>
          </b-col>
          <b-col cols="12">
            <b-input-group>
              <b-form-input
                v-model="period.rate"
                type="number"
                placeholder="PVP en €"
                min="0"
              />
              <b-input-group-append is-text>
                <b-form-radio-group
                  v-model="period.application"
                  :disabled="!period.rate"
                  :options="nightTotalOptions"
                  value-field="value"
                  text-field="text"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PREVIEW -->
    <b-row v-if="selectedRates.length > 0" class="mb-2">
      <b-col cols="12" class="mb-50">
        <h6>Detall (PVP)</h6>
      </b-col>

      <b-col cols="12">
        <div
          v-for="(rate, rateIndex) in selectedRates"
          :key="`selected-rate-${rateIndex}`"
          class="d-flex justify-content-between mb-50"
        >
          <span class="text-grey">
            {{ formatRateDate(rate.date) }}
          </span>
          <span :class="{ 'text-danger font-weight-bold': rate.price <= 0 }">
            {{ formatCurrency(rate.price) }}
          </span>
        </div>

        <b-divider class="my-1" />

        <div class="d-flex justify-content-between mb-50 font-weight-bold">
          <span>Total ({{ nightsText }})</span>
          <span>{{ formatCurrency(selectedRatesTotal) }}</span>
        </div>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="
            !period.checkin || !period.checkout || loadingAccommodationRates
          "
          @click="save()"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { calculateNights, sameDates } from "@/utils/methods";
import {
  formatCurrency,
  formatDateObjectToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BDivider,
  },
  data() {
    return {
      period: {
        checkin: null,
        checkout: null,
        rate: null,
        application: null,
      },
      nightTotalOptions: [
        { text: "vespre", value: "night", disabled: false },
        { text: "total", value: "total", disabled: false },
      ],
    };
  },
  computed: {
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingAccommodationRates() {
      return this.$store.getters["accommodation/loadingRates"];
    },
    accommodationRates() {
      return this.$store.getters["accommodation/rates"];
    },
    locale() {
      return this.$i18n.locale;
    },
    isNightRateApplication() {
      return this.period.application === "night";
    },
    transformedPvpAmount() {
      if (!this.period.rate) return null;
      return Math.round(this.period.rate * 100);
    },
    nights() {
      return calculateNights(this.period.checkin, this.period.checkout);
    },
    nightsText() {
      return `${this.nights} ${this.nights !== 1 ? "vespres" : "vespre"}`;
    },
    nightPvp() {
      if (this.isNightRateApplication) return this.transformedPvpAmount;
      if (this.nights <= 0) return null;
      return Math.round(
        (parseFloat(this.period.rate) / this.nights).toFixed(2) * 100
      );
    },
    selectedRates() {
      if (!this.period.checkin || !this.period.checkout) return [];

      const selectedRates = [];
      if (this.period.rate) {
        // A rate has been introduced, calculate the rates with this.

        let sum = 0;
        let index = 0;

        for (
          let loopDate = new Date(this.period.checkin);
          loopDate < new Date(this.period.checkout);
          loopDate.setDate(loopDate.getDate() + 1)
        ) {
          if (index < this.nights - 1) {
            selectedRates.push({
              date: formatDateObjectToDatabaseDate(loopDate),
              price: this.nightPvp,
            });
            sum += this.nightPvp;
          } else {
            // We are in the last index, manually calculate the remaining amount
            // to avoid decimal misscalculations.
            selectedRates.push({
              date: formatDateObjectToDatabaseDate(loopDate),
              price: this.isNightRateApplication
                ? this.nightPvp
                : this.transformedPvpAmount - sum,
            });
          }
          index += 1;
        }
      } else {
        // No rate has been introduce, calculate the rates with the accommodation rates
        for (
          let loopDate = new Date(this.period.checkin);
          loopDate < new Date(this.period.checkout);
          loopDate.setDate(loopDate.getDate() + 1)
        ) {
          const accommodationRate = this.accommodationRates.find((ar) =>
            sameDates(loopDate, new Date(ar.date))
          );
          selectedRates.push({
            date: formatDateObjectToDatabaseDate(loopDate),
            price: accommodationRate?.price || 0,
          });
        }
      }
      return selectedRates;
    },
    selectedRatesTotal() {
      if (this.selectedRates.length > 0) {
        return this.selectedRates.reduce((acc, r) => acc + r.price, 0);
      }
      return null;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "period.rate": function (newValue, oldValue) {
      if (!oldValue && newValue) {
        this.period.application = "total";
      } else if (oldValue && !newValue) {
        this.period.application = null;
      }
    },
  },
  created() {
    this.fetchAccommodationRates();
  },
  methods: {
    formatRateDate(date) {
      if (!date) return this.$t("No disponible");
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("No disponible")
      );
    },
    formatCurrency(price) {
      if (!price && price !== 0) return "No disponible";
      return formatCurrency(price);
    },
    fetchAccommodationRates() {
      this.$store.dispatch(
        "accommodation/fetchAccommodationRates",
        this.booking.accommodation.uuid
      );
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      // TODO: check that all the selected dates are available before creating the new rates.

      // Start loader
      this.$store.dispatch("app/setLoading", true);

      // Array of promises, we will check later to check if all the promises were successful.
      const promises = [];

      // Add booking rates
      this.$store
        .dispatch("booking/setRates", {
          booking: this.booking["@id"],
          checkin: this.period.checkin,
          checkout: this.period.checkout,
          price: this.selectedRatesTotal,
          rates: this.selectedRates,
        })
        .then(() => {
          promises.push(
            this.$store.dispatch("booking/fetchBooking", this.booking.uuid)
          );
          promises.push(
            this.$store.dispatch("booking/fetchRates", this.booking.uuid)
          );
          Promise.all(promises)
            .then(() => this.$emit("booking-rates-updated"))
            .catch(() => this.$emit("booking-rates-update-error"))
            .finally(() => this.$store.dispatch("app/setLoading", false));
        })
        .catch(() => {
          this.$emit("booking-rates-update-error");
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
#booking-dates-form {
  .custom-control-input ~ .custom-control-label {
    margin-left: 5px;
  }
}
</style>
