<template>
  <div id="booking-owner-rates-form">
    <!-- FORM -->
    <b-row class="mb-2">
      <!-- RATE -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h6>Tarifa</h6>
          </b-col>
          <b-col cols="12">
            <b-input-group>
              <b-form-input
                v-model="rate"
                type="number"
                placeholder="PVP en €"
                min="0"
              />
              <b-input-group-append is-text>
                <b-form-radio-group
                  v-model="application"
                  :disabled="!rate"
                  :options="nightTotalOptions"
                  value-field="value"
                  text-field="text"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- PREVIEW -->
    <b-row v-if="rate && application" class="mb-2">
      <b-col cols="12" class="mb-50">
        <h6>Detall (Import propietari)</h6>
      </b-col>

      <b-col cols="12">
        <div
          v-for="(selectedRate, selectedRateIndex) in selectedRates"
          :key="`selected-rate-${selectedRateIndex}`"
          class="d-flex justify-content-between mb-50"
        >
          <span class="text-grey">
            {{ formatRateDate(selectedRate.date) }}
          </span>
          <span
            :class="{ 'text-danger font-weight-bold': selectedRate.price <= 0 }"
            >{{ formatCurrency(selectedRate.price) }}</span
          >
        </div>

        <b-divider class="my-1" />

        <div class="d-flex justify-content-between mb-50 font-weight-bold">
          <span>Total ({{ nightsText }})</span>
          <span>{{ formatCurrency(selectedRatesTotal) }}</span>
        </div>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="!rate || !application"
          @click="save()"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  formatCurrency,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupAppend,
    BDivider,
  },
  data() {
    return {
      rate: null,
      application: null,
      nightTotalOptions: [
        { text: "vespre", value: "night", disabled: false },
        { text: "total", value: "total", disabled: false },
      ],
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    rates() {
      return this.$store.getters["booking/rates"];
    },
    isNightRateApplication() {
      return this.application === "night";
    },
    transformedPvpAmount() {
      if (!this.rate) return null;
      return Math.round(this.rate * 100);
    },
    nights() {
      return this.booking?.nights;
    },
    nightsText() {
      if (!this.nights && this.nights !== 0) return null;
      return `${this.nights} ${this.nights !== 1 ? "vespres" : "vespre"}`;
    },
    nightPvp() {
      if (this.isNightRateApplication) return this.transformedPvpAmount;
      if (this.nights <= 0) return null;
      return Math.round((parseFloat(this.rate) / this.nights).toFixed(2) * 100);
    },
    selectedRates() {
      if (this.rates.length === 0) return [];
      if (!this.rate && this.rate !== 0) return [];

      const selectedRates = [];
      let sum = 0;
      let index = 0;

      this.rates.forEach((rate) => {
        if (index < this.nights - 1) {
          selectedRates.push({
            date: formatDateStringToDatabaseDate(rate.date),
            price: this.nightPvp,
          });
          sum += this.nightPvp;
        } else {
          // We are in the last index, manually calculate the remaining amount
          // to avoid decimal misscalculations.
          selectedRates.push({
            date: formatDateStringToDatabaseDate(rate.date),
            price: this.isNightRateApplication
              ? this.nightPvp
              : this.transformedPvpAmount - sum,
          });
        }
        index += 1;
      });

      return selectedRates;
    },
    selectedRatesTotal() {
      if (this.selectedRates.length === 0) return null;
      return this.selectedRates.reduce((acc, r) => acc + r.price, 0);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    rate(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.application = "total";
      } else if (oldValue && !newValue) {
        this.application = null;
      }
    },
  },
  methods: {
    formatRateDate(date) {
      if (!date) return "No disponible";
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    formatCurrency(price) {
      if (!price && price !== 0) return "No disponible";
      return formatCurrency(price);
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (this.selectedRates.length === 0) return;

      // Start loader
      this.$store.dispatch("app/setLoading", true);

      // Add booking rates
      this.$store
        .dispatch("booking/setOwnerRates", {
          booking: this.booking["@id"],
          price: this.selectedRatesTotal,
          rates: this.selectedRates,
        })
        .then(() => this.$emit("price-updated"))
        .catch(() => this.$emit("price-update-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
#booking-owner-rates-form {
  .custom-control-input ~ .custom-control-label {
    margin-left: 5px;
  }
}
</style>
