<template>
  <span
    class="text-nowrap client cursor-pointer"
    @click.exact.left.stop="openClient"
    @click.exact.left.meta.stop="openClientInNewTab"
    @click.exact.left.ctrl.stop="openClientInNewTab"
    @click.exact.middle.stop="openClientInNewTab"
  >
    {{ name }}
  </span>
</template>

<script>
export default {
  props: {
    uuid: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    clientViewRoute() {
      return {
        name: "foravila-client-view",
        params: { clientUuid: this.uuid },
      };
    },
  },
  methods: {
    openClient() {
      if (!this.uuid) return null;
      this.$router.push(this.clientViewRoute);
    },
    openClientInNewTab() {
      if (!this.uuid) return null;
      const routeData = this.$router.resolve(this.clientViewRoute);
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
span.client:hover {
  color: #ffcd00;
}
</style>
