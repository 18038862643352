<template>
  <div id="security-deposit-form">
    <b-row class="mb-2">
      <!-- PAYMENT DATE -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Data</h5>
          </b-col>
          <b-col cols="12">
            <b-form-datepicker
              v-model="newRefund.date"
              placeholder="Data depagament"
              :locale="locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- PAYMENT AMOUNT -->
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Import</h5>
          </b-col>
          <b-col cols="12">
            <b-input-group append="€" class="input-group-merge">
              <b-form-input
                v-model="newRefund.pvpAmount"
                type="number"
                debounce="750"
                number
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <!-- PAYMENT METHOD -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Mètode de devolució</h5>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="newRefund.method"
              placeholder="Selecciona un mètode"
              :options="refundMethodOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- LOCALIZATOR -->
      <b-col cols="12">
        <b-row class="mb-2">
          <b-col cols="12">
            <h5>Localitzador</h5>
          </b-col>
          <b-col cols="12">
            <b-form-input
              v-model="newRefund.localizator"
              placeholder="Localitzador de la devolució"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- COMMENT -->
      <b-col cols="12">
        <b-row class="mb-1">
          <b-col cols="12">
            <h5>Comentari</h5>
          </b-col>
          <b-col cols="12">
            <b-form-textarea
              v-model="newRefund.comment"
              placeholder="Comentari sobre la devolució"
              rows="2"
            />
          </b-col>
        </b-row>
      </b-col>
      <!-- ALERTS -->
      <b-col cols="12">
        <!-- INCOMPLETE REFUND ALERT -->
        <b-alert
          variant="primary"
          :show="showInsufficientRefundAlert"
          class="my-1"
        >
          <div class="alert-body font-weight-normal">
            L'import que es retornarà és
            {{ formatCurrency(refundDifference) }} inferior a l'import pagat de
            fiança.
          </div>
        </b-alert>
        <!-- EXCESIVE REFUND ALERT -->
        <b-alert variant="danger" :show="showExcesiveRefundAlert" class="my-1">
          <div class="alert-body font-weight-normal">
            L'import que es retornarà es
            {{ formatCurrency(-refundDifference) }} superior a l'import pagat de
            fiança.
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BAlert,
  BFormDatepicker,
} from "bootstrap-vue";
import { paymentMethodOptions } from "@/utils/select-options";
import vSelect from "vue-select";
import {
  formatCurrency,
  formatDateObjectToDatabaseDateTime,
} from "@/utils/formatters";

export default {
  name: "SecurityDepositRefundForm",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BAlert,
    vSelect,
    BFormDatepicker,
  },
  props: {
    service: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      newRefund: {
        date: formatDateObjectToDatabaseDateTime(new Date()),
        pvpAmount: null,
        method: "CREDIT_CARD",
        localizator: null,
        comment: "Devolució fiança",
      },
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingPayments() {
      return this.$store.getters["booking/loadingClientPayments"];
    },
    payments() {
      return this.$store.getters["booking/clientPayments"];
    },
    refunds() {
      return this.$store.getters["booking/clientRefunds"];
    },
    refundMethodOptions() {
      return paymentMethodOptions;
    },
    transformedPvpAmount() {
      if (!this.newRefund.pvpAmount && this.newRefund.pvpAmount !== 0)
        return null;
      return Math.round(this.newRefund.pvpAmount * 100);
    },
    securityDepositAmount() {
      return this.service?.pvpPrice ? this.service.pvpPrice / 100 : 300;
    },
    securityDepositPayments() {
      if (!this.payments || this.payments.length === 0) return [];
      return this.payments.filter((cp) => cp.scope === "SECURITY_DEPOSIT");
    },
    securityDepositRefunds() {
      if (!this.refunds || this.refunds.length === 0) return [];
      return this.refunds.filter((cp) => cp.scope === "SECURITY_DEPOSIT");
    },
    securityDepositChargedPrice() {
      return this.$store.getters["booking/securityDepositChargedPrice"];
    },
    securityDepositRefundedPrice() {
      return this.$store.getters["booking/securityDepositRefundedPrice"];
    },
    isPartialRefund() {
      if (!this.transformedPvpAmount && this.transformedPvpAmount !== 0)
        return false;
      return this.transformedPvpAmount < this.securityDepositChargedPrice;
    },
    showInsufficientRefundAlert() {
      if (!this.transformedPvpAmount && this.transformedPvpAmount !== 0)
        return false;
      return (
        this.transformedPvpAmount + this.securityDepositRefundedPrice <
        this.securityDepositChargedPrice
      );
    },
    showExcesiveRefundAlert() {
      if (!this.transformedPvpAmount && this.transformedPvpAmount !== 0)
        return false;
      return (
        this.transformedPvpAmount + this.securityDepositRefundedPrice >
        this.securityDepositChargedPrice
      );
    },
    refundDifference() {
      return (
        this.securityDepositChargedPrice -
        (this.transformedPvpAmount + this.securityDepositRefundedPrice)
      );
    },
  },
  created() {
    this.newRefund.pvpAmount = this.securityDepositAmount;
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.createRefund();
      // TODO: add edit refund method
    },
    createRefund() {
      this.$store.dispatch("app/setLoading", true);

      setTimeout(() => {
        this.$store
          .dispatch("booking/addClientPayment", {
            booking: this.booking["@id"],
            bookingService: this.service["@id"] || null,
            ...this.newRefund,
            pvpAmount: -this.transformedPvpAmount,
            status: "CONFIRMED",
            confirmedAt: formatDateObjectToDatabaseDateTime(new Date()),
            scope: "SECURITY_DEPOSIT",
          })
          .then(() => this.$emit("security-deposit-refunded"))
          .catch(() => {
            this.$emit("security-deposit-refund-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
