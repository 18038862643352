<template>
  <b-overlay :show="loading" rounded="sm" variant="white" opacity="0.9">
    <b-card title="Històric de pagaments">
      <client-payments-table
        class="mt-2"
        :payments="tableItems"
        scope
        localizator
        status
        method
        type
        details-button
        refund-button
        confirm-button
        verify-button
        edit-button
        delete-button
        @payment-details="onShowPaymentDetails"
        @payment-confirm="onConfirmPayment"
        @payment-verify="onVerifyPayment"
        @payment-refund="onGenerateRefund"
        @payment-edit="onEditPayment"
        @payment-delete="onDeletePayment"
      />

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col cols="6" sm="auto">
          <b-button block variant="primary" @click="onAddClientPaymentClicked">
            Afegeix pagament
          </b-button>
        </b-col>
        <b-col cols="6" sm="auto">
          <b-button block variant="primary" @click="onAddRefundClicked">
            Afegeix devolució
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <!-- CLIENT PAYMENT DETAILS MODAL -->
    <b-modal
      :id="'client-payment-details-modal'"
      title="Detalls de l'operació"
      hide-footer
      centered
    >
      <client-payment-details :payment="selectedPayment" />
    </b-modal>

    <!-- ADD CLIENT PAYMENT MODAL -->
    <b-modal
      :id="'add-client-payment-modal'"
      title="Afegeix un pagament"
      scrollable
      hide-footer
      centered
    >
      <client-payment-form
        @client-payment-added="onClientPaymentAdded"
        @cancel="onAddClientPaymentCancel"
      />
    </b-modal>

    <!-- REFUND CLIENT PAYMENT MODAL -->
    <b-modal
      :id="'refund-client-payment-modal'"
      title="Afegeix una devolució"
      scrollable
      hide-footer
      centered
    >
      <client-payment-form
        refund-form
        :payment="selectedPayment"
        @client-payment-added="onClientPaymentRefunded"
        @cancel="onRefundClientPaymentCancel"
      />
    </b-modal>

    <!-- EDIT CLIENT PAYMENT MODAL -->
    <b-modal
      :id="'edit-client-payment-modal'"
      title="Edita un pagament"
      scrollable
      hide-footer
      centered
    >
      <client-payment-form
        :payment="selectedPayment"
        @client-payment-edited="onClientPaymentEdited"
        @cancel="onEditClientPaymentCancel"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButton,
  BModal,
} from "bootstrap-vue";
import ClientPaymentsTable from "@/views/bookings/booking/components/payments/ClientPaymentsTable.vue";
import ClientPaymentDetails from "@/views/bookings/booking/components/payments/ClientPaymentDetails.vue";
import ClientPaymentForm from "@/views/bookings/booking/components/payments/ClientPaymentForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    ClientPaymentsTable,
    BButton,
    BModal,
    ClientPaymentDetails,
    ClientPaymentForm,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selectedPayment: null,
    };
  },
  computed: {
    loading() {
      return this.loadingBooking || this.loadingPayments;
    },
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    loadingPayments() {
      return this.$store.getters["booking/loadingClientPayments"];
    },
    payments() {
      return this.$store.getters["booking/clientPayments"];
    },
    refunds() {
      return this.$store.getters["booking/clientRefunds"];
    },
    tableItems() {
      return this.payments.concat(this.refunds);
    },
  },
  methods: {
    onShowPaymentDetails(payment) {
      this.selectedPayment = payment;
      this.$bvModal.show("client-payment-details-modal");
    },
    onConfirmPayment(payment) {
      this.$swal({
        title: "Confirmar pagament",
        text: "Estàs a punt de confirmar aquest pagament, estàs segur/a?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Confirma",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.confirmPayment(payment);
      });
    },
    confirmPayment(payment) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/updateClientPayment", {
          uuid: payment.uuid,
          status: "CONFIRMED",
          confirmedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          notifySuccess(
            "Pagament confirmat",
            "El pagament ha estat confirmat correctament."
          );
          this.$store.dispatch("booking/fetchServices", this.booking.uuid);
        })
        .catch(() => {
          notifyError(
            "Pagament no confirmat",
            "Hi ha hagut un error al confirmat el pagament."
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onVerifyPayment(payment) {
      this.$swal({
        title: "Verificar pagament",
        text: "Estàs a punt de verificar aquest pagament, estàs segur/a?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Verifica",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.verifyPayment(payment);
      });
    },
    verifyPayment(payment) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/updateClientPayment", {
          uuid: payment.uuid,
          status: "VERIFIED",
          verifiedAt: formatDateObjectToDatabaseDateTime(new Date()),
        })
        .then(() => {
          notifySuccess(
            "Pagament verificat",
            "El pagament ha estat verificat correctament."
          );
          this.$store.dispatch("booking/fetchServices", this.booking.uuid);
        })
        .catch(() => {
          notifyError(
            "Pagament no verificat",
            "Hi ha hagut un error al verificat el pagament."
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onDeletePayment(payment) {
      this.$swal({
        title: "Eliminar pagament",
        text: "Estàs a punt d'eliminar aquest pagament, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deletePayment(payment);
      });
    },
    deletePayment(payment) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("booking/deleteClientPayment", payment.uuid)
        .then(() => {
          notifySuccess(
            "Pagament eliminat",
            "El pagament ha estat eliminat correctament."
          );
          this.$store.dispatch("booking/fetchServices", this.booking.uuid);
        })
        .catch(() => {
          notifyError(
            "Pagament no eliminat",
            "Hi ha hagut un error al eliminar el pagament."
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddClientPaymentClicked() {
      this.$bvModal.show("add-client-payment-modal");
    },
    onClientPaymentAdded() {
      this.$bvModal.hide("add-client-payment-modal");
      this.selectedPayment = null;
    },
    onAddClientPaymentCancel() {
      this.$bvModal.hide("add-client-payment-modal");
      this.selectedPayment = null;
    },
    onEditPayment(payment) {
      this.selectedPayment = payment;
      this.$bvModal.show("edit-client-payment-modal");
    },
    onClientPaymentEdited() {
      this.closeEditClientPaymentModal();
    },
    onEditClientPaymentCancel() {
      this.closeEditClientPaymentModal();
    },
    closeEditClientPaymentModal() {
      this.$bvModal.hide("edit-client-payment-modal");
      this.selectedPayment = null;
    },
    onGenerateRefund(payment) {
      this.selectedPayment = payment;
      this.$bvModal.show("refund-client-payment-modal");
    },
    onAddRefundClicked() {
      this.$bvModal.show("refund-client-payment-modal");
    },
    onClientPaymentRefunded() {
      this.$bvModal.hide("refund-client-payment-modal");
      this.selectedPayment = null;
    },
    onRefundClientPaymentCancel() {
      this.$bvModal.hide("refund-client-payment-modal");
      this.selectedPayment = null;
    },
  },
};
</script>
