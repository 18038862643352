<template>
  <b-card id="incidences-card" title="Incidències">
    <b-overlay :show="loadingIncidences" variant="white" :opacity="1">
      <!-- INCIDENCES -->
      <b-row>
        <b-col v-if="incidences.length" cols="12">
          <incidences-card-item
            v-for="incidence in incidences"
            :key="`incidence-${incidence.uuid}`"
            :incidence="incidence"
            class="mb-2"
            @edit-incidence="onEditIncidenceButtonClicked"
            @close-incidence="onCloseIncidenceSelected"
          />
        </b-col>
        <b-col v-else cols="12" class="mb-2 text-center">
          💃 {{ $t("Sense incidències") }} 🕺
        </b-col>
      </b-row>

      <!-- BUTTON -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="12">
          <b-button
            variant="primary"
            block
            @click="onNewIncidenceButtonClicked"
          >
            {{ $t("Nova incidència") }}
          </b-button>
        </b-col>
      </b-row>

      <!-- NEW INCIDENCE MODAL -->
      <b-modal
        id="new-incidence-modal"
        size="lg"
        :title="$t('Nova incidència')"
        centered
        hide-footer
      >
        <new-incidence-form @new-incidence-close="onNewIncidenceClose" />
      </b-modal>

      <!-- EDIT INCIDENCE MODAL -->
      <b-modal
        id="edit-incidence-modal"
        size="lg"
        :title="$t('Detalls de la incidència')"
        centered
        hide-footer
      >
        <edit-incidence-form
          :incidence="selectedIncidence"
          @edit-incidence-close="onEditIncidenceClose"
        />
      </b-modal>

      <!-- CLOSE INCIDENCE MODAL -->
      <b-modal
        id="close-incidence-modal"
        size="lg"
        :title="$t('Tancar incidència')"
        centered
        hide-footer
      >
        <close-incidence-form
          :incidence="selectedIncidence"
          @close-incidence-close="onCloseIncidenceClose"
        />
      </b-modal>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BButton, BModal } from "bootstrap-vue";
import NewIncidenceForm from "@/views/bookings/booking/components/incidences/NewIncidenceForm.vue";
import EditIncidenceForm from "@/views/bookings/booking/components/incidences/EditIncidenceForm.vue";
import CloseIncidenceForm from "@/views/bookings/booking/components/incidences/CloseIncidenceForm.vue";
import IncidencesCardItem from "@/views/bookings/booking/components/incidences/IncidencesCardItem.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BModal,
    NewIncidenceForm,
    EditIncidenceForm,
    CloseIncidenceForm,
    IncidencesCardItem,
  },
  data() {
    return {
      selectedIncidence: null,
    };
  },
  computed: {
    loadingIncidences() {
      return this.$store.getters["booking/loadingIncidences"];
    },
    incidences() {
      return this.$store.getters["booking/incidences"];
    },
  },
  methods: {
    onNewIncidenceButtonClicked() {
      this.$bvModal.show("new-incidence-modal");
    },
    onEditIncidenceButtonClicked(incidence) {
      this.selectedIncidence = incidence;
      this.$bvModal.show("edit-incidence-modal");
    },
    onCloseIncidenceSelected(incidence) {
      this.selectedIncidence = incidence;
      this.$bvModal.show("close-incidence-modal");
    },
    onNewIncidenceClose() {
      this.$bvModal.hide("new-incidence-modal");
    },
    onEditIncidenceClose() {
      this.$bvModal.hide("edit-incidence-modal");
    },
    onCloseIncidenceClose() {
      this.$bvModal.hide("close-incidence-modal");
    },
  },
};
</script>
