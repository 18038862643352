<template>
  <b-card title="Accions">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <!-- UPDATE DATES -->
        <b-col class="mb-1" cols="12">
          <b-button
            block
            variant="primary"
            @click="updateBookingDatesButtonClicked"
          >
            Modifica dates
          </b-button>
          <b-modal
            id="action-update-booking-dates-modal"
            title="Modifica les dates de la reserva"
            hide-footer
            centered
          >
            <booking-dates-form
              @booking-rates-updated="onBookingRatesUpdated"
            />
          </b-modal>
        </b-col>

        <!-- UPDATE ACCOMMODATION -->
        <b-col class="mb-1" cols="12">
          <b-button
            block
            variant="primary"
            @click="updateBookingAccommodationButtonClicked"
          >
            Modifica allotjament
          </b-button>
          <b-modal
            id="action-update-booking-accommodation-modal"
            title="Modifica l'allotjament de la reserva"
            hide-footer
            centered
            no-close-on-backdrop
          >
            <booking-accommodation-form
              @updated="onBookingAccommodationUpdated"
              @cancel="closeBookingAccommodationModal"
            />
          </b-modal>
        </b-col>

        <!-- CLIENT NOTIFICATIONS -->
        <b-col cols="12" class="mb-1" v-if="!isFictitious">
          <b-dropdown text="Notificació client" variant="primary" block>
            <b-dropdown-item @click="clientPrebookingConfirmationButtonClicked">
              Confirmació pre-reserva
            </b-dropdown-item>
            <b-dropdown-item @click="clientBookingConfirmationButtonClicked">
              Confirmació reserva
            </b-dropdown-item>
            <b-dropdown-item @click="clientBookingModificationButtonClicked">
              Modificació reserva
            </b-dropdown-item>
            <b-dropdown-item @click="clientBookingCancellationButtonClicked">
              Cancel·lació reserva
            </b-dropdown-item>
            <b-dropdown-item @click="clientInstructionsButtonClicked">
              Instruccions d'arribada
            </b-dropdown-item>
            <b-dropdown-item @click="clientManualNotificationButtonClicked">
              Notificació manual
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <!-- OWNER NOTIFICATIONS -->
        <b-col cols="12" class="mb-1">
          <b-dropdown text="Notificació propietari" variant="primary" block>
            <b-dropdown-item @click="ownerBookingConfirmationButtonClicked">
              Confirmació reserva
            </b-dropdown-item>
            <b-dropdown-item @click="ownerBookingModificationButtonClicked">
              Modificació reserva
            </b-dropdown-item>
            <b-dropdown-item @click="ownerBookingCancellationButtonClicked">
              Cancel·lació reserva
            </b-dropdown-item>
            <b-dropdown-item @click="ownerInstructionsButtonClicked">
              Informació d'arribada
            </b-dropdown-item>
          </b-dropdown>
        </b-col>

        <!-- CLIENT PREBOOKING CONFIRMATION MODAL -->
        <b-modal
          :id="'action-send-client-prebooking-confirmation-modal'"
          title="Envia la confirmació de pre-reserva al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="prebooking-confirmation"
            @sent="onClientPrebookingConfirmationSent"
            @send-error="onClientPrebookingConfirmationSendError"
          />
        </b-modal>

        <!-- CLIENT BOOKING CONFIRMATION MODAL -->
        <b-modal
          :id="'action-send-client-booking-confirmation-modal'"
          title="Envia la confirmació de reserva al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="booking-confirmation"
            @sent="onClientBookingConfirmationSent"
            @send-error="onClientBookingConfirmationSendError"
          />
        </b-modal>

        <!-- CLIENT BOOKING MODIFICATION MODAL -->
        <b-modal
          :id="'action-send-client-booking-modification-modal'"
          title="Envia la modificació de reserva al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="booking-modification"
            @sent="onClientBookingModificationSent"
            @send-error="onClientBookingModificationSendError"
          />
        </b-modal>

        <!-- CLIENT BOOKING CANCELLATION MODAL -->
        <b-modal
          :id="'action-send-client-booking-cancellation-modal'"
          title="Envia la cancel·lació de reserva al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="booking-cancellation"
            @sent="onClientBookingCancellationSent"
            @send-error="onClientBookingCancellationSendError"
          />
        </b-modal>

        <!-- CLIENT INSTRUCTIONS MODAL -->
        <b-modal
          :id="'action-send-client-instrucitons-modal'"
          title="Envia les instruccions d'arribada al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="checkin-instructions"
            @sent="onClientInstructionsSent"
            @send-error="onClientInstructionsSendError"
          />
        </b-modal>

        <!-- CLIENT MANUAL NOTIFICATION MODAL -->
        <b-modal
          id="action-send-client-manual-notification-modal"
          title="Envia una notificació manual al client"
          hide-footer
          scrollable
          size="lg"
        >
          <client-email-notification-form
            notification-type="manual"
            @sent="onClientManualNotificationSent"
            @send-error="onClientManualNotificationSendError"
          />
        </b-modal>

        <!-- OWNER BOOKING CONFIRMATION MODAL -->
        <b-modal
          :id="'action-send-owner-booking-confirmation-modal'"
          title="Envia la confirmació de reserva al propietari"
          hide-footer
          scrollable
          size="lg"
        >
          <owner-email-notification-form
            notification-type="booking-confirmation"
            @sent="onOwnerBookingConfirmationSent"
            @send-error="onOwnerBookingConfirmationSendError"
          />
        </b-modal>

        <!-- OWNER BOOKING MODIFICATION MODAL -->
        <b-modal
          :id="'action-send-owner-booking-modification-modal'"
          title="Envia la modificació de reserva al propietari"
          hide-footer
          scrollable
          size="lg"
        >
          <owner-email-notification-form
            notification-type="booking-modification"
            @sent="onOwnerBookingModificationSent"
            @send-error="onOwnerBookingModificationSendError"
          />
        </b-modal>

        <!-- OWNER BOOKING CANCELLATION MODAL -->
        <b-modal
          :id="'action-send-owner-booking-cancellation-modal'"
          title="Envia la cancel·lació de reserva al propietari"
          hide-footer
          scrollable
          size="lg"
        >
          <owner-email-notification-form
            notification-type="booking-cancellation"
            @sent="onOwnerBookingCancellationSent"
            @send-error="onOwnerBookingCancellationSendError"
          />
        </b-modal>

        <!-- OWNER CHECK-IN INFORMATION MODAL -->
        <b-modal
          :id="'action-send-owner-information-modal'"
          title="Envia la informació d'arribada al propietari"
          hide-footer
          scrollable
          size="lg"
        >
          <owner-email-notification-form
            notification-type="checkin-information"
            @sent="onOwnerInformationSent"
            @send-error="onOwnerInformationSendError"
          />
        </b-modal>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import BookingDatesForm from "@/views/bookings/booking/components/summary/BookingDatesForm.vue";
import BookingAccommodationForm from "@/views/bookings/booking/components/summary/BookingAccommodationForm.vue";
import ClientEmailNotificationForm from "@/views/bookings/booking/components/notifications/ClientEmailNotificationForm.vue";
import OwnerEmailNotificationForm from "@/views/bookings/booking/components/notifications/OwnerEmailNotificationForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    BookingDatesForm,
    BookingAccommodationForm,
    BOverlay,
    BDropdown,
    BDropdownItem,
    ClientEmailNotificationForm,
    OwnerEmailNotificationForm,
  },
  computed: {
    loading() {
      return this.$store.getters["booking/loadingSomething"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    isFictitious() {
      return this.booking?.fictitious || false;
    },
    client() {
      return this.$store.getters["booking/client"];
    },
    clientHasEmail() {
      if (!this.client) return false;
      return !!this.client.email;
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
    ownerHasEmail() {
      return !!this.owner?.email;
    },
  },
  methods: {
    updateBookingDatesButtonClicked() {
      this.$bvModal.show("action-update-booking-dates-modal");
    },
    onBookingRatesUpdated() {
      this.$bvModal.hide("action-update-booking-dates-modal");
    },
    updateBookingAccommodationButtonClicked() {
      this.$bvModal.show("action-update-booking-accommodation-modal");
    },
    onBookingAccommodationUpdated() {
      this.closeBookingAccommodationModal();
    },
    closeBookingAccommodationModal() {
      this.$bvModal.hide("action-update-booking-accommodation-modal");
    },
    clientInstructionsButtonClicked() {
      this.$bvModal.show("action-send-client-instrucitons-modal");
    },
    onClientInstructionsSent() {
      this.$bvModal.hide("action-send-client-instrucitons-modal");
      notifySuccess(
        "Instruccions enviades",
        "Les instruccions d'arribada han estat enviades correctament al client"
      );
    },
    onClientInstructionsSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar les instruccions d'arribada al client, contacta amb en Pedro"
      );
    },
    clientManualNotificationButtonClicked() {
      this.$bvModal.show("action-send-client-manual-notification-modal");
    },
    onClientManualNotificationSent() {
      this.$bvModal.hide("action-send-client-manual-notification-modal");
      notifySuccess(
        "Notificació enviada",
        "La notificació ha estat enviada correctament al client"
      );
    },
    onClientManualNotificationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la notificació al client, contacta amb en Pedro"
      );
    },
    ownerInstructionsButtonClicked() {
      this.$bvModal.show("action-send-owner-information-modal");
    },
    onOwnerInformationSent() {
      this.$bvModal.hide("action-send-owner-information-modal");
      notifySuccess(
        "Instruccions enviades",
        "Les instruccions d'arribada han estat enviades correctament al propietari"
      );
    },
    onOwnerInformationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar les instruccions d'arribada al propietari, contacta amb en Pedro"
      );
    },
    clientPrebookingConfirmationButtonClicked() {
      this.$bvModal.show("action-send-client-prebooking-confirmation-modal");
    },
    onClientPrebookingConfirmationSent() {
      this.$bvModal.hide("action-send-client-prebooking-confirmation-modal");
      notifySuccess(
        "Confirmació enviada",
        "La confirmació de pre-reserva ha estat enviada correctament al client"
      );
    },
    onClientPrebookingConfirmationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la confirmació de pre-reserva al client, contacta amb en Pedro"
      );
    },
    clientBookingConfirmationButtonClicked() {
      this.$bvModal.show("action-send-client-booking-confirmation-modal");
    },
    onClientBookingConfirmationSent() {
      this.$bvModal.hide("action-send-client-booking-confirmation-modal");
      notifySuccess(
        "Confirmació enviada",
        "La confirmació de reserva ha estat enviada correctament al client"
      );
    },
    onClientBookingConfirmationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la confirmació de reserva al client, contacta amb en Pedro"
      );
    },
    clientBookingCancellationButtonClicked() {
      this.$bvModal.show("action-send-client-booking-cancellation-modal");
    },
    onClientBookingCancellationSent() {
      this.$bvModal.hide("action-send-client-booking-cancellation-modal");
      notifySuccess(
        "Cancel·lació enviada",
        "La cancel·lació de reserva ha estat enviada correctament al client"
      );
    },
    onClientBookingCancellationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la cancel·lació de reserva al client, contacta amb en Pedro"
      );
    },
    clientBookingModificationButtonClicked() {
      this.$bvModal.show("action-send-client-booking-modification-modal");
    },
    onClientBookingModificationSent() {
      this.$bvModal.hide("action-send-client-booking-modification-modal");
      notifySuccess(
        "Modificació enviada",
        "La modificació de reserva ha estat enviada correctament al client"
      );
    },
    onClientBookingModificationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la modificació de reserva al client, contacta amb en Pedro"
      );
    },
    ownerBookingConfirmationButtonClicked() {
      this.$bvModal.show("action-send-owner-booking-confirmation-modal");
    },
    ownerWABookingConfirmationButtonClicked() {
      let text = [];
      text.push(
        this.$t("emails.ownerBookingConfirmation.intro", this.$i18n.locale, {
          clientName: this.client.firstName,
          accommodationName: this.accommodation.name,
        })
      );
      text = text.length ? text.join("\r\n\r\n") : null;
      this.$copyText(text);
    },
    onOwnerBookingConfirmationSent() {
      this.$bvModal.hide("action-send-owner-booking-confirmation-modal");
      notifySuccess(
        "Confirmació enviada",
        "La confirmació de reserva ha estat enviada correctament al propietari"
      );
    },
    onOwnerBookingConfirmationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la confirmació de reserva al propietari, contacta amb en Pedro"
      );
    },
    ownerBookingModificationButtonClicked() {
      this.$bvModal.show("action-send-owner-booking-modification-modal");
    },
    onOwnerBookingModificationSent() {
      this.$bvModal.hide("action-send-owner-booking-modification-modal");
      notifySuccess(
        "Confirmació enviada",
        "La modificació de reserva ha estat enviada correctament al propietari"
      );
    },
    onOwnerBookingModificationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la modificació de reserva al propietari, contacta amb en Pedro"
      );
    },
    ownerBookingCancellationButtonClicked() {
      this.$bvModal.show("action-send-owner-booking-cancellation-modal");
    },
    onOwnerBookingCancellationSent() {
      this.$bvModal.hide("action-send-owner-booking-cancellation-modal");
      notifySuccess(
        "Cancel·lació enviada",
        "La cancel·lació de reserva ha estat enviada correctament al propietari"
      );
    },
    onOwnerBookingCancellationSendError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar enviar la cancel·lació de reserva al propietari, contacta amb en Pedro"
      );
    },
  },
};
</script>
