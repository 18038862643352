<template>
  <div class="b-badge-dropdown">
    <b-dropdown
      v-if="dropdownOptions.length"
      size="sm"
      :text="badgeText"
      :variant="badgeVariant"
    >
      <b-dropdown-item
        v-for="(option, index) in dropdownOptions"
        :key="`b-badge-dropdown-${componentID}-option-${index}`"
        @click="optionSelected(option.value)"
      >
        {{ option.label }}
      </b-dropdown-item>
    </b-dropdown>
    <b-badge v-else :variant="badgeVariant">
      {{ badgeText }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge, BDropdown, BDropdownItem } from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  props: {
    badgeText: {
      type: String,
      required: true,
    },
    badgeVariant: {
      type: String,
      default: "primary",
      validator: (prop) =>
        [
          "primary",
          "secondary",
          "danger",
          "warning",
          "success",
          "info",
        ].includes(prop),
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      componentID: "",
    };
  },
  created() {
    this.componentID = uuidv4();
  },
  methods: {
    optionSelected(optionValue) {
      this.$emit("selected", optionValue);
    },
  },
};
</script>

<style lang="scss">
.b-badge-dropdown {
  .dropdown-toggle {
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    padding: 0.2rem 0.5rem;
    text-align: center;
    border-radius: 0.358rem;
    &:after {
      left: 0.15rem;
      top: 0.1rem;
    }
  }
}
</style>
