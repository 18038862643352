<template>
  <b-card title="A tenir en compte">
    <b-overlay :show="loading" variant="white" :opacity="1">
      <b-row>
        <b-col class="text-center" cols="12">
          <!-- EVERYTHING OK -->
          <b-badge
            v-if="everythingInOrder"
            class="mr-50 mb-50"
            variant="success"
          >
            Tot en ordre
          </b-badge>

          <!-- LAST MINUTE -->
          <b-badge v-if="isLastMinute" class="mr-50 mb-50" variant="primary">
            Last Minute
          </b-badge>

          <!-- PENDING AMOUNT -->
          <b-badge
            v-if="pending > 0"
            v-b-tooltip.hover.bottom="pendingText"
            class="mr-50 mb-50"
            variant="danger"
          >
            Pagament pendent
          </b-badge>

          <!-- PENDING SECURITY DEPOSIT -->
          <b-badge
            v-if="
              !hasManagedSecurityDeposit &&
              hasChargableSecurityDeposit &&
              securityDepositPendingPrice > 0
            "
            v-b-tooltip.hover.bottom="securityDepositPendingPriceText"
            class="mr-50 mb-50"
            variant="danger"
          >
            Fiança pendent
          </b-badge>

          <!-- PENDING CHECK-INS -->
          <b-badge
            v-if="confirmedGuests.length < booking.adults"
            class="mr-50 mb-50"
            variant="danger"
          >
            Check-ins pendents
          </b-badge>

          <!-- BABY COTS/CHAIRS -->
          <b-badge
            v-if="babyChairs > 0 || babyCots > 0"
            v-b-tooltip.hover.bottom="babyChairsAndCotsText"
            class="mr-50 mb-50"
            variant="secondary"
          >
            Cuna / Trona
          </b-badge>

          <!-- TRANSFER -->
          <b-badge v-if="hasTransfer" class="mr-50 mb-50" variant="primary">
            Transfer
          </b-badge>

          <!-- CAR RENTAL -->
          <b-badge v-if="hasCarRental" class="mr-50 mb-50" variant="primary">
            Lloguer de cotxe
          </b-badge>

          <!-- FROM FICTITIOUS -->
          <b-badge
            v-if="isAdmin && isReceivedFromFictitious"
            class="mr-50 mb-50"
            variant="info"
          >
            Rebuda especial
          </b-badge>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BBadge, VBTooltip, BOverlay } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";

export default {
  name: "BookingTagsCard",
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    loading() {
      return this.$store.getters["booking/loadingSomething"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    pendingText() {
      return formatCurrency(this.pending);
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    hasManagedSecurityDeposit() {
      return this.$store.getters["booking/hasManagedSecurityDeposit"];
    },
    securityDepositPendingPrice() {
      return this.$store.getters["booking/securityDepositPendingPrice"];
    },
    securityDepositPendingPriceText() {
      return formatCurrency(this.securityDepositPendingPrice);
    },
    hasTransfer() {
      return this.$store.getters["booking/hasTransfer"];
    },
    hasCarRental() {
      return this.$store.getters["booking/hasCarRental"];
    },
    isLastMinute() {
      return this.$store.getters["booking/isLastMinute"];
    },
    babyChairs() {
      return this.$store.getters["booking/babyChairs"];
    },
    babyCots() {
      return this.$store.getters["booking/babyCots"];
    },
    babyChairsAndCotsText() {
      if (this.babyChairs === 0 && this.babyCots === 0) return "No disponible";

      const text = [];
      if (this.babyCots > 0) {
        text.push(`${this.babyCots} ${this.babyCots !== 1 ? "cunes" : "cuna"}`);
      }
      if (this.babyChairs > 0) {
        text.push(
          `${this.babyChairs} ${this.babyChairs !== 1 ? "trones" : "trona"}`
        );
      }
      return text.join(" i ");
    },
    confirmedGuests() {
      return this.$store.getters["booking/confirmedGuests"];
    },
    everythingInOrder() {
      return (
        this.pending <= 0 &&
        this.securityDepositPendingPrice <= 0 &&
        this.confirmedGuests.length >= this.booking.adults &&
        this.babyChairs <= 0 &&
        this.babyCots <= 0 &&
        !this.hasCarRental &&
        !this.hasTransfer
      );
    },
    isReceivedFromFictitious() {
      return this.stats?.receivedFromFictitious || false;
    },
  },
};
</script>
