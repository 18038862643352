<template>
  <div class="incidences-card-item">
    <b-overlay :show="loading" variant="white" :opacity="0.8">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center mb-50">
        <b-badge-dropdown
          :badge-text="incidenceStatusName"
          :badge-variant="incidenceStatusColor"
          :dropdown-options="bookingIncidenceStatusOptions"
          @selected="updateIncidenceStatus"
        />
        <!-- ACTION BUTTONS -->
        <div>
          <b-button
            size="sm"
            variant="primary"
            class="btn-icon p-25"
            @click="onEditIncidenceButtonClicked"
          >
            <feather-icon icon="EyeIcon" size="13" />
          </b-button>
          <b-button
            v-if="canDeleteIncidence"
            size="sm"
            variant="danger"
            class="btn-icon ml-50 p-25"
            @click="onDeleteIncidenceButtonClicked"
          >
            <feather-icon icon="Trash2Icon" size="13" />
          </b-button>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="bg-light p-50">
        <div
          class="font-weight-bold"
          :class="{ 'mb-50 border-bottom pb-50': incidenceLastComment }"
        >
          {{ incidenceTitle }}
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="incidenceLastCommentContent" />
        <small
          class="text-muted d-flex justify-content-end"
          :class="{ 'justify-content-between': incidenceLastCommentIsSolution }"
        >
          <b-badge v-if="incidenceLastCommentIsSolution" class="mb-50">
            {{ $t("Solució") }}
          </b-badge>
          <span>
            {{ incidenceDate }}
          </span>
        </small>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BButton, BBadge, BOverlay } from "bootstrap-vue";
import BBadgeDropdown from "@foravila-core/components/b-badge-dropdown/BBadgeDropdown.vue";
import {
  getBookingIncidenceStatusName,
  getBookingIncidenceStatusColor,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { bookingIncidenceStatusOptions } from "@/utils/select-options";
import {
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDate,
} from "@/utils/formatters";

export default {
  components: {
    BButton,
    BBadge,
    BBadgeDropdown,
    BOverlay,
  },
  props: {
    incidence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIncidence: null,
      loading: false,
      bookingIncidenceStatusOptions,
      getBookingIncidenceStatusName,
      getBookingIncidenceStatusColor,
    };
  },
  computed: {
    incidenceStatusName() {
      return getBookingIncidenceStatusName(
        this.incidence?.status,
        this.$i18n.locale
      );
    },
    incidenceStatusColor() {
      return getBookingIncidenceStatusColor(this.incidence?.status);
    },
    incidenceTitle() {
      return this.incidence?.title || this.$t("Incidència");
    },
    incidenceLastComment() {
      return this.incidence?.lastComment || null;
    },
    incidenceLastCommentContent() {
      return this.incidence?.lastComment?.content || null;
    },
    incidenceLastCommentIsSolution() {
      return this.incidence?.lastComment?.isSolution || false;
    },
    incidenceDate() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };

      if (!this.incidence?.lastComment?.postedAt) {
        return formatDateStringToDate(
          this.incidence.updatedAt,
          this.$i18n.locale,
          formatting
        );
      }

      return formatDateStringToDate(
        this.incidence.lastComment.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
    canDeleteIncidence() {
      if (!this.incidence?.createdAt || this.incidence?.status === "CLOSED")
        return false;

      const createDate = this.$moment(this.incidence.createdAt);
      const now = this.$moment();

      return now.isBefore(createDate.add(1, "day"), "hours");
    },
  },
  methods: {
    updateIncidenceStatus(newStatus) {
      if (newStatus === this.incidence.status) return;
      if (newStatus !== "CLOSED") {
        this.loading = true;
        this.$store
          .dispatch("booking/updateIncidence", {
            uuid: this.incidence.uuid,
            status: newStatus,
            updatedAt: formatDateObjectToDatabaseDateTime(new Date()),
          })
          .catch(() =>
            notifyError(
              "Error",
              "Hi ha hagut un error al intentar actualitzar l'estat de la incidència"
            )
          )
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$emit("close-incidence", this.incidence);
      }
    },
    onEditIncidenceButtonClicked() {
      this.$emit("edit-incidence", this.incidence);
    },
    onDeleteIncidenceButtonClicked() {
      this.$swal({
        title: "Eliminar incidència",
        text: "Estàs a punt d'eliminar aquesta incidència, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteIncidence();
      });
    },
    deleteIncidence() {
      this.loading = true;
      this.$store
        .dispatch("booking/deleteIncidence", this.incidence.uuid)
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar la incidència"
          )
        )
        .finally(() => {
          this.loading = false;
          notifySuccess(
            "Incidència eliminada",
            "La incidència ha estat eliminada correctament"
          );
        });
    },
  },
};
</script>
