<template>
  <div class="py-1">
    <b-badge v-if="!contract" variant="danger" class="w-100 mb-2">
      Aquesta reserva no està associada a cap contracte. Cal corregir-ho per
      poder calcular el marge comercial
    </b-badge>

    <div class="d-flex justify-content-between mb-50">
      <span>Import allotjament</span>
      <span>{{ formatCurrency(accommodationPrice) }}</span>
    </div>

    <div class="d-flex justify-content-between mb-50">
      <span>Import propietari</span>
      <span>{{ formatCurrency(ownerBasePrice) }}</span>
    </div>

    <div class="d-flex justify-content-between mb-50">
      <span>Comissió portal</span>
      <span
        >{{ formatCurrency(sourceComission) }} ({{
          sourceComissionPercentage
        }})</span
      >
    </div>

    <div v-if="vat" class="d-flex justify-content-between">
      <span>{{ vatTitle }}</span>
      <span>{{ formatCurrency(vat) }}</span>
    </div>

    <!-- SEPARADOR -->
    <b-divider class="my-1" />

    <!-- SOURCE COMISSION PERCENTAGE -->
    <div class="d-flex justify-content-between font-weight-bold">
      <span>Marge real sobre PVP</span>
      <b-badge :variant="marginVariant">
        {{ formatCurrency(realMargin) }}
        <span v-if="realMarginPercentageText"
          >({{ realMarginPercentageText }})</span
        >
      </b-badge>
    </div>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";

export default {
  components: {
    BBadge,
    BDivider,
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.booking?.contract || null;
    },
    billingType() {
      return this.contract?.billingType || null;
    },
    ownerVatPercentage() {
      return this.contract?.ownerVatPercentage || null;
    },
    accommodationPrice() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    ownerPrice() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    ownerBasePrice() {
      return this.ownerPrice ?? null;
    },
    pvpPrice() {
      return this.booking?.pvpPrice ?? null;
    },
    sourceComission() {
      return Number.isInteger(this.booking?.sourceComissionPrice)
        ? this.booking?.sourceComissionPrice
        : null;
    },
    sourceComissionPercentage() {
      if (!this.pvpPrice || !this.sourceComission) return "0 %";
      const percentage =
        Math.round((100 / this.pvpPrice) * this.sourceComission * 100) / 100;
      return `${percentage} %`;
    },
    vat() {
      if (this.billingType === "COMISSION") {
        const VAT_BASE = this.accommodationPrice - this.ownerBasePrice;
        const VAT = VAT_BASE - VAT_BASE / 1.21;
        return Math.round(VAT * 100) / 100;
      }
      if (this.billingType === "SUBURANCE") {
        const VAT = this.accommodationPrice - this.accommodationPrice / 1.1;
        return Math.round(VAT * 100) / 100;
      }
      return null;
    },
    vatTitle() {
      if (this.billingType === "COMISSION") {
        return "I.V.A. (21% sobre el marge)";
      }
      if (this.billingType === "SUBURANCE") {
        return "I.V.A. (10% sobre el PVP)";
      }
      return null;
    },
    realMargin() {
      if (!this.vat) return null;
      return (
        this.accommodationPrice -
        this.ownerBasePrice -
        this.sourceComission -
        this.vat
      );
    },
    realMarginPercentage() {
      if (!this.realMargin) return null;
      const percentage =
        Math.round((100 / this.pvpPrice) * this.realMargin * 100) / 100;
      return percentage;
    },
    realMarginPercentageText() {
      if (!this.realMarginPercentage) return null;
      return `${this.realMarginPercentage} %`;
    },
    marginVariant() {
      if (this.realMarginPercentage > 8) return "success";
      if (this.realMarginPercentage > 0) return "warning";
      return "danger";
    },
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price) || this.$t("No disponible");
    },
  },
};
</script>
